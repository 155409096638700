const emojiMap = {
    // Existing features with briefs enhanced
    "foot": { group: "Travel methods", name: "Hiking", emoji: "👣", color: "#4CAF50", textColor: "white", brief: "Explore trails by foot", description: "Perfect for hiking enthusiasts, these trails offer a variety of terrains and breathtaking landscapes, encouraging exploration and adventure on foot." },
    "car": { group: "Travel methods", name: "Driving", emoji: "🚗", color: "#f44336", textColor: "white", brief: "Accessible by car", description: "Easily accessible by car, making it convenient for families and groups carrying camping gear and supplies for their outdoor adventure." },
    "grassland": { group: "Natural features", name: "Meadow", emoji: "🌾", color: "#8BC34A", textColor: "black", brief: "Open, grassy fields", description: "Expansive, grassy meadows perfect for group activities, games, and setting up spacious camp areas under the open sky." },
    "peak": { group: "Natural features", name: "Peak", emoji: "🏔️", color: "#9C27B0", textColor: "white", brief: "High elevation points", description: "Reach the summit for panoramic views that reward your climbing efforts, ideal for seasoned hikers looking for a challenge." },
    "viewpoint": { group: "Activities", name: "Viewpoint", emoji: "🔭", color: "#3F51B5", textColor: "white", brief: "Scenic viewing spots", description: "Strategically located viewing spots offering scenic vistas, perfect for sunrise or sunset watchers and photographers alike." },
    "scrub": { group: "Natural features", name: "Shrubland", emoji: "🌿", color: "#4CAF50", textColor: "white", brief: "Bushy, rugged terrain", description: "Rugged terrain dominated by scrubby bushes, offering unique landscapes and challenging hiking trails for adventurous spirits." },
    "spring": { group: "Natural features", name: "Spring", emoji: "💧", color: "#2196F3", textColor: "white", brief: "Natural water springs", description: "Fresh, clear springs provide a natural water source, ideal for refilling supplies during long hikes or hot days." },
    "heath": { group: "Natural features", name: "Heath", emoji: "🍂", color: "#795548", textColor: "white", brief: "Open, uncultivated land", description: "Wild, open heaths with a haunting beauty, offering a stark landscape that contrasts with dense forested areas." },
    "cliff": { group: "Natural features", name: "Cliff", emoji: "🏞️", color: "#607D8B", textColor: "white", brief: "Steep rock faces and edges", description: "Dramatic cliffs providing challenging climbs and breathtaking views, suitable for experienced climbers and sightseers." },
    "wood": { group: "Natural features", name: "Forest", emoji: "🌲", color: "#2E7D32", textColor: "white", brief: "Densely wooded areas", description: "Dense forests offering shaded campsites and a chance to immerse oneself in the tranquility of nature." },
    "stream": { group: "Natural features", name: "Stream", emoji: "🌊", color: "#2196F3", textColor: "white", brief: "Small, flowing water bodies", description: "Gentle streams adding soothing background sounds and opportunities for kids to play and explore aquatic life." },
    "alpine_hut": { group: "Utilities", name: "Mountain Hut", emoji: "🏡", color: "#FF9800", textColor: "black", brief: "Shelter in mountain areas", description: "Cozy mountain huts provide a welcome respite and shelter from the harsh mountain environment, ideal for overnight stays during extended treks." },
    "water": { group: "Natural features", name: "Water", emoji: "💧", color: "#2196F3", textColor: "white", brief: "General water bodies", description: "Lakes, ponds, and rivers scattered throughout the area offer plentiful water sources for camping, fishing, and water sports." },
    "river": { group: "Water", name: "River", emoji: "🌊", color: "#2196F3", textColor: "white", brief: "Large, flowing water bodies", description: "Majestic rivers ideal for canoeing, rafting, and fishing, providing both thrill and tranquility to all visitors." },
    "reservoir": { group: "Water", name: "Reservoir", emoji: "🚰", color: "#2196F3", textColor: "white", brief: "Water storage areas", description: "Large reservoirs ensure water availability for all campers and offer additional activities such as fishing and bird watching." },
    "lake": { group: "Water", name: "Lake", emoji: "🏞️", color: "#03A9F4", textColor: "white", brief: "Large inland water bodies", description: "Serene lakes ideal for swimming, kayaking, and evening campfires by the water, creating memorable camping experiences." },
    "nature_reserve": { group: "Natural features", name: "Nature Reserve", emoji: "🌲", color: "#4CAF50", textColor: "white", brief: "Protected natural areas", description: "Protected reserves where wildlife and flora thrive, offering educational and photographic opportunities in a preserved setting." },
    "waterfall": { group: "Natural features", name: "Waterfall", emoji: "💦", color: "#2196F3", textColor: "white", brief: "Water flowing over a vertical drop", description: "Stunning waterfalls that serve as a spectacular backdrop for camping sites and a refreshing swim spot." },
    "ridge": { group: "Natural features", name: "Ridge", emoji: "⛰️", color: "#9C27B0", textColor: "white", brief: "Long, narrow elevated land", description: "Elevated ridges offering strategic viewpoints and challenging hikes, perfect for adventurous groups and solo explorers." },
    "glacier": { group: "Natural features", name: "Glacier", emoji: "❄️", color: "#90CAF9", textColor: "black", brief: "Slow-moving masses of ice", description: "Glaciers providing awe-inspiring landscapes and challenging terrains for experienced climbers and environmental enthusiasts." },
    "toilets": { group: "Utilities", name: "Toilets", emoji: "🚽", color: "#E0E0E0", textColor: "black", brief: "Public restroom facilities", description: "Clean, maintained public toilets enhance camping convenience, ensuring hygiene and comfort throughout your stay." },
    "shelter": { group: "Utilities", name: "Shelter", emoji: "⛺", color: "#FFC107", textColor: "black", brief: "Temporary places for rest and cover", description: "Various shelters available for quick rest stops or emergency cover, providing safety and comfort in unpredictable weather." },
    "valley": { group: "Natural features", name: "Valley", emoji: "🏞️", color: "#8BC34A", textColor: "black", brief: "Low areas between hills or mountains", description: "Verdant valleys offering sheltered campsites with stunning surrounding views, ideal for both relaxation and exploration." },
    "bench": { group: "Utilities", name: "Bench", emoji: "🪑", color: "#795548", textColor: "white", brief: "Seating and resting spots", description: "Strategically placed benches provide restful spots for hikers to enjoy the views and catch their breath." },
    "wilderness_hut": { group: "Utilities", name: "Wilderness Hut", emoji: "🏚️", color: "#FF9800", textColor: "black", brief: "Remote shelter in wild areas", description: "Isolated huts in wild settings offer a unique, rustic experience for those seeking solitude and a deep connection with nature." },
    "drinking_water": { group: "Utilities", name: "Drinking Water", emoji: "🚰", color: "#2196F3", textColor: "white", brief: "Potable water sources", description: "Conveniently located drinking water facilities ensure that hydration is never a concern during your outdoor activities." },
    "bicycle": { group: "Activities", name: "Cycling", emoji: "🚲", color: "#E91E63", textColor: "white", brief: "Bicycle-friendly paths", description: "Well-maintained paths ideal for cycling enthusiasts of all levels, offering scenic rides through diverse landscapes." },
    "hunting_stand": { group: "Activities", name: "Hunting Stand", emoji: "🏹", color: "#8D6E63", textColor: "white", brief: "Elevated hunting posts", description: "Elevated stands provide a vantage point for game hunting, blending safely and effectively into the environment." },
    "picnic_table": { group: "Utilities", name: "Picnic Table", emoji: "🪑", color: "#795548", textColor: "white", brief: "Outdoor dining areas", description: "Are you planning a picnic with your crew? Great news. This place is equipped with picnic tables, perfect for group meals and social gatherings in the great outdoors." },
    "place_of_worship": { group: "Activities", name: "Place of Worship", emoji: "⛪", color: "#FFEB3B", textColor: "black", brief: "Religious and spiritual sites", description: "Peaceful places of worship available for reflection and spiritual connection, accommodating diverse faiths and practices." },
    "fell": { group: "Natural features", name: "Fell", emoji: "⛰️", color: "#9C27B0", textColor: "white", brief: "High and barren landscape", description: "Barren fells providing stark, beautiful landscapes for tough hikes and rewarding views, appealing to those who seek solitude and challenge." },
    "pond": { group: "Natural features", name: "Pond", emoji: "🌊", color: "#03A9F4", textColor: "white", brief: "Small bodies of still water", description: "Tranquil ponds perfect for peaceful moments, wildlife observation, or a quiet fishing spot away from the crowds." },
    "cave_entrance": { group: "Natural features", name: "Cave", emoji: "🕳️", color: "#757575", textColor: "white", brief: "Entrances to underground caves", description: "Mysterious cave entrances beckon the brave to explore the unknown, offering cool respite and a touch of adventure beneath the earth’s surface." },
    "bbq": { group: "Utilities", name: "BBQ", emoji: "🍖", color: "#F44336", textColor: "white", brief: "Designated grilling areas", description: "Designated BBQ areas equipped with grills are perfect for cooking up a delicious meal while enjoying the outdoors." },
    "firepit": { group: "Utilities", name: "Firepit", emoji: "🔥", color: "#FF5722", textColor: "white", brief: "Controlled fire grounds", description: "Pre-made fire pits make this suitable for beginner campers looking to enjoy a controlled, safe bonfire experience under the stars." },
    "fishing": { group: "Activities", name: "Fishing", emoji: "🎣", color: "#03A9F4", textColor: "white", brief: "Suitable fishing spots", description: "Idyllic spots abound for anglers to cast their lines in hopes of catching local fish, complete with stunning views and abundant wildlife." },
    "grave_yard": { group: "Activities", name: "Graveyard", emoji: "⚰️", color: "#9E9E9E", textColor: "white", brief: "Burial and memorial sites", description: "Quiet, solemn graveyards offering a place for reflection and respect for those interested in local history and heritage." },
    "shower": { group: "Utilities", name: "Shower", emoji: "🚿", color: "#B2EBF2", textColor: "black", brief: "Public shower facilities", description: "Public shower facilities provide campers with the convenience of freshening up after a day full of outdoor activities, ensuring comfort and hygiene." },
    "beach": { group: "Activities", name: "Beach", emoji: "🏖️", color: "#FFC107", textColor: "black", brief: "Sandy areas by water", description: "Beautiful beaches providing perfect spots for sunbathing, swimming, and beach games, with ample space for relaxing by the water." },
    "wildlife_hide": { group: "Activities", name: "Wildlife Hide", emoji: "🦌", color: "#8D6E63", textColor: "white", brief: "Observation spots for wildlife", description: "Secluded hides placed for observing wildlife in their natural habitats, offering unique opportunities for wildlife photographers and nature lovers." },
    "stream_pool": { group: "Natural features", name: "Stream Pool", emoji: "🌊", color: "#03A9F4", textColor: "white", brief: "Calm pool areas in streams", description: "Calm, secluded pools in streams provide a perfect setting for a refreshing dip or a quiet moment surrounded by nature." },
    "horse": { group: "Travel methods", name: "Horseback", emoji: "🐎", color: "#795548", textColor: "white", brief: "Horse riding trails", description: "Well-marked trails available for horseback riding, offering a different perspective of the landscape and a memorable experience for equestrians." },
    "boat_rental": { group: "Travel methods", name: "Boat Rental", emoji: "🚤", color: "#03A9F4", textColor: "white", brief: "Boat hire services", description: "Boat rental services available, allowing visitors to explore larger water bodies at their leisure and enjoy water-based activities." },
    "bicycle_rental": { group: "Travel methods", name: "Bicycle Rental", emoji: "🚲", color: "#E91E63", textColor: "white", brief: "Bike hire services", description: "Convenient bicycle rental services provide all you need to hit the trails and explore the area on two wheels, suitable for all ages and skill levels." },
    "caravan_site": { group: "Travel methods", name: "Caravan Site", emoji: "🚐", color: "#FFEB3B", textColor: "black", brief: "Caravan parking and facilities", description: "Well-equipped caravan sites with all necessary facilities, including hook-ups and waste disposal, ensuring a comfortable stay for all caravan travelers." },
    "bay": { group: "Natural features", name: "Bay", emoji: "🏝️", color: "#03A9F4", textColor: "white", brief: "Recessed coastal bodies of water", description: "Inviting bays with calm waters, ideal for swimming, kayaking, and relaxing by the shore, offering safety away from strong ocean currents." },
    "tundra": { group: "Natural features", name: "Tundra", emoji: "🌨️", color: "#90CAF9", textColor: "black", brief: "Treeless, cold landscapes", description: "Expansive, treeless tundra providing a unique, stark beauty and a challenge for those seeking to experience the extremes of nature." },
    "peninsula": { group: "Natural features", name: "Peninsula", emoji: "🌉", color: "#03A9F4", textColor: "white", brief: "Land surrounded by water on three sides", description: "Picturesque peninsulas offering expansive views of the surrounding water, perfect for scenic drives and peaceful walks." },
    "volcano": { group: "Natural features", name: "Volcano", emoji: "🌋", color: "#FF9800", textColor: "black", brief: "Mountain with vent for lava", description: "Dormant or active volcanoes providing unique geological landscapes to explore, with guided tours often available for safety." },
    "oxbow": { group: "Natural features", name: "Oxbow", emoji: "🏞️", color: "#607D8B", textColor: "white", brief: "U-shaped bend in a river", description: "Oxbow lakes formed from river bends, offering quiet, enclosed waters ideal for fishing, kayaking, and nature observation." },
    "hot_spring": { group: "Natural features", name: "Hot Spring", emoji: "♨️", color: "#FF5722", textColor: "white", brief: "Natural thermal water source", description: "Natural hot springs providing therapeutic warm waters in serene settings, perfect for relaxation after a long day of hiking." },
    "climbing": { group: "Activities", name: "Climbing", emoji: "🧗", color: "#FFC107", textColor: "black", brief: "Rock or wall climbing opportunities", description: "Designated climbing areas equipped with routes for various skill levels, offering both physical challenge and fun." },
    "cape": { group: "Natural features", name: "Cape", emoji: "🌊", color: "#03A9F4", textColor: "white", brief: "Headland jutting into a body of water", description: "Stunning capes offering dramatic views of the ocean and coastline, accessible for hikes and photo opportunities." },
    "isthmus": { group: "Natural features", name: "Isthmus", emoji: "🌉", color: "#03A9F4", textColor: "white", brief: "Narrow strip of land connecting two larger land areas", description: "Unique isthmuses providing rare geographical features and paths that connect distinct landscapes and ecosystems." },
    "coastline": { group: "Natural features", name: "Coastline", emoji: "🏖️", color: "#03A9F4", textColor: "white", brief: "Area where land meets the sea", description: "Dynamic coastlines offering a mix of beaches, cliffs, and dunes, ideal for a variety of recreational activities from sunbathing to surfing." }
};

export default emojiMap;
