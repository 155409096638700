import React, { useState } from 'react';
import "./Footer.css";
import { useNavigate } from "react-router-dom";
import PosaljiMail from './PosaljiMail';
import Popup from './Popup'; // Ensure Popup component is imported

function Footer() {

  const navigate = useNavigate();

  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handlePopupClose = () => setIsPopupVisible(false);
  const handlePopupOpen = () => setIsPopupVisible(true);


  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-info">
                <h3>Tribal</h3>
                <p className="pb-3"><em>We hope you enjoyed the experience provided by Tribal.</em></p>
                <p>
                  <p>For any other information,<br />
                    you can check on our social media.<br /><br />
                    <strong>Email:</strong> tribalcamping@gmail.com
                  </p>
                </p>
                <div className="social-links mt-3">
                  <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
                  <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
                  <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
                  <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a>
                  <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-6 footer-links">
              <h4>Links</h4>
              <ul style={{ paddingTop: '20px' }}>
                <li><i className="bx bx-chevron-right"></i> <a href="#hero">Home</a></li>
                <li><i className="bx bx-chevron-right"></i> <a href="#about">About us</a></li>
                <li><i className="bx bx-chevron-right"></i> <a href="#features">Features</a></li>
                <li><i className="bx bx-chevron-right"></i> <a href="#phases">Phases</a></li>
                <li><i className="bx bx-chevron-right"></i> <a href="#donate">Donate</a></li>
              </ul>
            </div>

            <div className="col-lg-2 col-md-6 footer-links">
              <h4>Our Services</h4>
              <ul style={{ paddingTop: '20px' }}>
                <li><i className="bx bx-chevron-right"></i> <a href="#" onClick={() => navigate("/map")}>Tribal Map</a></li>
                <li><i className="bx bx-chevron-right"></i> <a href="#" onClick={() => navigate("/campsiteQuestions")}>Tribal Interface</a></li>
              </ul>
            </div>

            <div className="col-lg-4 col-md-6 footer-newsletter">
              <h4>Our Blog</h4>
              <p>We are still working on it! Subscribe and you will be notified when it's ready.</p>
              
                  <PosaljiMail onSubscribe={handlePopupOpen} />
             
            </div>

          </div>
        </div>
      </div>

      <div className="container">
        <div className="copyright">
          &copy; Copyright <strong><span>Tribal Camping</span></strong>
        </div>
      </div>

      <Popup isVisible={isPopupVisible} onClose={handlePopupClose} />
    
    </footer>
  );
}

export default Footer;
