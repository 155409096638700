import logo from './logo.svg';
import './App.css';
import Home from './AppPageFolder/pages/Home';
import CampsitePage from './AppPageFolder/pages/CampsitePage.js';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from './LandingFolder/pages/LandingPage.js';
import Router1 from './StartingSpotPicker/Pages/Router1';
import { useEffect } from 'react';
import goodSpots from "./goodSpots.json";
global.pageObject = {
  changed: false,
  pageNumber: 0,
  selectedFeatures: localStorage.getItem('previousSelectedFeatures') || ""
}

const ip_map = {
  test: "http://localhost",
  spremo_test: "http://192.168.1.81",
  spremo_gajba_test: "http://192.168.1.38",
  viktor_test: "http://192.168.1.22",
  production: "https://tribalcamping.co",
  production2: "https://spremo.tech",
}
global.SERVIP = ip_map.production;

global.FRONTURL = global.SERVIP + ":3000";
if (global.SERVIP == ip_map.production || global.SERVIP == ip_map.production2) {
  global.FRONTURL = global.SERVIP;
}

function App() {
  if (!localStorage.getItem("notFirst")) {
    localStorage.setItem("notFirst", 1);
    //localStorage.setItem("center", [48.6316, 15.9092]);
    localStorage.setItem("groups", JSON.stringify(goodSpots))
    localStorage.setItem("initialZoom", 5);
    localStorage.setItem("isMap", 0)
  }
  return (
    <Router>
      <Routes>
        {/* Other routes here */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/map" element={<Home />} />
        <Route path="/campsite" element={<CampsitePage />} />
        <Route path="/campsiteQuestions/*" element={<Router1 />} />
      </Routes>
    </Router>
  );
}

export default App;
