import React, { useEffect, useState } from 'react';
import MapView from "../components/MapView";
import Sidebar from "../components/SideBar/Sidebar1";
import '../styles/Home.css';
import MapLoader from "../components//loaders/MapLoader";
import AlertModal from "../components/AlertModal";
import JSBI from 'jsbi';
import { fetchCookieCenterString } from '../utils/IPBase';
import { parseCoordinateString } from '../utils/Coordinate';
import countriesData from "country-list-js";
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import ListView from '../components/ListViewComponents/ListView';
import CampingResponsiblyModal from '../components/CampingResponsiblyModal';

const Home = () => {

  const [isLoading, setLoading] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [country, setCountry] = useState(localStorage.getItem("country_code") || "CH");
  const [featureBitMappings, setFeatureBitMappings] = useState({});
  const [features, setFeatures] = useState({});
  const [plainFeatures, setPlainFeatures] = useState([])
  const [lastLocationSearchQuery, setLastLocationSearchQuery] = useState(localStorage.getItem("lastLocationSearchQuery") || null);
  const location = useLocation();
  const [currentSidebar, setCurrentSidebar] = useState(window.matchMedia("(max-width: 768px)").matches ? (localStorage.getItem('campingSpots') ? 0 : 1) : 1)
  const [mapViewSelected, setMapViewSelected] = useState(localStorage.getItem("isMap")?(localStorage.getItem("isMap") == "1"? true:false):true)
  const [selectedFeatures, setSelectedFeatures] = useState(JSON.parse(localStorage.getItem('selectedFeatures')) || {
    highway: [],
    leisure: [],
    amenity: [],
    natural: [],
    tourism: [],
    waterway: [],
    waterarea: []
  });
  const [campingSpots, setCampingSpots] = useState(JSON.parse(localStorage.getItem('campingSpots')) || []);
  const [groups, setGroups] = useState(JSON.parse(localStorage.getItem('groups')) || []);
  const [center, setCenter] = useState(parseCoordinateString(localStorage.getItem('center') || "46.719,9.897"));
  const [potentialCampsiteNumber, setPotentialCampsiteNumber] = useState(0)

  const [shouldSearch, setShouldSearch] = useState(false)
  const [spotNumberLoading,setSpotNumberLoading] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("lastLocationSearchQuery", lastLocationSearchQuery)
  }, [lastLocationSearchQuery]);

  useEffect(() => {
    async function fetchData() {
      await fetchFeatureMapItems();
      await getSpot();
    }
    if (!localStorage.getItem("center")) {
      fetchCookieCenterString()
        .then(center_string => {
          setCountry(localStorage.getItem("country_code") || "CH")
          setCenter(parseCoordinateString(center_string));
          localStorage.setItem("center", center_string)
        })
    }
    fetchData();
  }, []);

  useEffect(() => {
    localStorage.setItem("country_code", country);
  }, [country])
  useEffect(() => {
    if (!shouldSearch) return;
    handleSearch(shouldSearch);
    setShouldSearch(false)
  }, [shouldSearch])
  useEffect(() => {
    console.log("promena centra")

     getCampsitesNumber(selectedFeatures)
  }, [center])
  function setGroupsAndSpots(data) {
    setCampingSpots(data);
    setGroups([data]);
  }

  async function getSpot() {
    const params = new URLSearchParams(location.search);
    const ids = params.getAll('Ids');
    if (!ids.length) {
      return
    }
    try {
      const response = await fetch(`${global.SERVIP}/api/getCampsite?${params.toString()}`);
      const data = await response.json();
      setGroupsAndSpots(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    finally {
    }
  }
  async function fetchFeatureMapItems() {
    if(localStorage.getItem("bitmap"))
      {
       await setFeatureBitMappings(JSON.parse(localStorage.getItem("featureBitMappings")))
       await setFeatures(JSON.parse(localStorage.getItem("bitmap")))
        if(localStorage.getItem("bitmap2"))
          {
            await setPlainFeatures(JSON.parse(localStorage.getItem("bitmap2")))
          }
          else
          {
            await fetchAttributesFromApi();
          }
          return;
      }
    try {
      const response = await fetch(global.SERVIP + '/api/bitmap');
      if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.statusText}`);
      }
      const data = await response.json();
      const structuredData = {};

      data.forEach(category => {
        const categoryName = category.name.toLowerCase();
        structuredData[categoryName] = {};

        category.items.forEach(item => {
          structuredData[categoryName][item.name] = item.id;
        });
      });
      setFeatureBitMappings(structuredData);
      const searchFeatures = {}
      for (const category in structuredData) {
        searchFeatures[category] = Object.keys(structuredData[category]);
      }
      setFeatures(searchFeatures);
      localStorage.setItem("bitmap", JSON.stringify(searchFeatures));
      localStorage.setItem("featureBitMappings", JSON.stringify(structuredData));
      await fetchAttributesFromApi();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  async function fetchAttributesFromApi() {
    try {
      const response = await fetch(global.SERVIP + '/api/bitmap2');
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const res = await response.json();
      localStorage.setItem("bitmap2", JSON.stringify(res));
      setPlainFeatures(res)
    } catch (error) {
      console.error(`Error fetching attributes: ${error}`);
      return [];
    }
  }
  function findGroups(graph, data) {
    // Keeps track of the visited nodes
    const visited = new Array(graph.length).fill(false);

    function dfs(node, group) {
      // Mark the node as visited
      visited[node] = true;

      // Push the actual data object associated with the node into the group
      group.push(data[node]);

      // Recursively visit all neighbours
      if (!graph[node] || !Array.isArray(graph[node].neighbours)) {
        console.error('Error at node:', node, 'Value:', graph[node]);
      }

      for (const neighbour of graph[node].neighbours) {
        if (!visited[neighbour]) {
          dfs(neighbour, group);
        }
      }
    }

    const groups = [];

    // Iterate through all nodes and perform a DFS from each unvisited node
    for (let i = 0; i < graph.length; i++) {
      if (!visited[i]) {
        const group = [];
        dfs(i, group);
        groups.push(group);
      }
    }

    return groups;
  }





  // A dictionary to map feature names to their bit positions

  function getFeatureBitmapValue(category, feature) {
    if ((!featureBitMappings || !featureBitMappings[category]) && !localStorage.getItem("featureBitMappings")) {
      console.error(`Data not available for category: ${category}`);
      console.log(featureBitMappings)
      console.log(localStorage.getItem("featureBitMappings"))
      return JSBI.BigInt(0);
    }
    const isEmpty = Object.keys(featureBitMappings).length === 0;

    const currentBitMappings = !isEmpty?featureBitMappings:JSON.parse(localStorage.getItem("featureBitMappings"))
    const bitPosition = currentBitMappings[category][feature] - 1;

    if (bitPosition !== undefined) {
      return JSBI.leftShift(JSBI.BigInt(1), JSBI.BigInt(bitPosition));
    }
    return JSBI.BigInt(0);
  }



  function prepareDataForApi(selectedFeatures, includePage = true) {
    console.log("CENTER JE ")
    console.log(center);
    const [lat, lng] = center;

    let featuresBitmap = JSBI.BigInt(0);

    // Loop through each feature category
    Object.keys(selectedFeatures).forEach(category => {
      if (Array.isArray(selectedFeatures[category])) {
        selectedFeatures[category].forEach(feature => {
          featuresBitmap = JSBI.bitwiseOr(featuresBitmap, getFeatureBitmapValue(category, feature));
        });
      }
    });

    let obj = {
      Features: featuresBitmap.toString(), // convert BigInt to string
      Center: {
        Latitude: lat || 0,
        Longitude: lng || 0
      },
      Range: global.radius ? (global.radius * 1000) : 100000,
      CountryName: countriesData.findByIso2(country).name
    };

    if (includePage) {
      obj.PageNumber = global.pageObject.pageNumber;
    }

    return obj;
  }
  global.shouldDoNewSearch = () => {
    let location_query = "";
    if (global.locationSearchQuery && (global.locationSearchQuery != lastLocationSearchQuery)) {
      location_query = global.locationSearchQuery;
    }
    return (location_query ? true : false) || (global.pageObject.selectedFeatures != JSON.stringify(prepareDataForApi(selectedFeatures, false)))
  }
  const handleSearch = async (selectedFeatures) => {
    localStorage.setItem('lastVisitedCampsiteIndex', 0);

    let location_query = "";
    if (global.locationSearchQuery && (global.locationSearchQuery != lastLocationSearchQuery)) {
      location_query = global.locationSearchQuery;
    }
    // Create a search query from the selected features
    setLoading(true)
    global.pageObject.pageNumber += 1
    let doNewSearch = (location_query ? true : false) || (global.pageObject.selectedFeatures != JSON.stringify(prepareDataForApi(selectedFeatures, false)))
    if (doNewSearch) {
      setCampingSpots([])
      setGroups([])
      global.pageObject.pageNumber = 0;
    }
    try {
      const response = await fetch(global.SERVIP + '/api/search', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...prepareDataForApi(selectedFeatures), LocationSearchQuery: location_query }), // Send searchQuery as JSON
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonResponse = await response.json();

      const data = jsonResponse.spots; // Get the JSON data from the response

      if (location_query) {
        const { latitude, longitude } = { latitude: 0, longitude: 0 }
        let oldsf = prepareDataForApi(selectedFeatures, false);
        oldsf.Center.Latitude = jsonResponse.new_center[0];
        oldsf.Center.Longitude = jsonResponse.new_center[1];
        global.pageObject.selectedFeatures = JSON.stringify(oldsf)
        localStorage.setItem('previousSelectedFeatures', global.pageObject.selectedFeatures);
        setCenter(jsonResponse.new_center)
        localStorage.setItem("center", jsonResponse.new_center[0] + "," + jsonResponse.new_center[1])
        setLastLocationSearchQuery(location_query);
      }
      else if (!global.locationSearchQuery) {
        setLastLocationSearchQuery("");
      }

      const fullData = doNewSearch ? data : campingSpots.concat(data)

      if (!location_query && doNewSearch) {
        global.pageObject.selectedFeatures = JSON.stringify(prepareDataForApi(selectedFeatures, false))
        localStorage.setItem('previousSelectedFeatures', global.pageObject.selectedFeatures);
      }
      localStorage.setItem('campingSpots', JSON.stringify(fullData));
      // Assuming you'll also set groups in this function
      setCampingSpots(fullData);
      console.log("dfr")
      console.log(fullData[0])
      if (data.length == 0) {
        setIsAlertOpen(true)
        return;
      }
      else {
        setIsAlertOpen(false)
        // Ako je na fonu 
        if (window.matchMedia("(max-width: 768px)").matches && global.closeSidebar) {
          global.closeSidebar();
        }
      }

      const epsilonLat = 0.017;
      const epsilonLon = 0.01;

      const mapa = new Array(fullData.length).fill(null).map(() => ({ neighbours: [] }));

      for (let i1 = 0; i1 < fullData.length; i1++) {
        for (let i2 = i1 + 1; i2 < fullData.length; i2++) {
          const latDiff = Math.abs(fullData[i1].latitude - fullData[i2].latitude);
          const lonDiff = Math.abs(fullData[i1].longitude - fullData[i2].longitude);

          if ((latDiff <= epsilonLat && lonDiff <= epsilonLon) || (lonDiff <= epsilonLat && latDiff <= epsilonLon)) {
            mapa[i1].neighbours.push(i2);
            mapa[i2].neighbours.push(i1);  // Making the graph bidirectional
          }
        }
      }
      let g = findGroups(mapa, fullData);
      localStorage.setItem('groups', JSON.stringify(g));

      setGroups(g)

    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error('Error during search:', error);

    } finally {
      setLoading(false)
    }
  };
  useEffect(() => {
    async function fetchCampsiteNumber() {
      console.log("promena selected featura")
      console.log(global.pageObject.selectedFeatures)
      console.log(JSON.stringify(prepareDataForApi(selectedFeatures, false)))
      console.log(global.pageObject.selectedFeatures == JSON.stringify(prepareDataForApi(selectedFeatures, false)))
      
      await getCampsitesNumber(selectedFeatures);
      console.log("-------------");
      console.log(potentialCampsiteNumber);
    }
    
    fetchCampsiteNumber();
  }, [selectedFeatures]);
  const getCampsitesNumber = async (selectedFeatures) => {
    if(global.pageObject.selectedFeatures == JSON.stringify(prepareDataForApi(selectedFeatures, false)))
      {
          setPotentialCampsiteNumber( JSON.parse(localStorage.getItem('numberOfSpots')))
          return;
        
      }
    
   console.log("page features")
   console.log(global.pageObject.selectedFeatures)
   console.log("selected from storage")
   console.log(JSON.stringify(prepareDataForApi(selectedFeatures, false)))
   console.log(global.pageObject.selectedFeatures == JSON.stringify(prepareDataForApi(selectedFeatures, false)))
    setSpotNumberLoading(true)
    let location_query = "";
    if (global.locationSearchQuery && (global.locationSearchQuery != lastLocationSearchQuery)) {
      location_query = global.locationSearchQuery;
    }
    // Create a search query from the selected features
    
    try {
      const response = await fetch(global.SERVIP + '/api/getNumberOfCampsites', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...prepareDataForApi(selectedFeatures), LocationSearchQuery: location_query }), // Send searchQuery as JSON
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonResponse = await response.json();

      const numberOfSpots = jsonResponse.numberOfCampsites; // Get the JSON data from the response
        // Ako je na fonu 
        localStorage.setItem('numberOfSpots',JSON.stringify(numberOfSpots));
        setPotentialCampsiteNumber(numberOfSpots)

    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error('Error during search:', error);

    } finally {
      setSpotNumberLoading(false)
    }
  };
  const handleFeatureChange = async (category, feature, isChecked, selectedFeatures) => {
    setSelectedFeatures(prevSelected => {
      let updatedSelected;
  
      if (isChecked) {
        updatedSelected = {
          ...prevSelected,
          [category]: [...prevSelected[category] || [], feature]
        };
      } else {
        updatedSelected = {
          ...prevSelected,
          [category]: (prevSelected[category] || []).filter(f => f !== feature)
        };
      }
  
      localStorage.setItem('selectedFeatures', JSON.stringify(updatedSelected));
      return updatedSelected;
    }, async (updatedSelectedFeatures) => {
      console.log("promena selected featura 2")

      await getCampsitesNumber(updatedSelectedFeatures);
      console.log("-------------")
      console.log(potentialCampsiteNumber)
    });
  };
  


  return (
    <div style={{ display: 'flex',overflowX:'hidden' }}>
      <CampingResponsiblyModal></CampingResponsiblyModal>
      <Sidebar
        features={features}
        selectedFeatures={selectedFeatures}
        handleCheckboxChange={handleFeatureChange}
        handleSearch={handleSearch}
        lastLocationSearchQuery={lastLocationSearchQuery}
        center={center}
        setCenter={setCenter}
        setShouldSearch={setShouldSearch}
        country={country}
        setCountry={setCountry}
        featureBitMappings={featureBitMappings}
        currentSidebar={currentSidebar}
        setCurrentSidebar={setCurrentSidebar}
        potentialCampsiteNumber= {potentialCampsiteNumber}
        spotNumberLoading = {spotNumberLoading}
      />

      <div style={{ flex: 1, display: 'flex',justifyContent: 'center' }}>
        <AlertModal isOpen={isAlertOpen} onClose={() => setIsAlertOpen(false)} search={handleSearch} features={selectedFeatures} />



        {isLoading && (
          <div className="loading-container">
            <MapLoader />
          </div>
        )}
        {mapViewSelected? <MapView campingSpots={campingSpots} groups={groups} center={center} setCenter={setCenter} setLoading={setLoading} plainFeatures={plainFeatures} setMapViewSelected={setMapViewSelected}/>
        : 
        (!mapViewSelected ? <ListView campingSpots={campingSpots} campSites={groups}  center={center} setMapViewSelected={setMapViewSelected}/>:null)}
          
      </div>

    </div>
  );
};

export default Home;