import React from 'react';
import './Map.css';
import MapCard from '../components/MapCard';

function Map() {

  return (
    
    <section id="map" class="contact">
    <div class="container-fluid">
          <div class="section-title padingl" data-aos="fade-up">
          <h2 style={{ marginLeft: '0', textAlign: 'left' }}>Map</h2>

            <p>Try Tribal</p>

          </div>

            <div class="row">
              <div class="containercard">
              <MapCard
                    title="Simple Search"
                    imageUrl="https://i.imgur.com/vAKw4JI.jpeg"
                    description="Simple search helps you quickly and effortlessly find the best places. It's recommended if you're using Tribal for the first time."
                    buttonText="Try It!"
                    buttonLink="/campsiteQuestions"
                />
                <MapCard
                    title="Advanced Search"
                    imageUrl="https://i.imgur.com/feuhcLP.jpeg"
                    description="Advanced search offers more options and broader place choices, but it's less intuitive. If you're familiar with Tribal or maps in general, we recommend this."
                    buttonText="Try It!"
                    buttonLink="/map"
                />
              </div>

            </div>
      
     </div>
     
      
  </section>

  );
}

export default Map;