import { useEffect, useState } from "react";

import WaterwaysIcon from '../../../assets/waterways.png';
import TourismIcon from '../../../assets/tourism.png';
import NaturalIcon from '../../../assets/natural.png';
import LeisureIcon from '../../../assets/leisure.png';
import LandUsesIcon from '../../../assets/landuses.png';
import HighwaysIcon from '../../../assets/highways.png';
import AmenitiesIcon from '../../../assets/bonfire.png';
import WaterAreasIcon from '../../../assets/waterareas.png';
import close from '../../../assets/close.png';
import '../../../styles/SideBar.css'
import CheckBox from "../../CheckBox/CheckBox";
import '../../CheckBox/CheckBox.css'
import JSBI from 'jsbi';
import countriesData from "country-list-js";
import emojiMap from "../../../utils/EmojiMap";
import './searchStyle.css'
import './AdvancedSearch.css'
import AdvancedFeaturesList from "./searchComponents/AdvancedFeaturesList";

const category_names = {
  "highway": "Travel methods",
  "leisure": "Leisure",
  "amenity": "Amenity",
  "natural": "Natural",
  "tourism": "Tourism",
  "waterway": "Waterways",
  "waterarea": "Water areas",
}

const countries = countriesData.ls('iso2');

countries.sort((a, b) => {
  const nameA = countriesData.findByIso2(a).name;
  const nameB = countriesData.findByIso2(b).name;

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
});
function AdvancedSearch({
  features,
  selectedFeatures,
  handleCheckboxChange,
  handleSearch,
  center,
  setCenter,
  closeSidebar,
  featureBitMappings,
  lastLocationSearchQuery,
  country,
  setCountry,
  potentialCampsiteNumber,
  spotNumberLoading,
  ...otherProps
}) {
  const [locationSearchQuery, setLocationSearchQuery] = useState(localStorage.getItem("locationSearchQuery") || "");
  const [radius, setRadius] = useState(localStorage.getItem("radius") || "100")
  const [pCampsiteNumber, setPCampsiteNumber] = useState(potentialCampsiteNumber)
  global.radius = radius;
  global.locationSearchQuery = locationSearchQuery;

  const icons = {
    waterway: WaterwaysIcon,
    tourism: TourismIcon,
    natural: NaturalIcon,
    leisure: LeisureIcon,
    highway: HighwaysIcon,
    amenity: AmenitiesIcon,
    waterarea: WaterAreasIcon
  };

  useEffect(() => {
    global.setLocationSearchQuery = setLocationSearchQuery;
    if (locationSearchQuery)
      localStorage.setItem("locationSearchQuery", locationSearchQuery);
    else
      localStorage.removeItem("locationSearchQuery")
  }, [locationSearchQuery])

  useEffect(() => {
    global.radius = radius;
    global.setRadiusAdvancedSearch = setRadius
    localStorage.setItem("radius", radius);
  }, [radius]);


  function getFeatureBitmapValue(category, feature) {
    if (!featureBitMappings || !featureBitMappings[category]) {
      console.error(`Data not available for category: ${category}`);
      return JSBI.BigInt(0);
    }

    const bitPosition = featureBitMappings[category][feature] - 1;

    if (bitPosition !== undefined) {
      return JSBI.leftShift(JSBI.BigInt(1), JSBI.BigInt(bitPosition));
    }
    return JSBI.BigInt(0);
  }

  function prepareDataForApi(selectedFeatures, includePage = true) {
    const [lat, lng] = center;

    let featuresBitmap = JSBI.BigInt(0);

    // Loop through each feature category
    Object.keys(selectedFeatures).forEach(category => {
      if (Array.isArray(selectedFeatures[category])) {
        selectedFeatures[category].forEach(feature => {
          featuresBitmap = JSBI.bitwiseOr(featuresBitmap, getFeatureBitmapValue(category, feature));
        });
      }
    });
    let obj = {
      Features: featuresBitmap.toString(), // convert BigInt to string
      Center: {
        Latitude: lat || 0,
        Longitude: lng || 0
      },
      Range: radius * 1000 || 10000000,
      CountryName: countriesData.findByIso2(country).name
    };

    if (includePage) {
      obj.PageNumber = global.pageObject.pageNumber;
    }

    return obj;
  }


  return (
    <form style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: '15px',
      paddingRight: '15px',
      background: '#121212', // Setting the background color
      color: '#fff', // Setting the text color to white for better contrast
      borderRadius: '5px', // Optional: Adding some border radius
      maxHeight:'93vh',
    }} className="searchForm">

      <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'center' , marginTop:5}}>
      <a style={{
    color: '#ccc', // Light gray color for readability against a dark background
    backgroundColor: '#222', // Dark background to match the theme
    padding: '5px 10px', // Padding for better spacing
    borderRadius: '5px', // Rounded corners for a softer look
    textDecoration: 'none', // Remove underline typically found on links
    display: 'inline-block', // Ensures padding and background color are applied correctly
    margin: '10px 0', // Vertical spacing
    border: '1px solid #444' // Subtle border to add depth
}}>
    Number of spots around location : <a style={{color:"orange"}}>{spotNumberLoading? "Loading...":potentialCampsiteNumber}</a>
</a>


      </div>
      <AdvancedFeaturesList
        features={features}
        handleCheckboxChange={handleCheckboxChange}
        selectedFeatures={selectedFeatures}
      />


      {/* ... other form elements ... */}
      <div >
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
          <a style={{ color: 'white', fontWeight: 'bolder', fontSize: '0.9em' }}>Look for a cool place in</a>
          <select
            value={country}
            style={{
              backgroundColor: '#1a1a1a',
              color: 'white',
              border: 'none',
              width: '100%',
              margin: '5px 0',
              padding: '5px',
              borderRadius: '5px',
              outline: 'none'
            }} onChange={(e) => {
              setCountry(e.target.value)
            }}>
            {countries.map(e => <option value={e}>{countriesData.findByIso2(e).name}</option>)}
            {/* Add more country options as needed */}
          </select>
          <a style={{ color: 'white', fontWeight: 'bolder', fontSize: '0.9em' }}>Around (defaults to marker)</a>
          <input onChange={(e) => {
            global.locationSearchQuery = e.target.value;
            setLocationSearchQuery(e.target.value)
          }}
            value={locationSearchQuery} placeholder='Type location name ' className="input"></input>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <a style={{ color: 'white', fontWeight: 'bolder', fontSize: '0.9em' }}>in radius (km)</a>

          <input type="number" onChange={(e) => setRadius(Number(e.target.value))}
            value={radius} placeholder='Enter radius' className="input"></input>
        </div>

      </div>
      <button
        onClick={(e) => {
          e.preventDefault(); // prevent form submission
          localStorage.setItem("initialZoom", 12);
          handleSearch(selectedFeatures, locationSearchQuery); // call the search handler with selectedFeatures
        }}
        className="prompt-search-button"        style={{
width:'100%',
backgroundColor:'#ec6313',
          padding: '10px 20px', // Adding padding
          cursor: 'pointer', // Making it look clickable
          marginTop: '10px', // Adding some margin at the top
        }}
      >
        {(global.shouldDoNewSearch && global.shouldDoNewSearch()) ? "Search" : "Load more"}
      </button>
    </form>
  );

}

export default AdvancedSearch