import React from 'react';
import CountUp from 'react-countup';
import './Counts.css';

function Counts() {
  return (
    <section id="counts" className="counts">
      <div className="container">
        <div className="row" data-aos="fade-up">
          <div className="col-lg-3 col-md-6">
            <div className="count-box">
              <i className="bx bx-map"></i>
              <CountUp start={0} end={62394} duration={5} /> {/* Ispravljeno: koristimo CountUp komponentu */}
              <p>Mapped Spots</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
            <div className="count-box">
              <i className="ri-map-2-line"></i>
              <CountUp start={0} end={23} duration={5} />
              <p>Mapped Countries</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
            <div className="count-box">
              <i className="ri-tent-line"></i>
              <CountUp start={0} end={16} duration={5} />
              <p>Community Members</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
            <div className="count-box">
              <i className="ri-price-tag-3-line"></i>
              <CountUp start={0} end={37} duration={1} />
              <p>Search Categories</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Counts;
