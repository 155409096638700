import '../../styles/SideBar.css'
function SideBarButton({
    action,
    icon,
    tooltipContent
})
{
    return (
        <div onClick={action}  style={{ '--tooltip-content': `"${tooltipContent}"` }} className='sidebarButton'>
            <img src = {icon} style = {styles.icon} >
            </img>
        </div>
    )
}

export default SideBarButton

const styles = {
   
    icon: {
        width: 25,
        height: 25,
    },
};