import './RatingComponent.css'
import ReportButton from './ReportButton'
import { useState } from 'react';
function RatingComponent({report, RateCampsite})
{
    const [rating, setRating] = useState(null);

    // Function to handle the change of rating
    const handleRatingChange = (event) => {
      const selectedRating = event.target.value;
      setRating(selectedRating); // Update the local rating state
      // Call RateCampsite with the selected value
      RateCampsite(selectedRating);
    };
    return(
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap:10}}>
       <div className="rating">
        <input
          value="5"
          name="rating"
          id="star5"
          type="radio"
          checked={rating === '5'}
          onChange={handleRatingChange}
        />
        <label htmlFor="star5"></label>
        <input
          value="4"
          name="rating"
          id="star4"
          type="radio"
          checked={rating === '4'}
          onChange={handleRatingChange}
        />
        <label htmlFor="star4"></label>
        <input
          value="3"
          name="rating"
          id="star3"
          type="radio"
          checked={rating === '3'}
          onChange={handleRatingChange}
        />
        <label htmlFor="star3"></label>
        <input
          value="2"
          name="rating"
          id="star2"
          type="radio"
          checked={rating === '2'}
          onChange={handleRatingChange}
        />
        <label htmlFor="star2"></label>
        <input
          value="1"
          name="rating"
          id="star1"
          type="radio"
          checked={rating === '1'}
          onChange={handleRatingChange}
        />
        <label htmlFor="star1"></label>
      </div>
<a style={{color:'whitesmoke'}}> 345 reviews
</a>

<ReportButton onClick={report}/>
</div>

    )
}

export default RatingComponent