import React from "react";
import './ButtonStyles.css'

function GoogleEarthButton(props) {
  console.log("LAT I LONG U GOOGLE EARTH BUTTOJN")
  console.log(props)

  return (
    <a className="prompt-search-button" style={{textDecoration: 'none'}} href={(props?.lat && props?.lng) ? `https://earth.google.com/web/@${props.lat},${props.lng},5000a,0d,30y,0h,0t,0r` : "#"}
      target="_blank"
      rel="noopener noreferrer">

     
        <span className="text-2">Google Earth</span>
    </a>
  )
}

export default GoogleEarthButton