import SideBarButton
 from "../Buttons/SidebarButton"

import search from '../../assets/SidebarIcons/search.png'
import advancedSearch from '../../assets/SidebarIcons/prompt.png'
import info from '../../assets/SidebarIcons/info.png'
import instagram from '../../assets/SidebarIcons/instagram.png'
import tiktok from '../../assets/SidebarIcons/tiktok.png'
import discord from '../../assets/SidebarIcons/discord.png'
import roadmap from '../../assets/SidebarIcons/roadmap.png'
import email from '../../assets/SidebarIcons/email.png'
import donation from '../../assets/SidebarIcons/donation.png'
import { useNavigate } from 'react-router-dom';
import { useState } from "react"
function StartingSidebar({
    setState,
    setCloseButtonVisible,
    ...otherProps 
}) {

    const navigate = useNavigate()

    const navigateNavbar=(state)=>{
       if(state == 0)
        setCloseButtonVisible(false)

        setState(state)

    }
    return(
        <div style={styles.sidebarContainer}>
            <div>
            <SideBarButton tooltipContent="Our regular search, just pick the features and we will find you the best spots near you." action={() => navigateNavbar(1)} icon={search}/>
            <SideBarButton tooltipContent="This one's experimental. Use the textbox to describe your perfect campsite and we will find you some." action={() => navigateNavbar(2)} icon={advancedSearch}/>
            <SideBarButton tooltipContent="Having problems searching for campsites?" action={() => navigateNavbar(3)} icon={info}/>
            </div>
            <div>
            <SideBarButton tooltipContent="See what the project is going to be and leave suggestions for new countries and features" action={() => navigateNavbar(4)} icon={roadmap}/>
            <SideBarButton tooltipContent="We don't make money from this, support us with a donation " action={() => navigateNavbar(5)} icon={donation}/>
            </div>
            <div>
            <SideBarButton tooltipContent="Contact us" action={() => navigateNavbar(6)} icon={email}/>
       
            </div>
        </div>
    );
}

export default StartingSidebar

const styles = {
    sidebarContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#121212',
        width: '60px', // Adjust as needed
        boxShadow: '2px 0px 5px rgba(0,0,0,0.1)',
    },
    buttonsContainer: {
        paddingTop: '20px', // You can adjust the top padding here
    },
};