import React, { useState } from 'react';
import './Drugi.css';
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

function Drugi() {
    const [radius, setRadius] = useState(100);
    const location = useLocation();
    const [mostCommonNatureFeatures, setMostCommonNatureFeatures] = useState([])
    const [potentialCampsites, setPotentialCampsites] = useState([])
    const locationCoordinates = location.state ? location.state.locationCoordinates : [0, 0]; // default to [0, 0] if state is not passed

    const navigate = useNavigate();

    const handleClick = (e) => {
        // Ovde možete staviti kod koji želite da se izvrši za answer1
        e.preventDefault(); // Ovo će sprečiti podrazumevano ponašanje Link komponente
        window.location.href = "/campsiteQuestions/treci"; // Ručno promenite rutu
    };
    const getAvailableRegions = async () => {
        const requestBody = {
            latitude: locationCoordinates[0],
            longitude: locationCoordinates[1],
            radius: radius * 1000
        };
        try {
            const response = await fetch(global.SERVIP + '/api/getMostCommonNaturalFeatures', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });
            const data = await response.json();
            
            console.log(data);
            navigate("/campsiteQuestions/cetvrti", { state: { mostCommonNatureFeatures: data.top4NatureFeatures, spots : data.spots, locationCoordinates: locationCoordinates, radius: radius } })
        } catch (error) {
            console.error("There was an error fetching the regions:", error);
        }
    };

    return (
        <div className="limiter">
            <div className="container-login100">
                <div style={{backgroundColor:'#ffffff',margin:19}} className="wrap-login100">
                    <form className="login100-form validate-form">
                        <h2>
                            How far are you willing to travel?
                        </h2>

                        <div id="form-wrapper">
                            <div id="debt-amount-slider">
                                <input 
                                    type="range" 
                                    min="20" 
                                    max="200" 
                                    step="10" 
                                    color='orange'
                                    value={radius} 
                                    onChange={(e) => setRadius(e.target.value)}
                                />
                                <label>{radius} km</label>
                            </div>
                        </div>

                        <div className="container-login100-form-btn">
                                <button type='button' 
                                onClick={() => {
                                    console.log(location.state);
                                    getAvailableRegions();
                                }}  
                                id="answer1"className="login100-form-btn" style={{ backgroundColor: 'orange', borderColor:'white' , borderRadius:10}}>
                                    Continue
                                </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Drugi;
