import DesktopListView from "./DesktopListView"

function ListView({
    campSites,
    center,
    radius,
    setMapViewSelected,
    campingSpots
}){
    return(
        <div style={{ height: "100vh", width: '100%' }}>
            <DesktopListView setMapViewSelected={setMapViewSelected} campSites2={campSites} center={center} campingSpots={campingSpots}/>
        </div>
    )
}

export default ListView