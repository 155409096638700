import React from 'react';
import '../styles/DonationPage.css'
import close from '../assets/close.png'
function DonationPage({closeSidebar}) {
    return (
        <div className="containerRoad">
           
                <h1 style={{color:"whitesmoke"}}>Support Our Cause</h1>
                <br/>
            <div className="intro" >
                <p style={{color:"whitesmoke"}}>
                    Greetings, fellow campers. We've built this platform with the vision of a collaborative space where the wonders of the great outdoors meet the spirit of innovation. While our mission remains steadfast, we rely on the generosity of users like you to keep going. Since we don't monetize this platform, every donation is a step towards maintaining and enhancing our services. If you've ever felt the fusion of the serenity of camping, we urge you to help us keep Tribal alive.
                </p>
                <a href="https://www.buymeacoffee.com/tribalcamping" target="_blank" rel="noopener noreferrer nofollow" className="donateButton">Donate Now</a>
            </div>
        </div>
    );
}

export default DonationPage;
