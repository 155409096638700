// Hero.js
import React, { useState } from 'react';
import './Hero.css';
import PosaljiMail from './PosaljiMail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import Popup from './Popup';

function Hero() {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handlePopupClose = () => setIsPopupVisible(false);
  const handlePopupOpen = () => setIsPopupVisible(true);

  return (
    <section id="hero">
      <Popup isVisible={isPopupVisible} onClose={handlePopupClose} />

      <div className="container style_padding">
        <div className="center-link">
          <a href="#map">
            <FontAwesomeIcon icon={faArrowDown} className="arrow-icon" />
          </a>
        </div>

        <div className="row justify-content-between">
          <div className="col-lg-7 pt-5 pt-lg-0 order-2 order-lg-1 d-flex align-items-center">
            <div data-aos="zoom-out">
              <h1>Looking for a camping spot? Try <span>Tribal Camping</span></h1>
              <h2>Embrace Adventure and Go Wild with Tribal Camping<br />Keep in mind we are still working on it</h2>
            
              <div className="text-center text-lg-start">
                <div className="footer-newsletter" style={{ maxWidth: '90%', overflow: 'auto', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                  <PosaljiMail onSubscribe={handlePopupOpen} jelhome = {true} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 order-1 order-lg-2 hero-img custom-div" data-aos="zoom-out" data-aos-delay="300">
            <img src="/assets/img/homescreen.png" width="555px" className="large-image animated" title="source: imgur.com" alt="app-example" />
            <div className="mobile-content"></div>
          </div>
        </div>

        
      </div>

      <svg className="hero-waves" viewBox="0 24 150 28" preserveAspectRatio="none">
        <defs>
          <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
        </defs>
        <g className="wave1">
          <use xlinkHref="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)" />
        </g>
        <g className="wave2">
          <use xlinkHref="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)" />
        </g>
        <g className="wave3">
          <use xlinkHref="#wave-path" x="50" y="9" fill="#fff" />
        </g>
      </svg>
    </section>
  );
}

export default Hero;
