import React from 'react';
import './Features.css';

function Features() {
  return (
    <section id="features" className="features">
      <div className="container">

        <div className="section-title" data-aos="fade-up">
          <h2 style={{ marginLeft: '0', textAlign: 'left' }}>Features</h2>
          <p>We are currently mapping</p>
        </div>

        <div className="row" data-aos="fade-left">
          <div className="col-lg-3 col-md-4">
            <div className="icon-box" data-aos="zoom-in" data-aos-delay="50">
              <i className="flag-icon flag-icon-gb zemljaopis"></i>
              <h3><a href="">UK</a></h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 mt-4 mt-md-0">
            <div className="icon-box" data-aos="zoom-in" data-aos-delay="100">
              <i className="flag-icon flag-icon-fr zemljaopis"></i>
              <h3><a href="">France</a></h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 mt-4 mt-md-0">
            <div className="icon-box" data-aos="zoom-in" data-aos-delay="150">
              <i className="flag-icon flag-icon-de zemljaopis"></i>
              <h3><a href="">Germany</a></h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 mt-4 mt-lg-0">
            <div className="icon-box" data-aos="zoom-in" data-aos-delay="200">
              <i className="flag-icon flag-icon-it zemljaopis"></i>
              <h3><a href="">Italy</a></h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 mt-4">
            <div className="icon-box" data-aos="zoom-in" data-aos-delay="250">
              <i className="flag-icon flag-icon-es zemljaopis"></i>
              <h3><a href="">Spain</a></h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 mt-4">
            <div className="icon-box" data-aos="zoom-in" data-aos-delay="300">
              <i className="flag-icon flag-icon-pl zemljaopis"></i>
              <h3><a href="">Poland</a></h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 mt-4">
            <div className="icon-box" data-aos="zoom-in" data-aos-delay="350">
              <i className="flag-icon flag-icon-ro zemljaopis"></i>
              <h3><a href="">Romania</a></h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 mt-4">
            <div className="icon-box" data-aos="zoom-in" data-aos-delay="400">
              <i className="flag-icon flag-icon-nl zemljaopis"></i>
              <h3><a href="">Netherlands</a></h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 mt-4">
            <div className="icon-box" data-aos="zoom-in" data-aos-delay="450">
              <i className="flag-icon flag-icon-gr zemljaopis"></i>
              <h3><a href="">Greece</a></h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 mt-4">
            <div className="icon-box" data-aos="zoom-in" data-aos-delay="500">
              <i className="flag-icon flag-icon-se zemljaopis"></i>
              <h3><a href="">Sweden</a></h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 mt-4">
            <div className="icon-box" data-aos="zoom-in" data-aos-delay="600">
              <i className="flag-icon flag-icon-hu zemljaopis"></i>
              <h3><a href="">Hungary</a></h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 mt-4">
            <div className="icon-box" data-aos="zoom-in" data-aos-delay="650">
              <i className="flag-icon flag-icon-at zemljaopis"></i>
              <h3><a href="">Austria</a></h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 mt-4">
            <div className="icon-box" data-aos="zoom-in" data-aos-delay="700">
              <i className="flag-icon flag-icon-cz zemljaopis"></i>
              <h3><a href="">Czech Republic</a></h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 mt-4">
            <div className="icon-box" data-aos="zoom-in" data-aos-delay="750">
              <i className="flag-icon flag-icon-be zemljaopis"></i>
              <h3><a href="">Belgium</a></h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 mt-4">
            <div className="icon-box" data-aos="zoom-in" data-aos-delay="800">
              <i className="flag-icon flag-icon-bg zemljaopis"></i>
              <h3><a href="">Bulgaria</a></h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 mt-4">
            <div className="icon-box" data-aos="zoom-in" data-aos-delay="850">
              <i className="flag-icon flag-icon-ch zemljaopis"></i>
              <h3><a href="">Switzerland</a></h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 mt-4">
            <div className="icon-box" data-aos="zoom-in" data-aos-delay="900">
              <i className="flag-icon flag-icon-fi zemljaopis"></i>
              <h3><a href="">Finland</a></h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 mt-4">
            <div className="icon-box" data-aos="zoom-in" data-aos-delay="950">
              <i className="flag-icon flag-icon-rs zemljaopis"></i>
              <h3><a href="">Serbia</a></h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 mt-4">
            <div className="icon-box" data-aos="zoom-in" data-aos-delay="1000">
              <i className="flag-icon flag-icon-si zemljaopis"></i>
              <h3><a href="">Slovenia</a></h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 mt-4">
            <div className="icon-box" data-aos="zoom-in" data-aos-delay="1050">
              <i className="flag-icon flag-icon-ba zemljaopis"></i>
              <h3><a href="">Bosnia and Herzegovina</a></h3>
            </div>
          </div>
        </div>

      </div>
    </section>
  );
}

export default Features;

