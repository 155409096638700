import './DesktopListViewComponents.css'
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { distance } from '@turf/turf';

function DesktopListViewCard({
    data,
    name,
    plainFeatures,
    center,
    image,
    setCurrentCampsiteIndex,
    campsiteIndex
}){
  const [spotImage, setSpotImage] = useState("")

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Assuming fetchImagesForSpots is an async function returning a promise
        await fetchImageForSpot(data[0].id);

      } catch (error) {
        console.error('Failed to fetch images:', error);
      }
    };

    // Call the fetchData function
    fetchData();

    // Cleanup function if needed
    return () => {
      // Cleanup actions (e.g., canceling API requests)
    };
  }, []);

    const navigate = useNavigate()
    function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
        const R = 6371; // Radius of the earth in km
        const dLat = deg2rad(lat2 - lat1);
        const dLon = deg2rad(lon2 - lon1);
        const a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
          Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c; // Distance in km
        return Math.round(distance,2);
      }
      
      function deg2rad(deg) {
        return deg * (Math.PI / 180);
      }
      
    const navigateToCampsite = async (coordinates, group, plainFeatures, center, distance) => {
        if (!plainFeatures || plainFeatures.length == 0)
          return;
        const dataToPass = {
          coordinates,
          group,
          plainFeatures,
          center,
          distance
        };
    
        navigate('/campsite', { state: dataToPass });
      };
    const showCampsite = () =>{
        console.log("klik") 

        localStorage.setItem('lastVisitedCampsiteIndex', campsiteIndex);

        setCurrentCampsiteIndex(campsiteIndex)
        navigateToCampsite( { lat: data[0].latitude , lng: data[0].longitude }, data, plainFeatures, center, getDistanceFromLatLonInKm(data[0].latitude ,data[0].longitude, center[0], center[1] ))
    }

    const unzipData = (array) => {
      return array.map(e => {
        const split = e.split('|');
        let originalUrl;
    
        if (split[0].startsWith('g/')) {
          originalUrl = `https://lh5.googleusercontent.com/p/${split[0].substring(2)}`;
        } else if (split[0].startsWith('f')) {
          const parts = split[0].substring(1).split('/');
          const farm_number = `farm${parts[0]}`;
          const directory = parts[1];
          const filename = `${parts[2]}.jpg`;
    
          originalUrl = `https://${farm_number}.staticflickr.com/${directory}/${filename}`;
        } else {
          originalUrl = "Invalid URL";
        }
    
        return ({
          url: originalUrl,
          lat: parseFloat(split[1]),
          lng: parseFloat(split[2]),
          grade: 1
        });
      });
    }
    
    const fetchImageForSpot = async (id) => {
      try {
        const response = await fetch(global.SERVIP + '/api/getSingleImagePerSpot', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(id)
        });
    
        if (response.ok) {
          const data = await response.text();
    
          // Assume data is an array of URLs
          console.log("DATAJE")
          console.log(data);
         
          setSpotImage(unzipData([data])[0].url);
          
        } else {
          console.error("Failed to fetch images", await response.text());
        }
      } catch (error) {
        console.error("Error while fetching images:", error);
      }
    }
    
    // Function to replace the size in the URL
    function replaceUrlSize(url) {
      return url
    }
    
    return(
        <div className='dlvdiv' id={"CardDiv"+campsiteIndex}>
       <div className="dlvcard" style={{ flex: 2, position: 'relative', backgroundColor: spotImage.length==0 ? "black": "" }}>
 {spotImage.length>0 ? <img src={spotImage} style={{
    width: '100%', // Makes the image fill the width of the card
    height: '100%', // Makes the image fill the height of the card
    position: 'absolute', // Positions the image absolutely within the card
    top: 0,
    left: 0,
    objectFit: 'cover', // Ensures the image covers the area without distorting aspect ratio
    zIndex: 1 // Ensures the image stays behind the content
  }} /> : <div style={{width:"100%", height:"100%", display:'flex', justifyContent:'center', alignItems:"center"}}><a style={{color:'white', fontFamily:"Open+Sans", font:"1.2em"}}>Loading image ...</a></div>}
  <div className="dlvcard__content" style={{
    position: 'relative', // Ensures the text content is positioned above the image
    zIndex: 2, // Ensures the text content is visible above the image
    color: 'white', // Changes text color to white for better visibility
    padding: '20px', // Adds padding around the text content
    backgroundColor: 'rgba(0,0,0,0.9)' // Optional: Adds a semi-transparent background to enhance text visibility
  }}>
    <p className="dlvcard__title">{name}</p>
  </div>
</div>

<div style={{display:'flex',flex:2, flexDirection:'column', justifyContent:'space-around', color:'white', marginLeft:10}}>
    <h2 className="dlvcard__title2">{name}</h2>
    <h5 className="dlvcard__content2">Distance: {getDistanceFromLatLonInKm(data[0].latitude ,data[0].longitude, center[0], center[1] )}km from selected location</h5>
    <h5 className="dlvcard__content2">Area: {data.length} km^2</h5>
    <div style={{width:'100%', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
    <button className="prompt-search-button prompt-search-button2"  onClick={()=>showCampsite()}>Explore</button>
    </div>
  </div>
</div>

    )
}

export default DesktopListViewCard