
import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Prvi from './Prvi';
import Drugi from './Drugi';
import Cetvrti from './Cetvrti';
import Hi from './Hi';
import FeaturePick from './FeaturePick';
import './Router1.module.css';

const Router1 = () => {
  useEffect(() => {
    document.body.style.height = "100%";
    document.body.style.fontFamily = "Poppins-Regular, sans-serif";
    document.body.style.backgroundColor = "#F2F2F2";
    document.documentElement.style.height = "100%"; // za <html>
    document.documentElement.style.width = "100%";
    document.documentElement.style.margin = 0;
    document.documentElement.style.padding = 0;
    return () => {
      document.body.style.height = "";
      document.body.style.fontFamily = "";
      document.body.style.backgroundColor = "";
      document.documentElement.style.height = ""; // za <html>
      document.documentElement.style.width = "";
    };
  }, []);

  return (
  <Routes>
    <Route path="/" element={<Hi />} />
    <Route path="/Prvi" element={<Prvi />} />
    <Route path="/Drugi" element={<Drugi />} />
    <Route path="/Cetvrti" element={<Cetvrti />} />
    <Route path="/Peti" element={<FeaturePick />} />
  </Routes>
);
  };
export default Router1