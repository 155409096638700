const natureImages = {
    "foot": { name: "Hiking", emoji: "👣" },
    "car": { name: "Driving", emoji: "🚗" },
    "grassland": { name: "Meadow", emoji: "🌾" ,image : 'https://media.istockphoto.com/id/1299224032/photo/natural-mountains-and-grassland.webp?b=1&s=170667a&w=0&k=20&c=QP0Bz7hTtOeolwbKHGmoxRHWFqsI-MktV75Qqa6ein0='},
    "peak": { name: "Peak", emoji: "🏔️", image: "https://upload.wikimedia.org/wikipedia/commons/5/5b/7_15_BroadPeak.jpg" },
    "viewpoint": { name: "Viewpoint", emoji: "🔭" },
    "scrub": { name: "Shrubland", emoji: "🌿" , image:"https://conps.org/wp-content/uploads/2021/03/Big-Sagebrush-Pseudorogneria_spicata-Mo-Ewing.jpg"},
    "spring": { name: "Spring", emoji: "💧" , image:"https://upload.wikimedia.org/wikipedia/commons/0/07/Big_Spring_Missouri_1-02Aug08.jpg"},
    "heath": { name: "Heath", emoji: "🍂", image:"http://t2.gstatic.com/licensed-image?q=tbn:ANd9GcRL60_D3eIIRv8wab6izPOLMWS5juo1Y3gam4072UjHZ42G-e8zxkAgOIKl7sCrwyvVI8duEUHAZtgrezfk51Y" },
    "cliff": { name: "Cliff", emoji: "🏞️", image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXn41NiJ8vfXqrVJnuIIX4yD4Etcan6vOLBA&usqp=CAU" },
    "wood": { name: "Forest", emoji: "🌲", image:"https://images.nationalgeographic.org/image/upload/v1638892272/EducationHub/photos/hoh-river-valley.jpg" },
    "stream": { name: "Stream", emoji: "🌊" },
    "alpine_hut": { name: "Mountain Hut", emoji: "🏡" },
    "water": { name: "Water", emoji: "💧" , image:"https://cdn.britannica.com/97/158797-050-ABECB32F/North-Cascades-National-Park-Lake-Ann-park.jpg?w=400&h=300&c=crop"},
    "river": { name: "River", emoji: "🌊" },
    "reservoir": { name: "Reservoir", emoji: "🚰" },
    "lake": { name: "Lake", emoji: "🏞️" },
    "nature_reserve": { name: "Nature Reserve", emoji: "🌲" },
    "waterfall": { name: "Waterfall", emoji: "💦" },
    "ridge": { name: "Ridge", emoji: "⛰️", image:"https://upload.wikimedia.org/wikipedia/commons/a/ae/Tsubakurodake_from_Otenshodake_2002-8-22.jpg" },
    "glacier": { name: "Glacier", emoji: "❄️", image:"https://cdn.hswstatic.com/gif/gettyimages-523785064.jpg" },
    "toilets": { name: "Toilets", emoji: "🚽" },
    "shelter": { name: "Shelter", emoji: "⛺" },
    "valley": { name: "Valley", emoji: "🏞️", image:"https://www.ouinolanguages.com/assets/French/vocab/58/images/pic17.jpg" },
    "bench": { name: "Bench", emoji: "🪑" },
    "wilderness_hut": { name: "Wilderness Hut", emoji: "🏚️" },
    "drinking_water": { name: "Drinking Water", emoji: "🚰" },
    "bicycle": { name: "Cycling", emoji: "🚲" },
    "hunting_stand": { name: "Hunting Stand", emoji: "🏹" },
    "picnic_table": { name: "Picnic Table", emoji: "🪑" },
    "place_of_worship": { name: "Place of Worship", emoji: "⛪" },
    "fell": { name: "Fell", emoji: "⛰️", image:"" },
    "pond": { name: "Pond", emoji: "🌊" },
    "cave_entrance": { name: "Cave", emoji: "🕳️", image:"https://www.collinsdictionary.com/images/full/cave_474904921_1000.jpg" },
    "bbq": { name: "BBQ", emoji: "🍖" },
    "firepit": { name: "Firepit", emoji: "🔥" },
    "fishing": { name: "Fishing", emoji: "🎣" },
    "grave_yard": { name: "Graveyard", emoji: "⚰️" },
    "shower": { name: "Shower", emoji: "🚿" },
    "beach": { name: "Beach", emoji: "🏖️", image:"https://images.fineartamerica.com/images/artworkimages/mediumlarge/2/remote-beach-in-lagoa-portugal--allard-schager.jpg" },
    "wildlife_hide": { name: "Wildlife Hide", emoji: "🦌" },
    "stream_pool": { name: "Stream Pool", emoji: "🌊" },
    "horse": { name: "Horseback", emoji: "🐎" },
    "boat_rental": { name: "Boat Rental", emoji: "🚤" },
    "bicycle_rental": { name: "Bicycle Rental", emoji: "🚲" },
    "caravan_site": { name: "Caravan Site", emoji: "🚐" },
    "bay": { name: "Bay", emoji: "🏝️", image:"https://useruploads.socratic.org/h5gj1iBSxyuGBmSmyjwL_imageSrc.adapt.687.HIGH.jpg" },
    "tundra": { name: "Tundra", emoji: "🌨️" , image:"https://images.nationalgeographic.org/image/upload/v1638892272/EducationHub/photos/yukon-tundra.jpg"},
    "peninsula": { name: "Peninsula", emoji: "🌊", image:"https://eartheclipse.com/wp-content/uploads/2019/05/Peninsula.jpg" },
    "volcano": { name: "Volcano", emoji: "🌋" , image:"https://p.potaufeu.asahi.com/b8e2-p/picture/27647979/85590a730296775d2abfd145147330e5.jpg"},
    "oxbow": {name:"Oxbow", emoji : "🏞️"},
    "hot_spring": {name:"Hot spring", emoji : "♨️", image:"https://thumbor.bigedition.com/travertine-hot-spring/BtFIct-AcPVMqVSG4ldAdew9O10=/71x0:1185x835/800x0/filters:quality(80)/granite-web-prod/ba/e4/bae43730305a4d52af1591455c731c74.jpeg"},
    "climbing": {name:"Climbing", emoji : "🧗"},
};

export default natureImages;
