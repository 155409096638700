import React from "react";
import '../../styles/MapLoader.css'

 function MapLoader() {
  return (
<div className="container1">
  <div className="tree">
    <div className="branch" style={{ '--x': 0 }}>
      <span style={{ '--i': 0 }}></span>
      <span style={{ '--i': 1 }}></span>
      <span style={{ '--i': 2 }}></span>
      <span style={{ '--i': 3 }}></span>
    </div>
    <div className="branch" style={{ '--x': 1 }}>
      <span style={{ '--i': 0 }}></span>
      <span style={{ '--i': 1 }}></span>
      <span style={{ '--i': 2 }}></span>
      <span style={{ '--i': 3 }}></span>
    </div>
    <div className="branch" style={{ '--x': 2 }}>
      <span style={{ '--i': 0 }}></span>
      <span style={{ '--i': 1 }}></span>
      <span style={{ '--i': 2 }}></span>
      <span style={{ '--i': 3 }}></span>
    </div>
    <div className="branch" style={{ '--x': 3 }}>
      <span style={{ '--i': 0 }}></span>
      <span style={{ '--i': 1 }}></span>
      <span style={{ '--i': 2 }}></span>
      <span style={{ '--i': 3 }}></span>
    </div>
    <div className="stem">
      <span style={{ '--i': 0 }}></span>
      <span style={{ '--i': 1 }}></span>
      <span style={{ '--i': 2 }}></span>
      <span style={{ '--i': 3 }}></span>
    </div>
    <span className="shadow"></span>
  </div>
  <a style={{marginTop: '30px', fontSize:'2em', color:'white', fontFamily:'Handjet'}}>Searching ...</a>
</div>


  );
}
export default MapLoader