import React from 'react';
import '../../styles/MapView.css';
import AdvancedSearch from './Search/AdvancedSearch';
import { useState } from 'react';
import WaterwaysIcon from '../../assets/waterways.png';
import TourismIcon from '../../assets/tourism.png';
import NaturalIcon from '../../assets/natural.png';
import LeisureIcon from '../../assets/leisure.png';
import LandUsesIcon from '../../assets/landuses.png';
import HighwaysIcon from '../../assets/highways.png';
import AmenitiesIcon from '../../assets/bonfire.png';
import WaterAreasIcon from '../../assets/waterareas.png';
import PromptSearch from './Search/PromptSearch';
import StartingSidebar from './StartingSidebar';
import Info from './Info';
import '../../styles/SideBar.css'
import ProjectRoadmap from '../../pages/ProjectRoadmap';
import DonationPage from '../../pages/DonationPage';
import close from '../../assets/close.png';
import ContactPage from '../../pages/ContactPage';

const icons = {
  waterway: WaterwaysIcon,
  tourism: TourismIcon,
  natural: NaturalIcon,
  leisure: LeisureIcon,
  highway: HighwaysIcon,
  amenity: AmenitiesIcon,
  waterarea: WaterAreasIcon
};

function Sidebar1({
  features,
  selectedFeatures,
  handleCheckboxChange,
  setSidebarOpen,
  handleSearch,
  lastLocationSearchQuery,
  center,
  setCenter,
  setShouldSearch,
  open,
  country,
  setCountry,
  featureBitMappings,
  currentSidebar,
  setCurrentSidebar,
  potentialCampsiteNumber,
  spotNumberLoading,
  ...otherProps }) {
    const [closeButtonVisible,setCloseButtonVisible] =useState(true)
  function closeSidebar() {
    setCurrentSidebar(0)
  }
  global.closeSidebar = closeSidebar;
  const sidebarScreens = [
    <StartingSidebar setState={setCurrentSidebar} setCloseButtonVisible={setCloseButtonVisible}        closeSidebar={closeSidebar}
/>,
    <AdvancedSearch
      features={features}
      selectedFeatures={selectedFeatures}
      handleCheckboxChange={handleCheckboxChange}
      handleSearch={handleSearch}
      center={center}
      setCenter={setCenter}
      closeSidebar={closeSidebar}
      featureBitMappings={featureBitMappings}
      lastLocationSearchQuery={lastLocationSearchQuery}
      country={country}
      setCountry={setCountry}
      potentialCampsiteNumber={potentialCampsiteNumber}
      spotNumberLoading = {spotNumberLoading}
    />,
    <PromptSearch
      setCenter={setCenter}
      setShouldSearch={setShouldSearch}
      closeSidebar={closeSidebar}
      advancedSearch={() => setCurrentSidebar(1)}
      showHelp={() => setCurrentSidebar(3)}
      country={country}
      setCountry={setCountry}
      center={center}
    />
    ,
    <Info
      promptSearch={(query) => {
        setTimeout(() => {
          if (global.setSearchQuery) {
            const queryChars = query.split(''); // Split the query into individual characters
            let currentQuery = '';

            const interval = setInterval(() => {
              if (queryChars.length > 0) {
                currentQuery += queryChars.shift(); // Remove and get the first character from the array
                global.setSearchQuery(currentQuery); // Set the updated query
              } else {
                clearInterval(interval); // Stop the interval when all characters have been appended
              }
            }, 10); // Append a character every 50ms
          }
        }, 500);

        setCurrentSidebar(2)
      }}
      regularSearch={() => setCurrentSidebar(1)}
      closeSidebar={closeSidebar}
    />,
    <ProjectRoadmap
      closeSidebar={closeSidebar}


    />,
    <DonationPage
      closeSidebar={closeSidebar}
    />,
      <ContactPage
      closeSidebar={closeSidebar}
      />
  ]
  return (
    <div className="sidebar" style={{overflowX:'hidden'}}>
      {currentSidebar!=0?<div style={{display: 'flex', justifyContent: 'flex-start', alignItems:'self-start',width:'100%'}}>
                <img src={close} style={{width: 30, height: 30, marginLeft:10 }} className="close-icon" onClick={closeSidebar} alt="close"/>
            </div>:null}
      {sidebarScreens[currentSidebar]}
    </div>
  );
}
export default Sidebar1;

//  <button className="changeFilter"
//onClick={()=>{setToggleAdvanced(!toggleAdvanced)}}>{!toggleAdvanced? "Use Advanced Search":"Back to regular search"}</button>
/*
          <div style={{display: 'flex', width: '100%', justifyContent:'flex-start'}}>
          <img src={require('../assets/close.png')} style={{width:30,height:30}} onClick={()=>closeSidebar()}></img>
          </div>
*/