import React from "react";
import './ButtonStyles.css'

function DirectionsButton(props) {

  return (
    <a className="prompt-search-button"style={{ textDecoration: 'none' }} href={(props?.lat && props?.lng) ? `https://www.google.com/maps/dir/?api=1&destination=${props.lat},${props.lng}` : "#"}
      target="_blank"
      rel="noopener noreferrer">


      <span className="text-2">Show directions</span>
    </a>
  )
}

export default DirectionsButton