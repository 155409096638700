import React, { useState, useEffect } from 'react';
import './Prvi.css';
import { useNavigate } from "react-router-dom";
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, useMap } from 'react-leaflet';
import { fetchCenterIpBase } from '../../AppPageFolder/utils/IPBase';

import markerImg from './assets/destination.png'
import { Icon } from 'leaflet'; 

const customMarkerIcon = new Icon({
    iconUrl: markerImg, 
    iconSize: [60, 60], 
    iconAnchor: [30, 30], 
    popupAnchor: [0, -30] 
});

function Prvi() {
  
  
  const [locationCoordinates, setLocationCoordinates] = useState([0, 0]);
  const [country, setCountry] = useState('United Kingdom');
  const [place, setPlace] = useState('London');
  const [showMap, setShowMap] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchLocation = async () => {
        try {
            const data = await fetchCenterIpBase();
            setLocationCoordinates([data.latitude, data.longitude]);
            setPlace(data.city);
            setCountry(data.country_name);
        } catch (error) {
            console.error('Error fetching location:', error);
        }
    };
    fetchLocation();
  }, []);

  const handleAnswer2Click = (e) => {
    e.preventDefault();
    setShowMap(true);
  };

  const getPlaceFromCoordinates = async (lat, lon) => {
    const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`);
    const data = await response.json();
	if(! data.address)
		return
    const newPlace =data.address.city || data.address.state || data.address.town;
    setPlace(newPlace);
    setCountry(data.address.country);
  };

  const getCoordinatesFromPlace = async () => {
    alert('Please');
    const response = await fetch(`https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(place)}&format=json`);
    const data = await response.json();
    if (data && data.length) {
        const latitude = parseFloat(data[0].lat);
        const longitude = parseFloat(data[0].lon);
        setLocationCoordinates([latitude, longitude]);
		setCountry(" ")
        const map = window.myMap;
        map.setView([latitude, longitude], map.getZoom());
    }
  };

  const handleCustomLocationChange = (e) => {
    setPlace(e.target.value);
  };

  const MapClickHandler = () => {
    useMapEvents({
      click: (e) => {
        const { lat, lng } = e.latlng;
        setLocationCoordinates([lat, lng]);
        getPlaceFromCoordinates(lat, lng);
        localStorage.setItem('center', `${lat},${lng}`);
      },
    });
    return null;
  };

  const UseMapInstance = ({ onInstance }) => {
    const map = useMap();
    useEffect(() => {
        if (onInstance) onInstance(map);
    }, [map, onInstance]);
    return null;
  };

  return (
    <div className="limiter">
      <div className="container-login100">
      <div style={{backgroundColor:'#ffffff',margin:19}} className="wrap-login100">
          <form className="login100-form validate-form">
            <h2  style={{ textAlign: 'center', marginBottom:20 }}>Where would you like to camp?</h2>
            <p style={{ textAlign: 'center', marginBottom:20 }}>You're currently searching for places near: {place}, {country}</p>

            <div className="container-login100-form-btn">
              {!showMap && (
                <div >
                    <button onClick={handleAnswer2Click}  className="login100-form-btn" style={{ backgroundColor: 'orange', borderColor:'white',marginBottom:10}} >
                        Use other location
                    </button>
                </div>
              )}
              {showMap && (
                <>
                <div style={{display:'flex', flexDirection: 'column'}}>
                     <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <input 
                            type="text" 
                            placeholder="Enter a location" 
                            value={place}
                            onChange={handleCustomLocationChange}
                            style={{ backgroundColor: "#f5f5f5", margin: 10, width: '80%', padding: 10 }}
                        />
                        <button type="button" onClick={getCoordinatesFromPlace} className="login100-form-btn" style={{ color: 'black', width: '40%' }}>
                            Set Place
                        </button>
                    </div>
                    <MapContainer 
                        zoomControl={false}
                        center={locationCoordinates} 
                        zoom={13} 
                        style={{ width: '100%', height: '300px' }}
                    >
                        <UseMapInstance onInstance={map => { window.myMap = map; }}/>
                        <MapClickHandler/>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        {locationCoordinates && (
                            <Marker icon={customMarkerIcon} position={locationCoordinates}>
                                <Popup>
                                    This is your main point of search <br />
                                </Popup>
                            </Marker>
                        )}
                    </MapContainer>
                </div>
 
                </>
              )}

              
            </div>

            {!showMap &&  (<p style={{ textAlign: 'center', fontSize: 20 }}>Or</p>
             )}
            <div style={{ display: 'flex',marginTop: 10,  justifyContent: 'center' }}>
                {showMap ? (
                <button
                  onClick={() => navigate("/campsiteQuestions/drugi", { state: { locationCoordinates: locationCoordinates } })}
                  id="answer2"
                  className="login100-form-btn"
                  style={{ backgroundColor: 'orange', borderColor: 'white' }}
                >
                  Next
                </button>
              ) : (
                <button
                  onClick={() => navigate("/campsiteQuestions/drugi", { state: { locationCoordinates: locationCoordinates } })}
                  id="answer2"
                  className="login100-form-btn"
                  style={{ backgroundColor: 'orange', borderColor: 'white' }}
                >
                  Use your Current location
                </button>
              )}
              </div>
          </form>
          
        </div>
      </div>
    </div>
  );
}

export default Prvi;
