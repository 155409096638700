import React from 'react';
import { useState } from 'react';
import './FeaturePick.css';
import { useLocation } from 'react-router-dom';
import natureImages from './assets/natureFeaturesImages/natureImages';
import { useNavigate } from "react-router-dom";

function FeaturePick() {

  const location = useLocation();
  const navigate = useNavigate();

  const [radius, setRadius] = useState(location.state ? location.state.radius : []);


  const [selectedFeatureId, setSelectedFeatureId] = useState(-1);
	const [mostCommon4Features,setMostCommon4Features] = useState(location.state ? location.state.mostCommon4Features : [])
  const [spots, setSpots] = useState(location.state ? location.state.spots : []);
  const [groups, setGroups] = useState([])
    const handleClick = (e, index) => {
      if(selectedFeatureId !=index)
      setSelectedFeatureId(index)
    else
    setSelectedFeatureId(-1)
        // Ovde možete staviti kod koji želite da se izvrši za answer1
      };
      function findGroups(graph, data) {
        // Keeps track of the visited nodes
        const visited = new Array(graph.length).fill(false);
    
        function dfs(node, group) {
          // Mark the node as visited
          visited[node] = true;
    
          // Push the actual data object associated with the node into the group
          group.push(data[node]);
    
          // Recursively visit all neighbours
          if (!graph[node] || !Array.isArray(graph[node].neighbours)) {
            console.error('Error at node:', node, 'Value:', graph[node]);
          }
    
          for (const neighbour of graph[node].neighbours) {
            if (!visited[neighbour]) {
              dfs(neighbour, group);
            }
          }
        }
    
        const groups = [];
    
        // Iterate through all nodes and perform a DFS from each unvisited node
        for (let i = 0; i < graph.length; i++) {
          if (!visited[i]) {
            const group = [];
            dfs(i, group);
            groups.push(group);
          }
        }
    
        return groups;
      }
    
      const getGroups= () =>{
        const epsilonLat = 0.017;
        const epsilonLon = 0.01;
  
        const mapa = new Array(spots.length).fill(null).map(() => ({ neighbours: [] }));
  
        for (let i1 = 0; i1 < spots.length; i1++) {
          for (let i2 = i1 + 1; i2 < spots.length; i2++) {
            const latDiff = Math.abs(spots[i1].latitude - spots[i2].latitude);
            const lonDiff = Math.abs(spots[i1].longitude - spots[i2].longitude);
  
            if ((latDiff <= epsilonLat && lonDiff <= epsilonLon) || (lonDiff <= epsilonLat && latDiff <= epsilonLon)) {
              mapa[i1].neighbours.push(i2);
              mapa[i2].neighbours.push(i1);  // Making the graph bidirectional
            }
          }
        }
        let g = findGroups(mapa, spots);
        localStorage.setItem('groups', JSON.stringify(g));
  
        setGroups(g)
      }
      const handleNextClick = (e) => {
        // Ovde možete staviti kod koji želite da se izvrši za answer1
        e.preventDefault(); // Ovo će sprečiti podrazumevano ponašanje Link komponente
        console.log(mostCommon4Features) // Ručno promenite rutu
        localStorage.setItem("initialZoom", 12);
        finishSearch()
      };
      const finishSearch = () => {
        getGroups()
        localStorage.setItem('campingSpots', JSON.stringify(spots));
        localStorage.setItem("center", location.state.locationCoordinates[0] + "," + location.state.locationCoordinates[1])
        localStorage.setItem("radius", radius);
        console.log(spots)
        console.log(groups)

        navigate("/map")
      }
  return (
    <div className="limiter-cetvrti">
    <div className="container-cetvrti">
        <div className="wrap-cetvrti">
            <h2>Pick one must-have thing?</h2>
            <p style={{fontSize:'1.2em'}}>This is just to get familiar with Tribal, you will be able to search for more things after this step *</p>
           <div className='seperatorForFeaturePick'></div>

            <form className="login100-form validate-form login100-form4">
                {mostCommon4Features.map((feature, index) => (
                    <div 
                    key={index} 
                    onClick={(e) => handleClick(e, index)} 
                    className={`image-feature-container1 ${selectedFeatureId === index ? 'selected-feature1' : ''}`} 
                >
                    <p style={{width: '100%', height:50, display:'flex', justifyContent: 'center', alignItems: 'center', fontSize:'1.1em', color:'black',border: '2px solid orange'}}>{natureImages[feature.name].name} {natureImages[feature.name].emoji}</p>
                </div>
                ))}
            </form>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button onClick={handleNextClick} className="login100-form-btn" style={{ backgroundColor: 'orange', borderColor:'white' , borderRadius:10}}>
                  {selectedFeatureId != -1 ? "Next" : "Doesn't matter"}
              </button>
            </div>

        </div>
    </div>
</div>
  );
}

export default FeaturePick;