import '../styles/ProjectRoadmap.css'
import close from '../assets/close.png';

function ProjectRoadmap({
    closeSidebar
}) {
    return (
        <div>
           
        <div className="containerRoad" style={{color:'#f5f5f5'}}>
                
            <header style={{color:'#f5f5f5'}}>
                <h1>Tribal: Your Ultimate Camping Guide</h1>
            </header>
            
            <section className="intro" >
                <p style={{color:'#f5f5f5'}}>
                    How hard is it finding a perfect camping spot near you? With Tribal, it's as easy as it can be.
                </p>
                <p style={{color:'#f5f5f5'}}>
                    Our mission is to find the camping spots tailored to your desires. Desiring a place with a waterfall and a cave? We've got you covered with over 20 suggestions.
                </p>
                <p style={{color:'#f5f5f5'}}> 
                    But this is just the tip of the iceberg. Our vision extends beyond a mere search engine. Imagine a social platform where undiscovered campsites meet passionate campers. Whether you're planning a secluded retreat or a thrilling group adventure, Tribal is your go-to destination.
                </p>
            </section>

            <section className="phase">
    <h2 >Phase 1: Campsite Search Engine</h2>
    <p style={{color:'#f5f5f5'}}>Welcome to Tribal's Phase 1. This is where our journey began:</p>
    
    <ul className="feature-list">
        <li >
            <span className="emoji">📍</span>
            <strong>Discover Unique Spots:</strong> From waterfalls, lakes, and caves to firepits and more. We bring you sites you won't find elsewhere.
        </li>
        <li>
            <span className="emoji">🌐</span>
            <strong>Revolutionary Tech:</strong> Experience our avant-garde image recognition and map scraping technology, changing how you discover camping spots.
        </li>
        <li>
            <span className="emoji">🗺️</span>
            <strong>Detailed Mapping:</strong> Every camping feature you desire, we have it mapped out, ensuring you find the perfect spot every time.
        </li>
    </ul>
    <p>Dive in and find the camping site of your dreams!</p>
</section>

            <section className="phase">
    <h2>Phase 2: Social Camping</h2>
    <p style={{color:'#f5f5f5'}}>Welcome to Tribal's Phase 2! 🎉</p>
    <p style={{color:'#f5f5f5'}}>While Phase 1 helped you discover magical camping spots, Phase 2 is all about elevating your camping experience:</p>
    
    <ul className="feature-list">
        <li>
            <span className="emoji">📝</span>
            <strong>Better Planning:</strong> Make detailed lists for your trips. Get suggestions on what to bring based on your camping spot. Save maps and routes for easy navigation.
        </li>
        <li>
            <span className="emoji">🤝</span>
            <strong>Connect & Share:</strong> Share that perfect spot with friends! Organize group trips, set dates, and reminisce with photos and memories.
        </li>
        <li>
            <span className="emoji">🌐</span>
            <strong>Global Camper Community:</strong> Connect with campers from around the world. Camp together, share experiences, and make lifelong friends.
        </li>
        <li>
            <span className="emoji">🏆</span>
            <strong>Exciting Challenges:</strong> Fun activities await based on your camping region, from mushroom picking 🍄 and wildlife snaps 📸 to trail adventures 🚶‍♂️. And there's plenty more!
        </li>
    </ul>
    <p>Stay tuned for more enhancements to your camping adventures with Tribal!</p>
</section>
<section className="phase">
    <h2>Phase 3: TBD</h2>
    <p style={{color:'#f5f5f5'}}>If we get there, phase 3 includes some crazy features, stay tuned...</p>
    
</section>
<section className="phase">
    <h2>Not available for your country?</h2>
    <p style={{color:'#f5f5f5'}}>Click the button below vote for your country to be mapped next. No payment requiered.</p>
    <a href="https://www.buymeacoffee.com/tribalcamping" target="_blank" rel="noopener noreferrer nofollow" className="donateButton">Check it out</a>

</section>
        </div>
        </div>
    )
}


export default ProjectRoadmap