import { useMap } from "react-leaflet";
import { useEffect } from "react";
function MapEvents(props) {
  const map = useMap();

  useEffect(() => {
    if (props.defaultCenter) {
      map.flyTo({ lat: props.defaultCenter[0], lon: props.defaultCenter[1] });
    }
  }, [map, props.defaultCenter]);

  useEffect(() => {
    if (props.campingSpots && props.campingSpots.length > 0 && props.campingSpots[0]) {
      map.flyTo([props.campingSpots[0].latitude, props.campingSpots[0].longitude]);
    }
  }, [props.campingSpots, map]);

  return null; // No need to render anything, this component only handles the map events
}
export default MapEvents