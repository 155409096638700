import React from "react";
import './CheckBox.css';

function CheckBox({ name, radnja, category, feature, selectedFeatures }) {
  return (
    <label className="checkbox-btn">
      <input 
        onChange={(e) => radnja(category, feature, e.target.checked, selectedFeatures)} 
        name={name} 
        checked={selectedFeatures[category].includes(feature)} 
        type="checkbox" 
      />
      <span className="checkmark"></span>
    </label>
  );
}

export default CheckBox;
