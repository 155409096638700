import React, { useState } from 'react';
import './Faq.css';

function Faq() {
  const [openFaq, setOpenFaq] = useState(null);

  return (
    <section id="faq" className="faq section-bg">
      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2 style={{ marginLeft: '0', textAlign: 'left' }}>F.A.Q</h2>
          <p className="zafaq">Frequently Asked Questions</p>
        </div>

        <div className="faq-list">
          <ul>
            {renderFaqItem(1, "How to choose the right campsite?", 
              "Select a campsite that is safe, fits your needs, and complies with local regulations. Research potential sites, consider factors like accessibility, proximity to water sources, and the type of camping experience you desire.")}
            {renderFaqItem(2, "What equipment do I need for camping?", 
              "Camping equipment varies depending on the type of camping you plan. Common items include a tent, sleeping bag, sleeping pad, cooking equipment, and appropriate clothing. Customize your gear list based on your specific trip.")}
            {renderFaqItem(3, "How to prepare for bad weather during camping?", 
              "Check the weather forecast before your trip and pack accordingly. Bring waterproof gear, extra clothing layers, and a quality tent that can handle adverse conditions.")}
            {renderFaqItem(4, "How to safely build a fire and cook outdoors?", 
              "Learn proper fire-building techniques, use designated fire rings or stoves when available, and follow Leave No Trace principles. Keep a safe distance from the fire and cook with the right cookware.")}
            {renderFaqItem(5, "How to minimize your environmental impact when camping?", 
              "Practice Leave No Trace principles, pick up all trash, stay on established trails, and be mindful of wildlife. Respect local regulations and protect the environment for future generations.")}
          </ul>
        </div>
      </div>
    </section>
  );

  function renderFaqItem(id, question, answer) {
    return (
      <li data-aos="fade-up" className='faq-item'>
        
        <i className="bx bx-help-circle icon-help"></i>
        <a
          href="#"
          className={openFaq === id ? "active" : ""}
          onClick={(e) => {
            e.preventDefault();
            setOpenFaq(openFaq === id ? null : id);
          }}
        >
          {question} <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i>
        </a>
        {openFaq === id && (
          <div>
            <p><i>{answer}</i></p>
          </div>
        )}
      </li>
    );
  }
}

export default Faq;
