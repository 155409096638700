import React, { useEffect } from 'react';
import "./LandingPage.css";

import Header from '../components/Header';
import Hero from '../components/Hero';

import Map from '../sections/Map';
import About from '../sections/About';
import Features from '../sections/Features';
import Counts from '../sections/Counts';
import Contact from '../sections/Contact';
import Donate from '../sections/Donate';
import Faq from '../sections/Faq';
import Footer from '../components/Footer';
import Phases from '../sections/Phases';
import Eco from '../sections/Eco';
import DisplayApp from '../sections/DisplayApp';

function LandingPage() {


  useEffect(() => {
    // Stil za h2
    const h2Elements = document.querySelectorAll("h2");
    h2Elements.forEach(h2 => {
      h2.style.textAlign = "left";
      h2.style.paddingBottom = "0";
    });
    return () => {
      
      // Resetuj stil za h2 prilikom demontaže
      h2Elements.forEach(h2 => {
        h2.style.textAlign = "";
        h2.style.paddingBottom = "";
      });
    };
  }, []);

    return (
      <div>
      <Header />
      <Hero />
      <main id="main">
        <Map />
        <About />
        <DisplayApp />
        <Features />
        <Counts />
        <Phases />
        <Donate />
        <Faq />
        <Eco />
        <Contact />
        <Footer />
      </main>
  
    </div>
  );
  }
  
  export default LandingPage;