import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, LayersControl, ZoomControl, useMapEvents, Marker, Popup, Polygon } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { XMLParser } from 'react-xml-parser';
import L from 'leaflet';
import { polygon, convex, destination, bearing, centroid, point, featureCollection } from '@turf/turf';
import camp from '../assets/bonfire.png'
import { useMap, useMapEvent } from 'react-leaflet';
import MapEvents from './MapHelpers/MapEvents';
import * as turf from "@turf/turf";
import { parseCoordinateString } from '../utils/Coordinate';
import { useNavigate } from 'react-router-dom';
import pin from '../../StartingSpotPicker/Pages/assets/destination.png'
const { BaseLayer, Overlay } = LayersControl;

const campIcon = L.icon({
  iconUrl: camp, // replace with the actual path
  iconSize: [60, 60], // size of the icon
  iconAnchor: [30, 30], // point of the icon which will correspond to marker's location, centered
  popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
});

const pinIcon = L.icon({
  iconUrl: pin, // replace with the actual path
  iconSize: [60, 60], // size of the icon
  iconAnchor: [30, 60], // point of the icon which will correspond to marker's location, centered
  popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
});
export default function MapView(props) {
  const center = props.center;
  const setCenter = props.setCenter;
  const [spotImages, setSpotImages] = useState([])
  const [currentGroup, setCurrentGroup] = useState([])
  const [zoom, setZoom] = useState(12)
  const [groups, setGroups] = useState([])
  const surroundingPointsCache = new Map();
  const navigate = useNavigate();

  /*useEffect(()=>{
    localStorage.setItem("initialZoom", 12);
  }, [])*/
  useEffect(() => {


    const newcnt = props.center;
    for (let i = 0; i < 2; i++) {
      if (Math.abs(newcnt[i] - center[i]) > 0.000001) {
        setCenter(newcnt)
        return;
      }
    }
    setGroups(props.groups)
  }, [props])


  const addSurroundingPoints = (coord) => {
    // Directions in degrees for up, down, left, right
    const cardinalDirections = [0, 180, 270, 90];
    const diagonalDistance = 1; // For 0.5km diagonally
    // Directions in degrees for top-left, top-right, bottom-left, bottom-right
    const diagonalDirections = [315, 45, 225, 135];

    const newPoints = [];

    cardinalDirections.forEach(direction => {
      const translatedPoint = turf.destination(coord, 1, direction);
      newPoints.push(translatedPoint.geometry.coordinates);
    });

    diagonalDirections.forEach(direction => {
      const translatedPoint = turf.destination(coord, diagonalDistance, direction);
      newPoints.push(translatedPoint.geometry.coordinates);
    });

    return newPoints;
  };

  const getSurroundingPoints = (coord) => {
    const key = `${coord[0].toFixed(5)},${coord[1].toFixed(5)}`;
    if (surroundingPointsCache.has(key)) {
      return surroundingPointsCache.get(key);
    }

    const result = addSurroundingPoints(coord);
    surroundingPointsCache.set(key, result);
    return result;
  };

  const pointToString = pt => `${pt[0].toFixed(5)},${pt[1].toFixed(5)}`;
  const stringToPoint = str => str.split(',').map(Number);
  const navigateToCampsite = async (images, coordinates, group, plainFeatures, center) => {
    if (!plainFeatures || plainFeatures.length == 0)
      return;
    const dataToPass = {
      coordinates,
      group,
      plainFeatures,
      center
    };

    navigate('/campsite', { state: dataToPass });
  };
  const renderPolygonsAndMarkers = () => {
    const polygons = [];
    const markers = [];

    groups.forEach((group, index) => {
      let uniqueSurroundingPoints = new Set();
      let spot_id, spot_counter = 0;
      group.forEach(spot => {

        const coord = [spot.longitude, spot.latitude];
        if (spot_counter == 0) {
          spot_id = spot.longitude + ',' + spot.latitude
        }
        if (spot_counter == group.length - 1) {
          spot_id += spot.longitude + ',' + spot.latitude;
        }
        spot_counter++;
        const surroundingPoints = getSurroundingPoints(coord);
        surroundingPoints.forEach(pt => {
          uniqueSurroundingPoints.add(pointToString(pt));
        });
      });

      // Convert the Set back to an array of points for further calculations
      let surroundingPointsList = [...uniqueSurroundingPoints].map(stringToPoint);

      if (group.length >= 3) {
        const hull = convex(featureCollection(group.map(spot => point([spot.longitude, spot.latitude]))));
        if (!hull || hull.geometry.coordinates[0].length < 3) {

        }
        else {
          surroundingPointsList = surroundingPointsList.filter(pt => !turf.booleanPointInPolygon(pt, hull));
        }
      }

      // Compute the convex hull of the surrounding points
      const newHull = convex(featureCollection(surroundingPointsList.map(pt => point(pt))));
      if (!newHull) return;

      const newHullCoordinates = newHull.geometry.coordinates[0].map(coord => [coord[1], coord[0]]);
      polygons.push(<Polygon key={spot_id + 'p'} positions={newHullCoordinates} color="#ec6313"
        eventHandlers={{
          click: async (e) => {
           
            setCurrentGroup(group);
            navigateToCampsite(spotImages, { lat: position[0], lng: position[1] }, group, props.plainFeatures, center)
          },
          // You can keep the mouseover event or remove it based on your requirements
          mouseover: (e) => {
          }
        }}
      />);

      // Compute centroid for the marker placement
      const centroid = turf.centroid(newHull);
      const position = [centroid.geometry.coordinates[1], centroid.geometry.coordinates[0]];
      markers.push(
        <Marker
          key={spot_id + 'm'}
          position={position}
          icon={campIcon}
          eventHandlers={{
            click: async (e) => {
             


              setCurrentGroup(group);

              navigateToCampsite(spotImages, { lat: position[0], lng: position[1] }, group, props.plainFeatures, center)

            },
            // You can keep the mouseover event or remove it based on your requirements
            mouseover: (e) => {

            }
          }}
        >
          <Popup style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          </Popup>
        </Marker>



      );
    });
    return [...polygons, ...markers.concat(<Marker key={markers.length} position={[center[0], center[1]]} icon={pinIcon}>
      <Popup>{"This is the center of your search"}</Popup>
    </Marker>)];
  };

  const MapClickHandler = (props) => {
    useMapEvents({
      click: (e) => {
        const { lat, lng } = e.latlng;
        setCenter([lat, lng])
        if (global.setLocationSearchQuery)
          global.setLocationSearchQuery("");
        const latFixed = parseFloat(lat).toFixed(5);
        const lngFixed = parseFloat(lng).toFixed(5);
        const latLngString = lat + "," + lng;
        localStorage.setItem('center', latLngString);
      },
    });

    return null; // This component doesn't render anything
  };

  const MapUpdater = ({ sidebarOpen }) => {
    const map = useMap();

    useEffect(() => {
      map.invalidateSize();
      map.setView(center)
    }, [sidebarOpen, map]);

    return null; // This component doesn't render anything
  };
  console.log("ZOOM JE "+zoom)
  return (
    <div style={{ height: "100vh", width: '100%',display:'flex',justifyContent: 'center', alignItems: 'center'}}>
    <MapContainer
      center={props.center} zoom={localStorage.getItem('initialZoom')} style={{ height: "100vh", width: '100%', }} zoomControl={false}
    // Add this line
    >
      <LayersControl position="topright">
        <BaseLayer  name="World Imagery">
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            maxZoom={19}
          />
        </BaseLayer>
        <BaseLayer checked  name="OpenStreetMap">
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </BaseLayer>
        {/* Add additional layers here */}
      </LayersControl>
      <MapClickHandler setCenter={props.setCenter} />
      <MapUpdater sidebarOpen={props.sidebarOpen} />

      <ZoomControl position="bottomright" />
      <MapEvents campingSpots={props.campingSpots} defaultCenter={center} />

      {renderPolygonsAndMarkers()}

    </MapContainer>
    <div className="mapViewSwitch" onClick={()=>{props.setMapViewSelected(false) ;  localStorage.setItem("isMap", 0)}}>Switch to list view</div>

    </div>
  );
}