import React from 'react';
import './About.css';
function About() {
  return (
    <section id="about" class="about">
    <div class="container-fluid">
      
      <div class="row">
        <div class="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5" data-aos="fade-left">
          
          <h3>The Story Behind "Tribal"</h3>
          <p>"Tribal Camping" is not just an application; it's a product of our deep-rooted passion for camping and our profound love for the great outdoors. Our story is simple: We love camping, and we want to help you love it even more. Join us in creating unforgettable camping experiences, forging new friendships, and exploring the great outdoors with a sense of wonder. Welcome to "Tribal Camping."</p>

          <div class="icon-box" data-aos="zoom-in" data-aos-delay="100">
            <div class="icon"><i class="bx bx-search"></i></div>
            <h4 class="title">Personalized Camping</h4>
            <p class="description"> You can personalize your search for the perfect camping spot. Lakeside views or lush forests you tell us what you're looking for, and we'll guide you to the perfect option.</p>
          </div>

          <div class="icon-box" data-aos="zoom-in" data-aos-delay="200">
            <div class="icon"><i class="bx bx-brain"></i></div>
            <h4 class="title">Artificial Intelligence</h4>
            <p class="description">Our AI analyzes thousands of camping locations, ensuring that you are presented with only the very best options. </p>
          </div>

          <div class="icon-box" data-aos="zoom-in" data-aos-delay="300">
            <div class="icon"><i class="bx bx-group"></i></div>
            <h4 class="title">Connect with Fellow Campers</h4>
            <p class="description">Exchange experiences, swap stories around the campfire, and plan shared adventures with others who understand the thrill of outdoor living.</p>
          </div>

        </div>
      </div>
    </div>

  </section>
  );
}

export default About;