import React from 'react';
import './Donate.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAmericas  } from '@fortawesome/free-solid-svg-icons';


function Eco() {
    const divStyle = {
        backgroundColor: '#e0f7e0', // Zeleno pozadina
        borderRadius: '8px', // Zaobljeni uglovi
        padding: '20px', // Padding
        textAlign: 'center' // Centrira tekst
    };

    const iconStyle = {
        fontSize: '24px', // Veličina ikone
        marginTop: '20px', // Razmak od gornjeg sadržaja
        paddingBottom: '15px'
    };
  return (
  
        <div class="container" style={{padding: '1%'}} data-aos="fade-right">

<div className="row justify-content-center" style={{ margin: '0' }}>
            <div className="col-12" style={divStyle}>
                <h3>Be Mindful When Camping: Protect the Environment!</h3>
                <FontAwesomeIcon icon={faGlobeAmericas } style={iconStyle} />
                <p className="fst-italic">
                    Your actions can make a huge difference in preserving nature. Always ensure to clean up after yourself and leave no trace behind. Join us in our mission to keep the wilderness pristine for future generations to enjoy.
                </p>
                
            </div>
        </div>

        </div>
 
  );
}

export default Eco;