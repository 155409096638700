import React from 'react';

function AlertModal({ isOpen, onClose, search, features }) {
  async function increarse() {
    if (global.setRadiusAdvancedSearch) {
      try {
        global.radius = (parseInt(global.radius) + 50).toString();
      }
      catch(e){
        global.radius = "200"
      }
      global.setRadiusAdvancedSearch(global.radius);
    }
    await search(features)
  }
  return (
    <div style={{
      display: isOpen ? 'block' : 'none',
      position: 'fixed',
      top: 0, left: 0, right: 0, bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      zIndex: 1000
    }}>
      <div style={{
        width: '300px',
        margin: '15% auto',
        padding: '20px',
        backgroundColor: '#3a3a3a',  // Dark background for contrast
        borderRadius: '10px',
        textAlign: 'center',
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.4)',  // Deeper shadow for depth
        fontFamily: '"Roboto Mono", sans-serif',  // Keeping the font consistent
      }}>
        <p style={{
          color: '#ddd',  // Light text for readability
          fontSize: '18px',
          margin: '10px 0',
          fontWeight: '600'  // Boldness for emphasis
        }}>
          No spots found, change your criteria and/or radius
        </p>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <button onClick={onClose} style={{
            backgroundColor: '#ec6313',
            color: 'white',
            border: 'none',
            padding: '10px 20px',
            borderRadius: '5px',
            fontSize: '16px',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
            fontWeight: '600',  // Boldness for emphasis
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',  // Shadow for the button
            margin: '20px'
          }}
            onMouseOver={(e) => e.target.style.backgroundColor = '#d4580a'}
            onMouseOut={(e) => e.target.style.backgroundColor = '#ec6313'}
          >
            OK
          </button>
          <button onClick={async () => await increarse()} style={{
            backgroundColor: '#ec6313',
            color: 'white',
            border: 'none',
            padding: '10px 20px',
            borderRadius: '5px',
            fontSize: '16px',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
            fontWeight: '600',  // Boldness for emphasis
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',  // Shadow for the button
            margin: '20px',
            marginTop: 0,
            marginBottom: '10px'

          }}
            onMouseOver={(e) => e.target.style.backgroundColor = '#d4580a'}
            onMouseOut={(e) => e.target.style.backgroundColor = '#ec6313'}
          >
            Increase radius
          </button>
        </div>
      </div>
    </div>
  );
}

export default AlertModal;

