import React from 'react';
import { useNavigate } from 'react-router-dom';
import './MapCard.css';

const MapCard = ({ title, imageUrl, description, buttonText, buttonLink }) => {
    const navigate = useNavigate();

    return (
        <div className="map-card-container">
            <article className="card">
                <img
                    className="card__background"
                    src={imageUrl}
                    alt={title}
                    width="1920"
                    height="2193"
                />
                
                <div className="card__content flow">
                    <div className="card__content--container flow">
                        <h2 className="card__title">{title}</h2>
                        <p className="card__description">{description}</p>

                        <div >

                            <button 
                                className="card__button"
                                onClick={() => navigate(buttonLink)}
                            >
                                {buttonText}
                            </button>

                        </div>
                        
                    </div>
                </div>
            </article>
        </div>
    );
};

export default MapCard;
