let loading = {};
let loadingFullScreen = {};
let images = {};
let imagesFullScreen = {}
export function restartState() {
    loading = {};
    loadingFullScreen = {};
    images = {};
    imagesFullScreen = {};
}

export function ImageGalleryImage({ myIndex, image, fullscreen, update }) {
    const width = fullscreen ? window.screen.width : Math.floor(document.querySelector(".spot-images-container").getBoundingClientRect().width)
    const height = fullscreen ? window.screen.height : 500

    if (!width || !height)
        return <img src={""} alt="Gallery" />;

    let dimensions = width + "x" + height;


    if (fullscreen ? imagesFullScreen[myIndex] : images[myIndex])
        return <img style={{ maxHeight: height }} src={fullscreen ? imagesFullScreen[myIndex] : images[myIndex]} alt={"Image " + myIndex} />;

    if (!(fullscreen ? loadingFullScreen[myIndex] : loading[myIndex])) {
        (fullscreen ? loadingFullScreen : loading)[myIndex] = dimensions;
        (async () => {
            console.log("FETCHUJEM ZAA " + width)
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = () => {
                try {
                    const WIDTH = width;
                    const HEIGHT = height;
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    canvas.width = WIDTH;
                    canvas.height = HEIGHT;

                    const aspectRatio = img.width / img.height;

                    let targetWidth, targetHeight;

                    if (aspectRatio > WIDTH / HEIGHT) {
                        targetWidth = WIDTH;
                        targetHeight = WIDTH / aspectRatio;
                    } else {
                        targetHeight = HEIGHT;
                        targetWidth = HEIGHT * aspectRatio;
                    }
                    ctx.filter = 'blur(10px)';
                    ctx.drawImage(img, 0, 0, WIDTH, HEIGHT);

                    ctx.filter = 'none';
                    ctx.drawImage(img, (WIDTH - targetWidth) / 2, (HEIGHT - targetHeight) / 2, targetWidth, targetHeight);

                    (fullscreen ? imagesFullScreen : images)[myIndex] = canvas.toDataURL('image/png');
                    setTimeout(update, 1000);
                }
                catch (e) {
                    console.log(e);
                    (fullscreen ? imagesFullScreen : images)[myIndex] = "https://buffer.com/library/content/images/2023/10/free-images.jpg"
                }
            };

            img.onerror = (errorEvent) => {
                console.log(errorEvent);

                img.src = "https://buffer.com/library/content/images/2023/10/free-images.jpg"
                    //(fullscreen ? imagesFullScreen : images)[myIndex] = "https://buffer.com/library/content/images/2023/10/free-images.jpg"
            };

            const baseImage = image//(Math.random() < 0.5) ? "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/4289aa2d-f7a1-4557-a4ce-bddc7171d31f/dfzydb3-fec9e716-17e2-4582-a28b-a2f9923e3b79.jpg/v1/fill/w_1280,h_356,q_75,strp/neon_landscape_ultrawide_wallpaper_by_ultrawidewalls_dfzydb3-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MzU2IiwicGF0aCI6IlwvZlwvNDI4OWFhMmQtZjdhMS00NTU3LWE0Y2UtYmRkYzcxNzFkMzFmXC9kZnp5ZGIzLWZlYzllNzE2LTE3ZTItNDU4Mi1hMjhiLWEyZjk5MjNlM2I3OS5qcGciLCJ3aWR0aCI6Ijw9MTI4MCJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl19.AWHwmWFwNw5o0Ncd0WEswZOyfNAy5FsReBp1GLChGn8" : "https://marketplace.canva.com/EAFTDa9HnCc/1/0/900w/canva-cream-beige-aesthetic-phone-wallpaper-D0iGbUPXGiU.jpg"

            let imageUrl;
            if (baseImage.startsWith("https://lh5.googleusercontent.com"))
                imageUrl = baseImage + `=w${width}-k-no`
            else
                imageUrl = baseImage;
            img.src = imageUrl
        })()

    }
    return <img src={`${global.SERVIP}/misc/empty-image/${width}x${height}`} alt="Gallery" />;
}


