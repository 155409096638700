// Popup.js
import React from 'react';
import './Popup.css';

const Popup = ({ isVisible, onClose }) => {
  if (!isVisible) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
     
       

        <div className="checkmark-container">
          <div className="checkmark">
            <i className="fas fa-check"></i>
          </div>
        </div>
        <p>Thank you for subscribing to our newsletter.</p>
        <button className="ok-btn" onClick={onClose}>OK</button>
      </div>
    </div>
  );
};

export default Popup;
