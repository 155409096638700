import React, { useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import './DisplayApp.css';

const ImageGallery = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [
    {
      src: "https://lh5.googleusercontent.com/p/AF1QipNhwWsyUP3y_VPEqG2ZdMf7NM9NafOySt60JYxK=w1000-h800-no",
      alt: "Mountains",
      caption: "Abyssal Meadow Water",
      link: "https://tribalcamping.co/campsite/?Ids=912&Ids=118"
    },
    {
      src: "https://lh5.googleusercontent.com/p/AF1QipMknHKHgvJ9t2GY5_v_jxMdmVmk2kzsmWsVGU4N",
      alt: "Mountains",
      caption: "Stream of Forlorn Expanses",
      link: "https://tribalcamping.co/campsite/?Ids=912&Ids=126"
    },
    {
      src: "https://lh5.googleusercontent.com/p/AF1QipPbcl10qfavN4D2oqesylmZPf0-WdgxoUf5bAaw",
      alt: "Mountains",
      caption: "Water of Twisted Pinnacles",
      link: "https://tribalcamping.co/campsite/?Ids=912&Ids=453"
    },
    {
      src: "https://farm5.staticflickr.com/4387/36793932296_f959d29859.jpg",
      alt: "Mountains",
      caption: "Peak of the Sunlit Enclave",
      link: "https://tribalcamping.co/campsite/?Ids=912&Ids=2969"
    },
    {
      src: "https://farm66.staticflickr.com/65535/52145252723_c5563b016f.jpg",
      alt: "Mountains",
      caption: "Forest of Infernal Citadels",
      link: "https://tribalcamping.co/campsite/?Ids=912&Ids=37780"
    }
  ];

  const totalImages = images.length;

  const [props, set] = useSpring(() => ({
    opacity: 1,
    config: { duration: 500 }
  }));

  const handlePrev = () => {
    set({ opacity: 0 });
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? totalImages - 1 : prevIndex - 1));
      set({ opacity: 1 });
    }, 500);
  };

  const handleNext = () => {
    set({ opacity: 0 });
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex === totalImages - 1 ? 0 : prevIndex + 1));
      set({ opacity: 1 });
    }, 500);
  };

  return (
    <div style={{ paddingTop: "4%" }} data-aos="fade-right">
      <div className="container">
        <div className="section-title">
          <h2 style={{ marginLeft: '0', textAlign: 'left' }}>recommended</h2>
          <p>Top places</p>
        </div>
      </div>

      <div className="boxplaces-container">
        <div className="boxplaces">
          {images.map((image, index) => (
            <a key={index} href={image.link} target="_blank" rel="noopener noreferrer">
              <figure>
                <img src={image.src} alt={image.alt} />
                <figcaption>{image.caption}</figcaption>
              </figure>
            </a>
          ))}
        </div>
        <div className="mobile-gallery">
          <animated.figure style={props}>
            <a href={images[currentIndex].link} target="_blank" rel="noopener noreferrer">
              <img src={images[currentIndex].src} alt={images[currentIndex].alt} />
              <figcaption>{images[currentIndex].caption}</figcaption>
            </a>
          </animated.figure>
          <div className="arrow left" onClick={handlePrev}>‹</div>
          <div className="arrow right" onClick={handleNext}>›</div>
        </div>
      </div>
    </div>
  );
};

export default ImageGallery;
