const parseCoordinateString = (str) => {
    let split = str?.split(',');
    if(split?.length != 2)
        return null;

    try{
        return split.map(coord => parseFloat(coord.trim()))
    }
    catch(e){
        return null;
    }
}

export { parseCoordinateString };