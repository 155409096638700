import close from '../../assets/close.png';
import '../../styles/Info.css'
const regularSearchQueries = [
    "I'm looking for a peaceful campsite near a lake with a beautiful waterfall, preferably in a forest area around Bern, no more than 100 km away. Amenities like a barbecue and parking would be great.",
    "Searching for a family-friendly campsite with a cabin or lodge, close to a river for fishing, with nearby picnic sites and a swimming area. Must be within 150 km of Zurich.",

    "Can you find a remote campsite with a wilderness hut and nearby stream in the Geneva area? It would be great if there's a meadow with hiking paths and maybe a firepit for the night, all within 120 km.",
    "I need a campsite with a caravan site or chalet, close to natural springs and a grassland near Basel. It should be within 70 km. It would be nice to have a restaurant or cafe nearby and a place to rent a bicycle.",

    "Looking for a beach campsite with access to a bay, a nearby valley for hiking, and a nearby place for horse riding near Lucerne, no farther than 90 km. Must have toilets and vending machines."
]



function Info({ closeSidebar, promptSearch, regularSearch }) {
    return (
        <div style={{height:"100%"}} >
              
        <div className="containerRoad">
          
            <div className="phase" style={{color:'white'}}>
                <h2 >Search for campsites on Tribal</h2>
                <p style={{color:'white'}}>
Our campsite search find the best backcountry / remote camping areas near you. We have scaned whole countries and mapped perfect camping areas for each of your needs. 99% of Campgrounds on tribal can't be found anythere else. Just pick your favourite natural attractions, outdoors amenities (grill, hut, bench) and much more.                </p>
            </div>
            <div className="intro">
                <h2 style={{color:'white'}}>Regular campsite search</h2>
                <p style={{color:'white'}}>
This one is pretty straight forward. We show you all the possible search criteria and you pick things that you like. Make sure to pick things that make sence togeather eg. (lake,waterfall,woods,bbq,...) and not combinations like caravan (site , peak ,boat rental). With tribal, you can find pretty much any type of camp you can think of.                </p>
                
                <div>
                        <button className="animated-button" onClick={()=>regularSearch()}>
                            <span>Try it out</span>
                            <span></span>
                        </button>
                </div>
            </div>
            <div className="intro">
                <h2 style={{color:'white'}}>Prompt campsite search</h2>
                <p style={{color:'white'}}>
                    It's just like chatting with a friend. Just use the features you want (forest,peak,lake,fishing place,...) in a search sentence and our AI model will find you the perfect spots. You can also add parts like "No more than 70km from Basel" for results close to your city. You can search in whichever language you want, but English is recommended for complex searches.
                </p>
                
                <h2 style={{color:'#f5f5f5'}}>Examples of queries for prompt searches</h2>
                <div>
                    {regularSearchQueries.map(item => (
                        <button className="animated-button" onClick={() => promptSearch(item)}>
                            <span>{item}</span>
                            <span></span>
                        </button>
                    ))}
                </div>
            </div>

          
        </div>
        </div>
    );
}

export default Info;
