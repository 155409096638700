import { Button } from 'reactstrap';
import './ShareButton.css';

function ShareButton({ ids })
{
    const copyToClipboard = (e) => {
        e.preventDefault();
        const baseUrl = global.FRONTURL + "/campsite/";
        const idsQuery = ids.map(id => `Ids=${id}`).join('&');
        const textToCopy = `${baseUrl}?${idsQuery}`;
    
        if (navigator.clipboard) {
            navigator.clipboard.writeText(textToCopy).then(() => {
                alert('Link copied to clipboard!');
            }).catch(err => {
                alert('Failed to copy text to clipboard. Please copy the URL manually.');
            });
        } else {
            alert('Clipboard feature not supported in this browser. Please copy the URL manually.');
        }
    };
    return(
        <a className="prompt-search-button" style={{textDecoration: 'none'}} 
        target="_blank"
        rel="noopener noreferrer"
        onClick={copyToClipboard}>
  
       
          <span className="text-2">Share this spot</span>
      </a>  )
}

export default ShareButton;