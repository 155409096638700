import React, { useState } from 'react';
import '../styles/DonationPage.css';
import close from '../assets/close.png';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input,
    Button
} from 'reactstrap'; 

function ContactPage({ closeSidebar }) {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [title, setTitle] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const requestBody = {
            Email: email,
            Title: title,
            Body: message
        };

        try {
            const response = await fetch(global.SERVIP + "/api/Notification/send-notification", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            if (response.ok) {
                alert('Message sent successfully!');
                // Clear the form
                setEmail("");
                setMessage("");
                setTitle("");
            } else {
                alert('Failed to send message.');
            }
        } catch (error) {
            console.error('Error sending message:', error);
            alert('An error occurred while sending the message.');
        }
    };

    return (
        <div className="containerRoad">
            <h1 style={{ color: "whitesmoke" }}>Contact us</h1>
            <br />
            <div className="intro">
                <p style={{ color: "whitesmoke" }}>
                    If you have any questions or suggestions, you can contact us here, just leave your email and a message that you want to send.
                </p>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Input
                            type="text"
                            name="title"
                            id="title"
                            className="custom-textarea"
                            onInput={(e) => setTitle(e.target.value)}
                            placeholder="Title of your message"
                            value={title}
                            style={{
                                backgroundColor: "#2d2d2d",
                                color: "white",
                                padding: "10px",
                                borderRadius: "5px",
                                border: "0px solid #ec6313",
                                marginTop: "10px",
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            type="textarea"
                            name="explanation"
                            id="explanation"
                            className="custom-textarea"
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Write your message here"
                            value={message}
                            style={{
                                backgroundColor: "#2d2d2d",
                                color: "white",
                                padding: "10px",
                                borderRadius: "5px",
                                border: "0px solid #ec6313",
                                height: "100px",
                                textAlign: "left",
                                verticalAlign: "top",
                                resize: "none",
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            type="email"
                            name="email"
                            id="email"
                            className="custom-textarea"
                            onInput={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email"
                            value={email}
                            style={{
                                backgroundColor: "#2d2d2d",
                                color: "white",
                                padding: "10px",
                                borderRadius: "5px",
                                border: "0px solid #ec6313",
                                marginTop: "10px",
                            }}
                        />
                    </FormGroup>
                    <Button className="donateButton" style={{ width: "100%" }} type="submit">Send</Button>
                </Form>
            </div>
        </div>
    );
}

export default ContactPage;
