const fetchCenterIpBase = async () => {
    try {
        const response = await fetch("https://api.ipbase.com/v1/json/");
        //{latitude: 45, longitude: 19, country_code: "RS"}//
        const data = await response.json();
        const { latitude, longitude, country_code, city, country_name } = data
        console.log(data)
        return { latitude, longitude, country_code, city, country_name };
        return { latitude: 48.6316, longitude: 13.9092, country_code, city, country_name };
    }
    catch (e) {
        return null;
    }
}

const fetchCookieCenterString = async () => {
    const current_value = localStorage.getItem("approximate_location");
    if (current_value)
        return current_value;

    const center = await fetchCenterIpBase();

    if (!center)
        return null;

    const string_value = center.latitude + "," + center.longitude;

    localStorage.setItem("approximate_location", string_value);
    localStorage.setItem("country_code", center.country_code);

    return string_value;
}

export { fetchCenterIpBase, fetchCookieCenterString };
