import React, { useState, useEffect } from 'react';

import './Hi.css';

function Hi() {
  
  const [message1Visible, setMessage1Visible] = useState(true);
  const [message2Visible, setMessage2Visible] = useState(false);

  useEffect(() => {
    const timeout1 = setTimeout(() => {
      setMessage1Visible(false);
      setMessage2Visible(true);
    }, 3000);

    const timeout2 = setTimeout(() => {
      window.location.href = "/campsiteQuestions/prvi";
    }, 6000);

    // Ovo je funkcija čišćenja koja će se pozvati kada komponenta bude unmount-ovana
    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    };
  }, []); // Prazan niz zavisnosti osigurava da se useEffect izvršava samo jednom, slično kao `componentDidMount`

  return (
    <div>
      {message1Visible && (
        <div className="message1">
          Hello, Welcome to Tribal Camping!
        </div>
      )}

      {message2Visible && (
        <div className="message2">
          Let's Find your perfect camping spot!
        </div>
      )}
      
    </div>
  );
}

export default Hi;
