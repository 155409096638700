import React, { useState } from 'react';
import './Cetvrti.css';
import { useLocation } from 'react-router-dom';
import natureImages from './assets/natureFeaturesImages/natureImages';
import { useNavigate } from "react-router-dom";

function Cetvrti() {
    const location = useLocation();
	const navigate = useNavigate();

    const mostCommonNatureFeatures = location.state ? location.state.mostCommonNatureFeatures : [];
    const [spots, setSpots] = useState(location.state ? location.state.spots : []);
    const [radius, setRadius] = useState(location.state ? location.state.radius : []);

    const [selectedFeatureId, setSelectedFeatureId] = useState(null);
	const [mostCommon4Features,setMostCommon4Features] = useState([])
    function filterSpotsByFeature(featureId) {
        const mask = BigInt(1) << BigInt(featureId - 1);
        return spots.filter(spot => (BigInt(spot.features) & mask) !== BigInt(0));
    }

	async function fetchAttributesFromApi() {
		try {
		  const response = await fetch(global.SERVIP + '/api/bitmap2');
	
		  if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`);
		  }
	
		  const res = await response.json();
		  console.log(res)
		  return res;
		} catch (error) {
		  console.error(`Error fetching attributes: ${error}`);
		  return [];
		}
	  }
	  async function getSortedFilteredFeatureCounts() {

		let features = await fetchAttributesFromApi();
		// Filter out features with featureCategoryId as 1 or 4
		const filteredFeatures = features.filter(feature => feature.featureCategoryId !== 1 && feature.featureCategoryId !== 4);
	
		let featureCounts = {};
	
		spots.forEach(spot => {
			filteredFeatures.forEach(feature => {
				const mask = 1n << BigInt(feature.id - 1);
				if ((BigInt(spot.features) & mask) !== 0n) {
					if (feature.name in featureCounts) {
						featureCounts[feature.name] += 1;
					} else {
						featureCounts[feature.name] = 1;
					}
				}
			});
		});
	
		// Sort by count in descending order and take only the top 4
		const sortedFeatures = Object.entries(featureCounts).sort(([, aCount], [, bCount]) => bCount - aCount).slice(0, 6).map(([name, count]) => ({ name, count }));
		
		console.log(sortedFeatures);
		setMostCommon4Features(sortedFeatures)
	
		return { sortedFeatures, featureCounts };
	}
	
    const handleClick = (e, feature) => {
        e.preventDefault();
		if(selectedFeatureId != feature.id) 
		{
        setSelectedFeatureId(feature.id); // Update the selected feature state
		setSpots(filterSpotsByFeature(feature.id))
		getSortedFilteredFeatureCounts()	
		}
		else
		{
		setSelectedFeatureId(null);
		}
	}

    const handleNextClick = () => {
        if (selectedFeatureId) {
            // TODO: Handle the next action if a feature is selected
        } else {
            // TODO: Handle the "Doesn't matter" action
        }
		navigate("/campsiteQuestions/peti", { state: { mostCommon4Features: mostCommon4Features, spots : spots , radius: radius, locationCoordinates: location.state.locationCoordinates } })

    };

    return (
        <div className="limiter-cetvrti">
            <div className="container-cetvrti">
                <div className="wrap-cetvrti">
                    <h2>Which type of place do you prefer?</h2>
                    <form className="login100-form validate-form login100-form4">
                        {mostCommonNatureFeatures.map((feature, index) => (
                            <div 
                                key={index} 
                                onClick={(e) => handleClick(e, feature)} 
                                className={`image-feature-container ${selectedFeatureId === feature.id ? 'selected-feature' : ''}`} 
                                style={{backgroundImage: `url(${natureImages[feature.featureName].image})`}}
                            >
                                <p className="image-feature-text">{natureImages[feature.featureName].name}</p>
                            </div>
                        ))}
                    </form>
					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<button onClick={handleNextClick} className="login100-form-btn" style={{ textAlign:'center', backgroundColor: 'orange', borderColor:'white' , borderRadius:10}}>
							{selectedFeatureId ? "Next" : "Doesn't matter"}
						</button>
					</div>
                </div>
            </div>
        </div>
    );
}

export default Cetvrti;

/* global BigInt */
