import DesktopListViewCard from "./DesktopListViewComponents/DesktopListViewCard";
import './ListView.css'
import { useNavigate } from 'react-router-dom';
import { useState, useEffect} from "react";
import emojiMap from "../../utils/EmojiMap";
import seedrandom from "seedrandom";
import InfiniteScroll from 'react-infinite-scroll-component';

const adjectives = [
    'Eldritch', 'Mystic', 'Whispering', 'Golden', 'Arcane', 'Frozen',
    'Silent', 'Enchanted', 'Ancient', 'Cursed', 'Luminous', 'Ethereal',
    'Emerald', 'Lonely', 'Twisted', 'Sacred', 'Forgotten', 'Rustic',
    'Rugged', 'Crimson', 'Shadowed', 'Verdant', 'Eerie', 'Majestic',
    'Moonlit', 'Abyssal', 'Pristine', 'Dreaded', 'Sunlit', 'Lost',
    'Forlorn', 'Haunted', 'Blessed', 'Divine', 'Infernal', 'Mystical',
    'Eclipsed', 'Hallowed', 'Shrouded', 'Radiant', 'Dusky', 'Spectral',
    'Veiled', 'Emergent', 'Primeval', 'Fabled', 'Hidden', 'Celestial',
    'Venerable', 'Timeless', 'Harmonious', 'Azure', 'Dwindling', 'Resplendent'
  ];
  
  const nouns = [
    'Bastion', 'Spire', 'Sanctum', 'Thicket', 'Pinnacle', 'Stronghold', 'Glen',
    'Isle', 'Refuge', 'Meadow', 'Peak', 'Ruins', 'Cliff', 'Cavern',
    'Citadel', 'Plains', 'Desolation', 'Woods', 'Abyss', 'Bog',
    'Plateau', 'Ridge', 'Lagoon', 'Reef', 'Bluff', 'Tundra', 'Oasis',
    'Steppes', 'Grove', 'Waterfall', 'Chasm', 'Nexus', 'Grotto', 'Fjord',
    'Lair', 'Realm', 'Glade', 'Vista', 'Ravine', 'Pass', 'Dell',
    'Temple', 'Orchard', 'Terrace', 'Domain', 'Shrine', 'Expanse',
    'Enclave', 'Depths', 'Vale', 'Outlook', 'Breach', 'Garrison',
    'Frontier', 'Quarry', 'Monolith', 'Sepulcher', 'Eden', 'Relic',
    'Bulwark', 'Heath', 'Mire', 'Falls', 'Redoubt', 'Vortex'
  ];
  
  const nouns_multiple = [
    'Bastions', 'Spires', 'Sanctums', 'Thickets', 'Pinnacles', 'Strongholds', 'Glens',
    'Isles', 'Refuges', 'Meadows', 'Peaks', 'Ruins', 'Cliffs', 'Caverns',
    'Citadels', 'Plains', 'Desolations', 'Woods', 'Abyss', 'Bogs',
    'Plateaus', 'Ridges', 'Lagoons', 'Reefs', 'Bluffs', 'Tundras', 'Oases',
    'Steppes', 'Groves', 'Waterfalls', 'Chasms', 'Nexus', 'Grottos', 'Fjords',
    'Lairs', 'Realms', 'Glades', 'Vistas', 'Ravines', 'Passes', 'Dells',
    'Temples', 'Orchards', 'Terraces', 'Domains', 'Shrines', 'Expanses',
    'Enclaves', 'Depths', 'Vales', 'Outlooks', 'Breaches', 'Garrisons',
    'Frontiers', 'Quarries', 'Monoliths', 'Sepulchers', 'Edens', 'Relics',
    'Bulwarks', 'Heaths', 'Mires', 'Falls', 'Redoubts', 'Vortexes'
  ]
const test = [1,2,3,4]
function DesktopListView({
    setMapViewSelected,
    campSites2,
    center,
    campingSpots
}){
    const navigate = useNavigate();
    const [plainFeatures, setPlainFeatures] = useState([]);
    const [campsiteNames, setCampsiteNames] = useState({});
    const [imageUrls, setImageUrls] = useState([])
    const [campSites, setCampSites] = useState(campSites2);
    const initializeItems = (itemData) => {
      // Retrieve and parse the last index from localStorage
      const lastVisited = localStorage.getItem('lastVisitedCampsiteIndex');
      const parsedIndex = lastVisited ? parseInt(lastVisited, 10) : 10; // Ensure it's a number or fallback to 10
      const startIndex = Math.max(parsedIndex + 5, 10); // Ensure a minimum of 10
  
      // Slice `campSites` to return the first `startIndex` items
      return itemData.slice(0, startIndex);
  };
  
  // Initialize the `items` state
  const [items, setItems] = useState(initializeItems(campSites2));    
  const [hasMore, setHasMore] = useState(true);
    const [currentCampsiteIndex, setCurrentCampsiteIndex] = useState(localStorage.getItem('lastVisitedCampsiteIndex') || 0)
    useEffect(() => {
        async function fetchAttributesFromApi() {
            try {
              const response = await fetch(global.SERVIP + '/api/bitmap2');
        
              if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
              }
        
              const res = await response.json();
              setPlainFeatures(res)
              return res;
            } catch (error) {
              console.error(`Error fetching attributes: ${error}`);
              return [];
            }
          }
          fetchAttributesFromApi();
      }, []);

      useEffect(()=>{
        if(plainFeatures.length>0)
        {
            let newCampsiteNames = {}

        campSites.forEach((element,index) => {
          
            newCampsiteNames[index] = generateMythicName(element[0].features, plainFeatures, element)
        });

        setCampsiteNames(newCampsiteNames);
    }
      },[plainFeatures])

      useEffect(()=>{
        setHasMore(true)
        console.log("ja sam bil")
        console.log(campSites2)
      setCampSites(campSites2);
      
      setItems(initializeItems(campSites2))
      },[campSites2])

    const privremena = ()=>{
        console.log(campSites)
        localStorage.setItem("isMap", 1)
        setMapViewSelected(true)
    }

      const getRandomItem = (array, rng) => array[Math.floor(rng.double() * array.length)];
      const getSeed = (campsite) => {
     
        let ids = []
        if (Array.isArray(campsite)) {
             ids = campsite.map(place => {
                try {
                    return parseInt(place.id, 10);
                } catch (e) {
                    console.error("Error parsing campsite id:", e);
                    return 0;
                }
            });
        } else {
            console.error("campsite is not an array:", campsite);
        }

        ids.sort();
    
        let result = 0;
        const BASE = 32771
        const MODULE = 2147483647
    
        let exp = 1
        for (let i = 0; i < ids.length; i++) {
          result = (result + ids[i] * exp) % MODULE;
          exp = (exp * BASE) % MODULE;
        }
        return result;
      }
      const generateMythicName = (featuresNumber, fetchedFeatures = null, campsite) => {
        let seed = getSeed(campsite);
        const rng = seedrandom(seed);
    
        let selectedNamingFeatures = selectNamingFeatures(featuresNumber, fetchedFeatures);
        let gotRandomItem = getRandomItem(selectedNamingFeatures, rng);
        let emojiMapItem = emojiMap[gotRandomItem]
        if (!emojiMapItem) {
          return `${getRandomItem(adjectives, rng)} ${getRandomItem(nouns_multiple, rng)}`;
        }
        const randomFeature = emojiMapItem.name;
        let rnd = rng.double();
        if (rnd < 0.3) {
          return `${randomFeature} of ${getRandomItem(adjectives, rng)} ${getRandomItem(nouns_multiple, rng)}`;
        }
        if (rnd < 0.65) {
          return `${randomFeature} of the ${getRandomItem(adjectives, rng)} ${getRandomItem(nouns, rng)}`;
        }
        else if (rnd < 0.95) {
          return `${getRandomItem(adjectives, rng)} ${getRandomItem(nouns, rng)} ${randomFeature}`;
        }
        else {
          return `${getRandomItem(adjectives, rng)} ${randomFeature}`;
        }
    
    
      }
    
      const concatGroupFeatures = (curGroup) => {
        let curr = []
        curGroup.forEach(element => {
          curr.push(element.features)
        });
        return curr
      }
    
      const concatGroupNames = (curGroup) => {
        const featureNumbers = concatGroupFeatures(curGroup)
    
        const featureNames = new Set()
    
        featureNumbers.forEach(number => {
          const curentNames = mapNumberToAttributes(number)
          curentNames.forEach(name => {
            if (!featureNames.has(name))
              featureNames.add(name)
          });
        });
    
        return Array.from(featureNames)
      }
    
      function mapNumberToAttributes(number) {
        const mappedAttributes = [];
    
        const lower32Bits = number & 0xFFFFFFFF; // Extract the lower 32 bits
        const upper32Bits = (number / Math.pow(2, 32)) & 0xFFFFFFFF; // Extract the upper 32 bits
    
        for (let i = 0; i < 32; i++) {
          if ((lower32Bits & (1 << i)) && i < plainFeatures.length) {
            mappedAttributes.push(plainFeatures[i]['name']);
          }
        }
    
        for (let i = 0; i < 32; i++) {
          if ((upper32Bits & (1 << i)) && i + 32 < plainFeatures.length) {
            mappedAttributes.push(plainFeatures[i + 32]['name']);
          }
        }
    
        return mappedAttributes;
      }
    
    
    
      function selectNamingFeatures(number, fetchedFeatures = null) {
        const attributes = fetchedFeatures || plainFeatures;
        const mappedAttributes = [];
        // Extract lower and upper 32 bits
        const lower32Bits = number & 0xFFFFFFFF;
        const upper32Bits = (number / 0x100000000) & 0xFFFFFFFF;
    
        for (let i = 0; i < 32; i++) {
          // Check lower 32 bits
          if (lower32Bits & (1 << i) && i < attributes.length) {
            const attribute = attributes[i];
            if (attribute.featureCategoryId === 4 || attribute.featureCategoryId === 6 || attribute.featureCategoryId === 7) {
              mappedAttributes.push(attribute['name']);
            }
          }
    
          // Check upper 32 bits if applicable
          if (i + 32 < attributes.length && upper32Bits & (1 << i)) {
            const attribute = attributes[i + 32];
            if (attribute.featureCategoryId === 4 || attribute.featureCategoryId === 6 || attribute.featureCategoryId === 7) {
              mappedAttributes.push(attribute['name']);
            }
          }
        }
        return mappedAttributes.filter(e => e);
      }
      const fetchMoreData = () => {
        if (items.length >= campSites2.length) {
          setHasMore(false);
          return;
        }
        // Simulate a loading delay
        setTimeout(() => {
          setItems(items.concat(campSites2.slice(items.length, items.length + 10)));
        }, 1000);
      };
      useEffect(() => {

        const element = document.getElementById('CardDiv'+currentCampsiteIndex);
    if (element && currentCampsiteIndex !=0) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
    }, []);
    return(
        <div className="desktopListViewContainer"  >
            <div style={{ flex: 10, maxHeight: '100%', overflowY: 'scroll', padding: 5, paddingBottom: 0, width: '95%' }} id="scrollableDiv" className="desktopCampsiteList">
  <InfiniteScroll
    dataLength={items.length} // This is important field to render the next data
    next={fetchMoreData}
    hasMore={hasMore}
    loader={<h4>Loading...</h4>}
    style={{overflow:"hidden",}}
    endMessage={
      <p style={{ textAlign: 'center' }}>
        <b>You have seen it all</b>
      </p>
    }
    scrollableTarget="scrollableDiv" // Make sure this ID matches the container div's ID
  >
    {items.map((item, index) => (
      <DesktopListViewCard
        key={index}
        data={item}
        name={campsiteNames[index]}
        plainFeatures={plainFeatures}
        center={center}
        setCurrentCampsiteIndex = {setCurrentCampsiteIndex}
        campsiteIndex = {index}
      />
    ))}
  </InfiniteScroll>
  <br /><br /><br /><br />
</div>

        <div className="listViewSwitch" onClick={() =>privremena()}>Switch to map view 🌍</div>

        </div>
    )

}

export default DesktopListView