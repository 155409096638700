import React, { useEffect, useState } from 'react';
import './Header.css';

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState('hero');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const setActive = (section) => {
    setActiveSection(section);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }

      const sections = ['hero', 'map', 'about', 'features', 'phases', 'donate', 'contact'];

      // Pronađite trenutnu sekciju koja je najbliža vrhu prozora
      let closestSection = sections[0];
      let closestDistance = Math.abs(window.scrollY - document.getElementById(sections[0]).offsetTop);

      for (const section of sections) {
        const distance = Math.abs(window.scrollY - document.getElementById(section).offsetTop);
        if (distance < closestDistance) {
          closestDistance = distance;
          closestSection = section;
        }
      }

      // Postavite trenutnu aktivnu sekciju
      setActive(closestSection);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getSectionClass = (section) => (section === activeSection ? 'nav-link scrollto active' : 'nav-link scrollto');
  const getNavbarClass = () => (isScrolled ? 'fixed-top d-flex align-items-center' : 'fixed-top d-flex align-items-center header-transparent');

 
  return (
    <div>
      <header id="header" className={getNavbarClass()}>
        <div className="container d-flex align-items-center justify-content-between">
          <div className="container d-flex align-items-center">
            <img src="assets/img/icon.png" alt="Tribal Camping Icon" className="custom-icon" />
            <div className="logo">
              <h1>
                <a href="#">
                  <span className="headerTitle">Tribal Camping</span>
                </a>
              </h1>
            </div>
          </div>
          <nav id="navbar" className="navbar">
            <ul>
              <li><a className={getSectionClass('hero')} href="#hero" onClick={closeMobileMenu}>Home</a></li>
              <li><a className={getSectionClass('map')} href="#map" onClick={closeMobileMenu}>Map</a></li>
              <li><a className={getSectionClass('about')} href="#about" onClick={closeMobileMenu}>About</a></li>
              <li><a className={getSectionClass('features')} href="#features" onClick={closeMobileMenu}>Features</a></li>
              <li><a className={getSectionClass('phases')} href="#phases" onClick={closeMobileMenu}>Phases</a></li>
              <li><a className={getSectionClass('donate')} href="#donate" onClick={closeMobileMenu}>Donate</a></li>
              <li><a className={getSectionClass('contact')} href="#contact" onClick={closeMobileMenu}>Contact</a></li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle" onClick={toggleMobileMenu}></i>
          </nav>
        </div>
      </header>
      {isMobileMenuOpen && (
        <div className="navbar-mobile">
          <ul>
            <li><a className={getSectionClass('hero')} href="#hero" onClick={closeMobileMenu}>Home</a></li>
            <li><a className={getSectionClass('map')} href="#map" onClick={closeMobileMenu}>Map</a></li>
            <li><a className={getSectionClass('about')} href="#about" onClick={closeMobileMenu}>About</a></li>
            <li><a className={getSectionClass('features')} href="#features" onClick={closeMobileMenu}>Features</a></li>
            <li><a className={getSectionClass('phases')} href="#phases" onClick={closeMobileMenu}>Phases</a></li>
            <li><a className={getSectionClass('donate')} href="#donate" onClick={closeMobileMenu}>Donate</a></li>
            <li><a className={getSectionClass('contact')} href="#contact" onClick={closeMobileMenu}>Contact</a></li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default Header;