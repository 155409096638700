import { useState, useEffect, useRef } from "react";
import '../styles/CampsitePage.css';
import { useLocation } from 'react-router-dom';
import emojiMap from "../utils/EmojiMap";
import ImageChangeButton from "../components/Buttons/ImageChangeButton";
import GoogleEarthButton from "../components/Buttons/GoogleEarthButton";
import CopyCoordinatesButton from "../components/Buttons/CopyCoordinatesButton";
import { MapContainer, TileLayer, LayersControl, ZoomControl, Marker, Popup, Polygon, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import * as turf from "@turf/turf";
import { convex, point, featureCollection } from '@turf/turf';
import { useNavigate } from 'react-router-dom';
import pinImg from '../assets/imagePin.png'
import L from 'leaflet';
import Tooltip from "../components/Tooltip";
import close from '../assets/close.png'
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';
import DirectionsButton from "../components/Buttons/DirectionsButton";
import seedrandom from "seedrandom";
import backArrow from '../assets/arrow.png'
import { Button } from "reactstrap";
import ReportButton from "../components/CampsitePageComponents/ReportButton";
import RatingComponent from "../components/CampsitePageComponents/RatingComponent";
import ShareButton from "../components/CampsitePageComponents/ShareButton";
import WeatherForecast from "../components/CampsitePageComponents/WeatherForecast ";
import AnimalWarrning from "../components/CampsitePageComponents/AnimalWarrning";
import { ImageGalleryImage, restartState } from "../components/CampsitePageComponents/ImageGalleryImage";
import { fetchCookieCenterString } from "../utils/IPBase";
import { parseCoordinateString } from "../utils/Coordinate";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import Swal from 'sweetalert2';
import CampingResponsiblyModal from "../components/CampingResponsiblyModal";




const adjectives = [
  'Eldritch', 'Mystic', 'Whispering', 'Golden', 'Arcane', 'Frozen',
  'Silent', 'Enchanted', 'Ancient', 'Cursed', 'Luminous', 'Ethereal',
  'Emerald', 'Lonely', 'Twisted', 'Sacred', 'Forgotten', 'Rustic',
  'Rugged', 'Crimson', 'Shadowed', 'Verdant', 'Eerie', 'Majestic',
  'Moonlit', 'Abyssal', 'Pristine', 'Dreaded', 'Sunlit', 'Lost',
  'Forlorn', 'Haunted', 'Blessed', 'Divine', 'Infernal', 'Mystical',
  'Eclipsed', 'Hallowed', 'Shrouded', 'Radiant', 'Dusky', 'Spectral',
  'Veiled', 'Emergent', 'Primeval', 'Fabled', 'Hidden', 'Celestial',
  'Venerable', 'Timeless', 'Harmonious', 'Azure', 'Dwindling', 'Resplendent'
];

const nouns = [
  'Bastion', 'Spire', 'Sanctum', 'Thicket', 'Pinnacle', 'Stronghold', 'Glen',
  'Isle', 'Refuge', 'Meadow', 'Peak', 'Ruins', 'Cliff', 'Cavern',
  'Citadel', 'Plains', 'Desolation', 'Woods', 'Abyss', 'Bog',
  'Plateau', 'Ridge', 'Lagoon', 'Reef', 'Bluff', 'Tundra', 'Oasis',
  'Steppes', 'Grove', 'Waterfall', 'Chasm', 'Nexus', 'Grotto', 'Fjord',
  'Lair', 'Realm', 'Glade', 'Vista', 'Ravine', 'Pass', 'Dell',
  'Temple', 'Orchard', 'Terrace', 'Domain', 'Shrine', 'Expanse',
  'Enclave', 'Depths', 'Vale', 'Outlook', 'Breach', 'Garrison',
  'Frontier', 'Quarry', 'Monolith', 'Sepulcher', 'Eden', 'Relic',
  'Bulwark', 'Heath', 'Mire', 'Falls', 'Redoubt', 'Vortex'
];

const nouns_multiple = [
  'Bastions', 'Spires', 'Sanctums', 'Thickets', 'Pinnacles', 'Strongholds', 'Glens',
  'Isles', 'Refuges', 'Meadows', 'Peaks', 'Ruins', 'Cliffs', 'Caverns',
  'Citadels', 'Plains', 'Desolations', 'Woods', 'Abyss', 'Bogs',
  'Plateaus', 'Ridges', 'Lagoons', 'Reefs', 'Bluffs', 'Tundras', 'Oases',
  'Steppes', 'Groves', 'Waterfalls', 'Chasms', 'Nexus', 'Grottos', 'Fjords',
  'Lairs', 'Realms', 'Glades', 'Vistas', 'Ravines', 'Passes', 'Dells',
  'Temples', 'Orchards', 'Terraces', 'Domains', 'Shrines', 'Expanses',
  'Enclaves', 'Depths', 'Vales', 'Outlooks', 'Breaches', 'Garrisons',
  'Frontiers', 'Quarries', 'Monoliths', 'Sepulchers', 'Edens', 'Relics',
  'Bulwarks', 'Heaths', 'Mires', 'Falls', 'Redoubts', 'Vortexes'
]
const { BaseLayer, Overlay } = LayersControl;

function generateMarkerImage(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous"; // To handle CORS

    const pinImage = new Image();
    pinImage.crossOrigin = "Anonymous"; // To handle CORS

    let imagesLoaded = 0; // Counter to check both images are loaded

    const checkAllImagesLoaded = () => {
      imagesLoaded++;
      if (imagesLoaded === 2) {
        // Both images are loaded
        drawMarker();
      }
    };

    img.onload = checkAllImagesLoaded;
    img.onerror = reject;
    img.src = pinImg;

    pinImage.onload = checkAllImagesLoaded;
    pinImage.onerror = reject;
    pinImage.src = pinImg;

    const drawMarker = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      // Dimensions
      const markerWidth = 60;
      const markerHeight = 60;

      canvas.width = markerWidth;
      canvas.height = markerHeight;

      ctx.drawImage(pinImage, 0, 0, markerWidth, markerHeight);

      // Draw the circle for the image
      ctx.beginPath();
      ctx.arc(30, 24, 15, 0, Math.PI * 2, true);
      ctx.closePath();

      // Aspect ratio calculations
      let sourceX, sourceY, sourceWidth, sourceHeight;
      const zoomFactor = 1.5; // 10% zoom
      const imageAspectRatio = img.width / img.height;
      const canvasAspectRatio = markerWidth / markerHeight;

      if (imageAspectRatio > canvasAspectRatio) {
        // Image's aspect ratio is wider than the canvas's aspect ratio
        sourceHeight = img.height;
        sourceWidth = img.height * canvasAspectRatio;

        // Adjust width for zoom
        sourceWidth = sourceWidth / zoomFactor;
        sourceHeight = sourceHeight / zoomFactor;
        sourceX = img.width / 2 - sourceWidth / 2;
        sourceY = img.height / 2 - sourceHeight / 2;
      } else {
        // Image's aspect ratio is taller or equal to the canvas's aspect ratio
        sourceWidth = img.width;
        sourceHeight = img.width / canvasAspectRatio;

        // Adjust height for zoom
        sourceWidth = sourceWidth / zoomFactor;
        sourceHeight = sourceHeight / zoomFactor;
        sourceX = img.width / 2 - sourceWidth / 2;
        sourceY = img.height / 2 - sourceHeight / 2;
      }

      // Draw the image within the circle
      ctx.save(); // Save the current context
      ctx.clip();
      ctx.drawImage(img, sourceX, sourceY, sourceWidth, sourceHeight, 0, 0, 60, 60);
      ctx.restore(); // Restore to the context before clipping


      resolve(canvas.toDataURL("image/png"));
    };
  });
}



function CampsitePage() {
  const [counter, setCounter] = useState(0);
  const [spotImages, setSpotImages] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [name, setName] = useState("");
  const [zoom, setZoom] = useState(13)
  const location = useLocation();
  const data = location.state;
  const [group, setGroup] = useState(data?.group || null);
  //console.log("GROUP ZA COPY PASTE");
  //console.log(group);
  const [plainFeatures, setPlainFeatures] = useState(data?.plainFeatures || null);
  const [center, setCenter] = useState(data?.center || null);
  const [inFullScreen, setInFullScreen] = useState(false);
  const [placeName, setPlaceName] = useState("")
  const [generatedMarkerUrls, setGeneratedMarkerUrls] = useState([]);
  const galleryRef = useRef();
  const spotImagesContainerRef = useRef(null);
  const [distance, setDistance] = useState(center ? (getDistanceFromLatLonInKm(group[0].latitude, group[0].longitude, center[0], center[1])) : 0)
  const surroundingPointsCache = new Map();
  const [mapCenter, setMapCenter] = useState(data ? { lat: group[0].latitude, lng: group[0].longitude } : { lat: 0, lng: 0 })
  const [modalGrade, setModalGrade] = useState(false)
  const [modalReport, setmodalReport] = useState(false);
  const [grade, setGrade] = useState(0)
  const [animals, setAnimals] = useState([])
  const toggle = () => setmodalReport(!modalReport);
  const toggleGrade = () => setModalGrade(!modalGrade);
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const rightDivRef = useRef(null);
  const [padding, setPadding] = useState(0);
  const [isRightDivFixed, setIsRightDivFixed] = useState(true);
  const [campgroundIds, setCampgroundIds] = useState([])
  const [comment, setComment] = useState("")
  const [email, setEmail] = useState("")
  const [username, setUsername] = useState("")
  const [reportReason, setReportReason] = useState(1)

  const showReportModal = () => {
    Swal.fire({
      icon: 'success',
      title: 'Report has been sent',
      text: 'Thank you for reporting this campsite',
      customClass: {
        popup: 'swal2-popup',
        title: 'swal2-title',
        content: 'swal2-content',
        confirmButton: 'swal2-styled swal2-confirm',
        cancelButton: 'swal2-styled swal2-cancel'
      }
    });
  };

  const showReviewModal = () => {
    Swal.fire({
      icon: 'success',
      title: 'Review has been sent',
      text: 'Thank you for reviewing this campsite',
      customClass: {
        popup: 'swal2-popup',
        title: 'swal2-title',
        content: 'swal2-content',
        confirmButton: 'swal2-styled swal2-confirm',
        cancelButton: 'swal2-styled swal2-cancel'
      }
    });
  };

  useEffect(() => {
    console.log("Padding je " + padding);
  }, [padding])

  const rateCampsite = async (comment, grade, email, username) => {
    const ids = group.map(element => element.id)
    const idsQuery = ids.map(id => `Ids=${id}`).join('&');

    const data = {
      SpotIds: idsQuery, // The IDs of the spots being reviewed
      Email: email, // Replace with the user's email if available
      Username: username, // Replace with the user's username if available
      Comment: comment, // The user's comment
      Rating: parseInt(grade)
    };

    try {
      const response = await fetch(global.SERVIP + '/feedback/create-review', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      const result = await response.json();
      if (result.ok) {
        console.log('Rating submitted successfully');
      } else {
        console.error('Error submitting rating:', result.message);
      }
    } catch (error) {
      console.error('Error submitting rating:', error);
    }
    toggleGrade()
    showReviewModal()
  };
  const reportCampsite = async (comment, email, reason) => {
    const ids = group.map(element => element.id);
    const idsQuery = ids.map(id => `Ids=${id}`).join('&');
    console.log(reason)
    const data = {
      spotIds: idsQuery, // The IDs of the spots being reported
      email: email, // Replace with the user's email if available
      comment: comment, // The user's comment
      reason: parseInt(reason) // The reason for reporting
    };

    try {
      const response = await fetch(global.SERVIP + '/feedback/report-spot', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      const result = await response.json();
      if (result.ok) {
        console.log('Report submitted successfully');
      } else {
        console.error('Error submitting report:', result.message);
      }
    } catch (error) {
      console.error('Error submitting report:', error);
    }
    toggle();
    showReportModal()

  };
  const getPlaceName = async (lat, lon) => {
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}&zoom=8&addressdetails=1`; // Adjust zoom here

    try {
      const response = await fetch(url, {
        headers: {
          'User-Agent': 'My-React-App',
        }
      });

      if (response.ok) {
        const data = await response.json();
        const address = data.display_name || 'Unknown location';
        setPlaceName(address);
      } else {
        console.error('Error with reverse geocoding:', await response.text());
        setPlaceName('Error fetching location');
      }
    } catch (error) {
      console.error('Error fetching from Nominatim:', error);
      setPlaceName('Error fetching location');
    }
  };
  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }
  function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in km
    return Math.round(distance, 2);
  }

  useEffect(() => {
    restartState();
    if (group) {
      getPlaceName(group[0].latitude, group[0].longitude);
      setMapCenter({ lat: group[0].latitude, lng: group[0].longitude })
    }
  }, []); // Empty dependency array to run only on mount

  const getAnimals = async (props) => {
    const response = await fetch(`${global.SERVIP}/api/getAnimals?${props}`);
    const data1 = await response.json();
    const processAnimals = (data) => {
      let result = {}
      for (let arr of data) {
        let anm = JSON.parse(arr.animals.replace(/'/g, '"'));
        for (let animal of anm) {
          const key = Object.keys(animal)[0];
          if (!result[key] || animal[key] < result[key]) {
            result[key] = animal[key];
          }
        }
      }

      return Object.keys(result)
        .sort((a, b) => (result[a] - result[b]))
        .map(e => ({ id: e, distance: result[e] }));;
    }

    const filterAnimals = (data) => {
      if (data.length >= 5 && data[4].distance > 10000) {
        data.splice(4, 1);
      }
      if (data.length >= 4 && data[3].distance > 15000) {
        data.splice(3, 1);
      }
      if (data.length >= 3 && data[2].distance > 20000) {
        data.splice(2, 1);
      }
      return data;
    }
    setAnimals(filterAnimals(processAnimals(data1)))
  }
  useEffect(() => {
    if (global.fetchedAll)
      return;
    global.fetchedAll = true;
    async function fetchData() {
      console.log("FEC DATA LKEKT")
      if (data && data.group && data.group.length > 0) {

        function transformArrayToIds(array) {
          if (!Array.isArray(array) || array.length === 0) {
            return '';
          }

          if (array.length === 1) {
            return `Ids=${array[0]}`;
          } else {
            return array.map(num => `Ids=${num}`).join('&');
          }
        }

        getAnimals(transformArrayToIds(data.group.map(item => item.id)))

        fetchImagesForSpots(data.group.map(item => item.id));
        setName(generateMythicName(data.group[0].features));
        const baseUrl = global.FRONTURL + "/campsite/"
        const ids = data.group.map(item => item.id)
        const idsQuery = ids.map(id => `Ids=${id}`).join('&');
        const newURL = `${baseUrl}?${idsQuery}`;

        window.history.pushState({ path: newURL }, '', newURL);

      }
      else {
        const fetchedGroup = await getSpot();
        console.log("pocetak ------------------------------------------");
        console.log(localStorage.getItem("center"))
        // Ako nismo dobili prosledjeno


      }
    }

    fetchData();
  }, [plainFeatures]);

  useEffect(() => {
    if (!spotImages)
      return;

    Promise.all(spotImages.map(coord => generateMarkerImage(coord.url)))
      .then(newUrls => {
        setGeneratedMarkerUrls(newUrls);
      })
      .catch(error => {
        console.error("Error generating marker images:", error);
      });
  }, [spotImages]);

  async function getSpot() {
    console.log("GETSPOT LOL OLO LO");
    let fetchedFeatures = null;
    if (!plainFeatures || (plainFeatures.length == 0))
      fetchedFeatures = await fetchAttributesFromApi()

    const params = new URLSearchParams(location.search);
    const ids = params.getAll('Ids');
    setCampgroundIds(ids)
    if (!ids.length) {
      return
    }
    try {
      const response = await fetch(`${global.SERVIP}/api/getCampsite?${params.toString()}`);
      const data1 = await response.json();
      setGroup(data1);
      getAnimals(params.toString());
      fetchImagesForSpots(data1.map(item => item.id));
      setName(generateMythicName(data1[0].features, fetchedFeatures));
      if (!localStorage.getItem("center")) {
        fetchCookieCenterString()
          .then(center_string => {
            console.log("cs")
            console.log(center_string)
            const newCenter = parseCoordinateString(center_string)
            setCenter(newCenter);
            localStorage.setItem("center", center_string)
            setDistance(getDistanceFromLatLonInKm(data1[0].latitude, data1[0].longitude, newCenter[0], newCenter[1]))

          })

      }
      else {
        const oldCenter = parseCoordinateString(localStorage.getItem("center"))
        console.log("ovo je stari c")
        console.log(oldCenter)
        setCenter(parseCoordinateString(localStorage.getItem("center")))
        setDistance(getDistanceFromLatLonInKm(data1[0].latitude, data1[0].longitude, oldCenter[0], oldCenter[1]))

      }

      await getPlaceName(data1[0].latitude, data1[0].longitude)

    } catch (error) {
      console.error("Error fetching data:", error);
    }
    finally {
      return
    }
  }
  async function fetchAttributesFromApi() {
    try {
      const response = await fetch(global.SERVIP + '/api/bitmap2');

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const res = await response.json();
      setPlainFeatures(res)
      return res;
    } catch (error) {
      console.error(`Error fetching attributes: ${error}`);
      return [];
    }
  }
  const getRandomItem = (array, rng) => array[Math.floor(rng.double() * array.length)];
  const getSeed = () => {
    const ids = [...((data?.group ? (data.group.map(item => item.id)) : (new URLSearchParams(location.search)).getAll('Ids')).map(e => {
      try {
        return parseInt(e);
      }
      catch (e) {
        return 0;
      }
    }))]
    ids.sort();

    let result = 0;
    const BASE = 32771
    const MODULE = 2147483647

    let exp = 1
    for (let i = 0; i < ids.length; i++) {
      result = (result + ids[i] * exp) % MODULE;
      exp = (exp * BASE) % MODULE;
    }
    return result;
  }
  const generateMythicName = (featuresNumber, fetchedFeatures = null) => {
    let seed = getSeed();
    const rng = seedrandom(seed);

    let selectedNamingFeatures = selectNamingFeatures(featuresNumber, fetchedFeatures);
    let gotRandomItem = getRandomItem(selectedNamingFeatures, rng);
    let emojiMapItem = emojiMap[gotRandomItem]
    if (!emojiMapItem) {
      return `${getRandomItem(adjectives, rng)} ${getRandomItem(nouns_multiple, rng)}`;
    }
    const randomFeature = emojiMapItem.name;
    let rnd = rng.double();
    if (rnd < 0.3) {
      return `${randomFeature} of ${getRandomItem(adjectives, rng)} ${getRandomItem(nouns_multiple, rng)}`;
    }
    if (rnd < 0.65) {
      return `${randomFeature} of the ${getRandomItem(adjectives, rng)} ${getRandomItem(nouns, rng)}`;
    }
    else if (rnd < 0.95) {
      return `${getRandomItem(adjectives, rng)} ${getRandomItem(nouns, rng)} ${randomFeature}`;
    }
    else {
      return `${getRandomItem(adjectives, rng)} ${randomFeature}`;
    }


  }

  const concatGroupFeatures = (curGroup) => {
    let curr = []
    curGroup.forEach(element => {
      curr.push(element.features)
    });
    return curr
  }

  const concatGroupNames = (curGroup) => {
    const featureNumbers = concatGroupFeatures(curGroup)

    const featureNames = new Set()

    featureNumbers.forEach(number => {
      const curentNames = mapNumberToAttributes(number)
      curentNames.forEach(name => {
        if (!featureNames.has(name))
          featureNames.add(name)
      });
    });

    return Array.from(featureNames)
  }

  function mapNumberToAttributes(number) {
    const mappedAttributes = [];

    const lower32Bits = number & 0xFFFFFFFF; // Extract the lower 32 bits
    const upper32Bits = (number / Math.pow(2, 32)) & 0xFFFFFFFF; // Extract the upper 32 bits

    for (let i = 0; i < 32; i++) {
      if ((lower32Bits & (1 << i)) && i < plainFeatures.length) {
        mappedAttributes.push(plainFeatures[i]['name']);
      }
    }

    for (let i = 0; i < 32; i++) {
      if ((upper32Bits & (1 << i)) && i + 32 < plainFeatures.length) {
        mappedAttributes.push(plainFeatures[i + 32]['name']);
      }
    }
    return mappedAttributes;
  }



  function selectNamingFeatures(number, fetchedFeatures = null) {
    const attributes = fetchedFeatures || plainFeatures;
    const mappedAttributes = [];
    // Extract lower and upper 32 bits
    const lower32Bits = number & 0xFFFFFFFF;
    const upper32Bits = (number / 0x100000000) & 0xFFFFFFFF;

    for (let i = 0; i < 32; i++) {
      // Check lower 32 bits
      if (lower32Bits & (1 << i) && i < attributes.length) {
        const attribute = attributes[i];
        if (attribute.featureCategoryId === 4 || attribute.featureCategoryId === 6 || attribute.featureCategoryId === 7) {
          mappedAttributes.push(attribute['name']);
        }
      }

      // Check upper 32 bits if applicable
      if (i + 32 < attributes.length && upper32Bits & (1 << i)) {
        const attribute = attributes[i + 32];
        if (attribute.featureCategoryId === 4 || attribute.featureCategoryId === 6 || attribute.featureCategoryId === 7) {
          mappedAttributes.push(attribute['name']);
        }
      }
    }
    return mappedAttributes.filter(e => e);
  }

  const unzipData = (array) => {
    return array.map(e => {
      const split = e.split('|');
      let originalUrl;

      if (split[0].startsWith('g/')) {
        originalUrl = `https://lh5.googleusercontent.com/p/${split[0].substring(2)}`;
      } else if (split[0].startsWith('f')) {
        const parts = split[0].substring(1).split('/');
        const farm_number = `farm${parts[0]}`;
        const directory = parts[1];
        const filename = `${parts[2]}.jpg`;

        originalUrl = `https://${farm_number}.staticflickr.com/${directory}/${filename}`;
      } else {
        originalUrl = "Invalid URL";
      }

      return ({
        url: originalUrl,
        lat: parseFloat(split[1]),
        lng: parseFloat(split[2]),
        grade: 1
      });
    });
  }


  const fetchImagesForSpots = async (ids) => {
    try {
      const response = await fetch(global.SERVIP + '/api/getImagesForSpots', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(ids)
      });
      if (response.ok) {
        const responseData = await response.json();
        const unzipped = unzipData(responseData);
        setSpotImages(unzipped.map(e => ({ ...e, url_gallery: e.url })));
      } else {
        console.error("Failed to fetch images", await response.text());
      }
    } catch (error) {
      console.error("Error while fetching images:", error);
    }
  }

  useEffect(() => {
    setCurrentImageIndex(e => currentImageIndex)
  }, [inFullScreen])

  useEffect(() => {
    console.log("sse")
    if (galleryRef.current) {
      galleryRef.current.slideToIndex(currentImageIndex);
    }
    if (spotImages && spotImages[currentImageIndex])
      setMapCenter({ lat: spotImages[currentImageIndex].lat, lng: spotImages[currentImageIndex].lng });
    console.log("pk")

    console.log(galleryRef.current)





  }, [currentImageIndex]);

  const addSurroundingPoints = (coord) => {
    // Directions in degrees for up, down, left, right
    const cardinalDirections = [0, 180, 270, 90];
    const diagonalDistance = 1; // For 0.5km diagonally
    // Directions in degrees for top-left, top-right, bottom-left, bottom-right
    const diagonalDirections = [315, 45, 225, 135];

    const newPoints = [];

    cardinalDirections.forEach(direction => {
      const translatedPoint = turf.destination(coord, 1, direction);
      newPoints.push(translatedPoint.geometry.coordinates);
    });

    diagonalDirections.forEach(direction => {
      const translatedPoint = turf.destination(coord, diagonalDistance, direction);
      newPoints.push(translatedPoint.geometry.coordinates);
    });

    return newPoints;
  };

  const getSurroundingPoints = (coord) => {
    const key = `${coord[0].toFixed(5)},${coord[1].toFixed(5)}`;
    if (surroundingPointsCache.has(key)) {
      return surroundingPointsCache.get(key);
    }

    const result = addSurroundingPoints(coord);
    surroundingPointsCache.set(key, result);
    return result;
  };

  const pointToString = pt => `${pt[0].toFixed(5)},${pt[1].toFixed(5)}`;
  const stringToPoint = str => str.split(',').map(Number);
  const getGroupNames = (groupData) => {
    if (!groupData)
      return []
    const groups = {}
    for (let i of groupData)
      groups[emojiMap[i].group] = true;
    return Object.keys(groups)
  }
  const filterForGroup = (groupData, group) => {
    return groupData.filter(e => (emojiMap[e].group == group))
  }
  const navigateToCampsite = (images, coordinates, group, plainFeatures) => {
    const dataToPass = {
      coordinates,
      group,
      plainFeatures
    };

    navigate('/campsite', { state: dataToPass });
  };
  const renderPolygonsAndMarkers = (focusIndex) => {
    const polygons = [];
    const markers = [];
    if (group.length == 0)
      return
    let uniqueSurroundingPoints = new Set();
    let spot_id, spot_counter = 0;
    group.forEach(spot => {

      const coord = [spot.longitude, spot.latitude];
      if (spot_counter == 0) {
        spot_id = spot.longitude + ',' + spot.latitude
      }
      if (spot_counter == group.length - 1) {
        spot_id += spot.longitude + ',' + spot.latitude;
      }
      spot_counter++;
      const surroundingPoints = getSurroundingPoints(coord);
      surroundingPoints.forEach(pt => {
        uniqueSurroundingPoints.add(pointToString(pt));
      });
    });

    // Convert the Set back to an array of points for further calculations
    let surroundingPointsList = [...uniqueSurroundingPoints].map(stringToPoint);

    if (group.length >= 3) {
      const hull = convex(featureCollection(group.map(spot => point([spot.longitude, spot.latitude]))));
      if (!hull || hull.geometry.coordinates[0].length < 3) {

      }
      else {
        surroundingPointsList = surroundingPointsList.filter(pt => !turf.booleanPointInPolygon(pt, hull));
      }
    }

    // Compute the convex hull of the surrounding points
    const newHull = convex(featureCollection(surroundingPointsList.map(pt => point(pt))));
    if (!newHull) return;

    const newHullCoordinates = newHull.geometry.coordinates[0].map(coord => [coord[1], coord[0]]);
    polygons.push(<Polygon key={spot_id + 'p'} positions={newHullCoordinates} color="#ec6313"
      eventHandlers={{
        click: async (e) => {

        },
        // You can keep the mouseover event or remove it based on your requirements
        mouseover: (e) => {
        }
      }}
    />);

    // Compute centroid for the marker placement
    const centroid = turf.centroid(newHull);
    const position = [centroid.geometry.coordinates[1], centroid.geometry.coordinates[0]];
    markers.push(
      <Marker
        key={spot_id + 'm'}
        position={position}
        eventHandlers={{
          click: async (e) => {


          },
          // You can keep the mouseover event or remove it based on your requirements
        }}
      >
        <Popup style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        </Popup>
      </Marker>



    );

    return polygons
  };

  const images = spotImages ? spotImages.map((img, index) => ({
    image: img.url_gallery,//+"=w800-k-no",
    // thumbnail: img.url_gallery,// + "=w80-k-no",
    // thumbnailLoading: "lazy",
    update: () => { setCounter(e => (e + 1)) },
    fullscreen: inFullScreen,
    myIndex: index,
    renderItem: ImageGalleryImage
  })) : null
  const LeftNav = (onClick, disabled) => (
    <button
      style={{
        position: 'absolute',
        left: 0,
        paddingBottom: 50,
        paddingTop: 50,
        paddingRight: 12,
        paddingLeft: 12,
        top: '50%',
        zIndex: 4,
        backgroundColor: 'transparent',
        border: 'none',
        outline: 'none',
        cursor: 'pointer',
        transform: 'translateY(-50%) rotate(180deg)' // Rotate the image 180 degrees
      }}
      onClick={onClick}
      disabled={disabled}
      aria-label="Previous Image"
    >
      <img src={backArrow} alt="Previous" style={{ width: '40px', height: '40px', borderRadius: 50 }} />
    </button>
  );

  const RightNav = (onClick, disabled) => (
    <button
      style={{
        position: 'absolute',
        top: '50%',
        zIndex: 4,
        backgroundColor: 'transparent',
        border: 'none',
        outline: 'none',
        cursor: 'pointer',
        transform: 'translateY(-50%)',
        right: 0,
        paddingBottom: 50,
        paddingTop: 50,
        paddingRight: 12,
        paddingLeft: 12
      }}
      onClick={onClick}
      disabled={disabled}
      aria-label="Next Image"
    >
      <img src={backArrow} alt="Next" style={{ width: '40px', height: '40px', borderRadius: 50 }} />
    </button>
  );



  function ChangeView({ center }) {
    const map = useMap();

    map.invalidateSize();
    map.setView(center);
    return null; // return null since we don't want to render anything
  }
  function RateCampsite(curGrade) {
    setGrade(curGrade);
    toggleGrade()
  }
  return (

    <div className="campsite-page" ref={containerRef}>
      <CampingResponsiblyModal></CampingResponsiblyModal>
      <header>
        <img src={close} className="close-icon" onClick={() => {
          global.fetchedAll = false;
          navigate('/map')
        }
        } alt="close" />
      </header>
      <div className="left-side">

        <div style={{ textAlign: "center", marginRight: 0, marginTop: "50px", marginLeft: "20px" }}>
          <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: 'start', marginTop: 20 }}>
            <h3 className="h1-place-name" style={{ color: 'white', }}>{name}</h3>

          </div>
          <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: 'space-between', marginTop: 30 }} onClick={() => console.log(center)}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: 'start' }}>
              <a style={{ color: 'whitesmoke', fontSize: '0.9em' }}>Near {placeName} about {distance} km from the selected location</a>
              <RatingComponent report={toggle} RateCampsite={RateCampsite} />

            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", width: '100%', textAlign: "left", maxWidth: '1000px' }}>
            <p className="card-area" style={{ color: 'white', textAlign: "left", fontSize: '0.8em' }}>* Mapped area is {group?.length + " square kilometer,"} but there might be more interesting spots nearby. We guarantee that the orange area of the map contains all of the features we have listed for this campsite, but feel free to explor the rest.</p>

          </div>

          <br />
          <div style={{ display: 'flex', justifyContent: 'start' }}>
            <div className="button-containerc" onClick={() => { console.log(mapNumberToAttributes([group.features])) }}>
              {group ? <ShareButton ids={group.map(element => element.id)} /> : null}

              <DirectionsButton lat={center ? center[0] : undefined} lng={center ? center[1] : undefined} />
              <GoogleEarthButton lat={center ? center[0] : undefined} lng={center ? center[1] : undefined} />
            </div>
          </div>

        </div>
        <div className="images-map-container">
          <div className="spot-images-container" ref={spotImagesContainerRef}>
            {images?.length > 0 ?
              <ImageGallery
                onScreenChange={setInFullScreen}
                onSlide={(e) => {
                  if (spotImages[e]) {
                    //setCenter({ lat: spotImages[e].lat, lng: spotImages[e].lng });
                    setCurrentImageIndex(e);
                  }
                }}
                startIndex={currentImageIndex}
                showIndex={true}
                useTranslate3D={false}
                ref={galleryRef}
                items={images}
                showFullscreenButton={true}
                showThumbnails={false}
                lazyLoad={true}
                onClick={() => {
                  if (galleryRef.current) {
                    galleryRef.current.toggleFullScreen();
                  }
                }}
                renderLeftNav={LeftNav}
                renderRightNav={RightNav}
              ></ImageGallery>
              :
              <div className="no-images">
                <a>{(images?.length === 0) ? "We do not have images for the selected place" : "Loading images ..."}</a>
              </div>}
          </div>
          {group && <MapContainer center={[mapCenter.lat, mapCenter.lng]} zoom={zoom} className="minimap">
            <ChangeView center={[mapCenter.lat, mapCenter.lng]} />
            <LayersControl position="topright">
              <BaseLayer checked name="World Imagery">
                <TileLayer
                  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                  maxZoom={19}
                />
              </BaseLayer>
              <BaseLayer name="OpenStreetMap">
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
              </BaseLayer>
              {/* Add additional layers here */}
            </LayersControl>                {renderPolygonsAndMarkers()}
            {spotImages?.length > 0 && spotImages.filter((_, idx) => generatedMarkerUrls[idx]).map((coords, idx) => (
              <Marker
                key={idx}
                position={[coords.lat, coords.lng]}
                icon={L.icon({
                  iconUrl: generatedMarkerUrls[idx],
                  iconSize: [60, 60], // size of the icon
                  iconAnchor: [30, 30], // point of the icon which will correspond to marker's location, centered
                  popupAnchor: [0, -30]
                })}
                eventHandlers={{
                  click: () => {
                    setCurrentImageIndex(idx);
                  }
                }}
              >
                {/*<Popup>
                  <img src={coords.url} alt="Spot Image" style={{ width: '100px', height: '100px' }} />
                </Popup>*/}
              </Marker>
            ))}
          </MapContainer>}


        </div>
        <div className="emojis" style={{ borderTop: "1px solid #333", marginRight: '0px' }}>
          {plainFeatures && group ? (
            getGroupNames(concatGroupNames(group)).map(name => (
              <>
                <h4 style={{ color: 'white' }}>{name}</h4>
                {filterForGroup(concatGroupNames(group), name)
                  .map(item => (
                    <p style={{ color: 'white', fontWeight: "normal", width: "60%" }}>{emojiMap[item]?.emoji} {emojiMap[item]?.description}</p>
                  ))}
              </>
            ))
          ) : null}
        </div>

        <div className="bottom-left">



        </div>

      </div >

      <div className={`right-side`} ref={rightDivRef}>
        {group && <WeatherForecast center={{ lat: group[0].latitude, lng: group[0].longitude }} placeName={placeName} />}
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start', marginTop: '15px' }}>
          {
            (animals.length > 0) ? <>
              <h4 style={{ color: 'orange', marginBottom: "10px" }}>Animals</h4>
              {animals.map(e => {
                return <AnimalWarrning id={e.id} distance={e.distance}></AnimalWarrning>
              })}
            </> : null
          }
        </div>


      </div>
      <Modal isOpen={modalReport} toggle={toggle} className="modal-dialog-centered special_modal" >
        <ModalHeader toggle={toggle} style={{ border: "0px solid #ec6313", }}>Report this campsite</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="campsiteType">Reason for reporting</Label>
              <Input
                type="select"
                name="campsiteType"
                id="campsiteType"
                className="custom-textarea"
                onChange={(e) => setReportReason(e.target.value)}
                style={{
                  backgroundColor: "#2d2d2d",
                  color: "white",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "0px solid #ec6313",
                }}
              >
                <option value="1">Information not accurate</option>
                <option value="2">Too crowded</option>
                <option value="3">Private property</option>
                <option value="4">Not accessible</option>
                <option value="5">Dangerous place</option>
                <option value="99">Other (please leave a comment)</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Input
                type="textarea"
                name="explanation"
                id="explanation"
                className="custom-textarea"
                onChange={(e) => setComment(e.target.value)}

                placeholder="Provide additional details if needed"
                style={{
                  backgroundColor: "#2d2d2d",
                  color: "white",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "0px solid #ec6313",
                  height: "100px", // Larger height for more space
                  textAlign: "left", // Ensure text aligns to the left
                  verticalAlign: "top", // Ensure text starts from the top-left corner
                  resize: "none", // Prevent user resizing the textarea
                }}
              />            </FormGroup>
            <FormGroup>
              <Input
                type="email"
                name="email"
                id="email"
                className="custom-textarea"

                onInput={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                value={email}
                style={{
                  backgroundColor: "#2d2d2d",
                  color: "white",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "0px solid #ec6313",
                  marginTop: "10px",
                }}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => reportCampsite(comment, email, reportReason)} style={{ backgroundColor: '#ec6313' }}>
            Report
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalGrade} toggle={toggleGrade} className="modal-dialog-centered special_modal">
        <ModalHeader toggle={toggleGrade} style={{ border: "0px solid #ec6313" }}>
          Review this campsite
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Input
                type="textarea"
                name="explanation"
                id="explanation"
                className="custom-textarea"
                onChange={(e) => setComment(e.target.value)}
                placeholder="Provide additional details"
                value={comment}
                style={{
                  backgroundColor: "#2d2d2d",
                  color: "white",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "0px solid #ec6313",
                  height: "100px",
                  textAlign: "left",
                  verticalAlign: "top",
                  resize: "none",
                }}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="email"
                name="email"
                id="email"
                className="custom-textarea"

                onInput={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                value={email}
                style={{
                  backgroundColor: "#2d2d2d",
                  color: "white",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "0px solid #ec6313",
                  marginTop: "10px",
                }}
              />
            </FormGroup>
            <FormGroup>
              <Input
                className="custom-textarea"

                type="username"
                name="username"
                id="username"

                onInput={(e) => setUsername(e.target.value)}
                placeholder="Enter your username"
                value={username}
                style={{
                  backgroundColor: "#2d2d2d",
                  color: "white",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "0px solid #ec6313",
                  marginTop: "10px",
                }}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => rateCampsite(comment, grade, email, username)} style={{ backgroundColor: '#ec6313' }}>
            Review
          </Button>
          <Button color="secondary" onClick={toggleGrade}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div >

  );
}

export default CampsitePage;

/*
  <div className="emojis">
            {(plainFeatures && group) ? concatGroupNames(group).map((item, index) =>

              <Tooltip emoji={emojiMap[item]?.emoji} name={emojiMap[item]?.name} color={emojiMap[item]?.color} fontColor={emojiMap[item]?.textColor} description={emojiMap[item]?.brief} />
            ) : null}
          </div>
*/

/*
          <a className="prompt-search-button" style={{width:'87%', color:'white'}}  onClick={() => navigate('/')}>Find more off-grid spots like this one?</a>

<div className="button-containerc">
{group ? <CopyCoordinatesButton ids={group.map(element => element.id)} /> : null}
<GoogleEarthButton lat={center?.lat} lng={center?.lng} />
<DirectionsButton lat={center?.lat} lng={center?.lng} />

</div>

 {(!window.matchMedia("(max-width: 768px)").matches && center && !inFullScreen) && (
          <MapContainer center={center} zoom={zoom} className="minimap">
            <ChangeView center={center} />
            <LayersControl position="topright">
              <BaseLayer checked name="World Imagery">
                <TileLayer
                  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                  maxZoom={19}
                />
              </BaseLayer>
              <BaseLayer name="OpenStreetMap">
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
              </BaseLayer>
              </LayersControl>                {renderPolygonsAndMarkers()}
              {spotImages?.length > 0 && spotImages.filter((_, idx) => generatedMarkerUrls[idx]).map((coords, idx) => (
                <Marker
                  key={idx}
                  position={[coords.lat, coords.lng]}
                  icon={L.icon({
                    iconUrl: generatedMarkerUrls[idx],
                    iconSize: [60, 60], // size of the icon
                    iconAnchor: [30, 30], // point of the icon which will correspond to marker's location, centered
                    popupAnchor: [0, -30]
                  })}
                  eventHandlers={{
                    click: () => {
                      setCurrentImageIndex(idx);
                    }
                  }}
                >
      
                </Marker>
              ))}
            </MapContainer>
          )} */
