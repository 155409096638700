import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import emojiMap from '../../../../utils/EmojiMap';
import WaterwaysIcon from '../../../../assets/waterways.png';
import TourismIcon from '../../../../assets/tourism.png';
import NaturalIcon from '../../../../assets/natural.png';
import LeisureIcon from '../../../../assets/leisure.png';
import LandUsesIcon from '../../../../assets/landuses.png';
import HighwaysIcon from '../../../../assets/highways.png';
import AmenitiesIcon from '../../../../assets/bonfire.png';
import WaterAreasIcon from '../../../../assets/waterareas.png';
import makeAnimated from 'react-select/animated';
import Select, { components }  from 'react-select';

const category_names = {
    "highway": "Travel methods",
    "leisure": "Leisure",
    "amenity": "Amenity",
    "natural": "Natural",
    "tourism": "Tourism",
    "waterway": "Waterways",
    "waterarea": "Water areas",
  }
  const icons = {
    waterway: WaterwaysIcon,
    tourism: TourismIcon,
    natural: NaturalIcon,
    leisure: LeisureIcon,
    highway: HighwaysIcon,
    amenity: AmenitiesIcon,
    waterarea: WaterAreasIcon
  };



  const getSelectStyles = () => {
    return {
      control: (provided) => ({
        ...provided,
        backgroundColor: '#222', // Even darker background for the dropdown control
        color: '#ccc', // Light gray text for better contrast without being too bright
        border: '0px solid #444', // Thinner, subtler border
        boxShadow: 'none', // Remove box shadow for a flatter, cleaner look
        minHeight: '36px', // Reduce the size for a more compact appearance
        '&:hover': {
          borderColor: '#666' // Slightly lighter border on hover for subtle interaction feedback
        },
        marginTop:'5px'
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#333' : 'transparent', // Use transparent for non-selected, darker for selected
        color: state.isSelected ? '#fff' : '#ccc', // White for selected, light gray for others
        '&:hover': {
          backgroundColor: '#444', // Dark gray on hover
          color: '#fff' // White text on hover
        },
        padding: '10px 20px' // Padding for better text alignment and spacing
      }),
      menu: (provided) => ({
        ...provided,
        backgroundColor: '#222', // Dark background for the dropdown menu
        borderColor: '#444' // Dark border for the dropdown menu
      }),
      multiValue: (provided) => ({
        ...provided,
        backgroundColor: '#333', // Dark background for selected items
        color: '#ccc' // Light gray text
      }),
      multiValueLabel: (provided) => ({
        ...provided,
        color: '#ccc' // Consistent text color for labels
      }),
     
      dropdownIndicator: (provided) => ({
        ...provided,
        color: '#ccc' // Light gray dropdown indicators
      }),
      clearIndicator: (provided) => ({
        ...provided,
        color: '#ccc' // Light gray clear indicators
      }),
    };
  };
  

  
  
  const DropdownFeatureCategory = ({ category, features, handleCheckboxChange, selectedFeatures }) => {
    const animatedComponents = makeAnimated();

    const options = features.map(feature => ({
      value: feature,
      label: `${emojiMap[feature]?emojiMap[feature].name:feature} ${emojiMap[feature] ? emojiMap[feature].emoji : ''}`
    }));
  
    const selectedOptions = selectedFeatures[category] || [];
  
    const handleChange = (newSelectedOptions) => {
      const newValues = newSelectedOptions ? newSelectedOptions.map(option => option.value) : [];
      const previousValues = selectedOptions;
  
      // Determine changes
      const added = newValues.filter(value => !previousValues.includes(value));
      const removed = previousValues.filter(value => !newValues.includes(value));
  
      // Handle added items
      added.forEach(feature => {
        handleCheckboxChange(category, feature, true, selectedFeatures);
      });
  
      // Handle removed items
      removed.forEach(feature => {
        handleCheckboxChange(category, feature, false, selectedFeatures);
      });
    };
  
    return (
      <Select
        value={selectedOptions.map(feature => ({ value: feature, label:  `${emojiMap[feature]?emojiMap[feature].name:feature} ${emojiMap[feature] ? emojiMap[feature].emoji : ''}` }))}
        isMulti
        name={category}
        options={options}
        classNamePrefix="Select"
        onChange={handleChange}
        styles={getSelectStyles()}
        components={animatedComponents}

      />
    );
  };
  
const AdvancedFeaturesList = ({ features, handleCheckboxChange, selectedFeatures }) => (
  <div className="advancedFeaturesList">
    {Object.keys(features).map(category => (
      <div key={category} className="feature-category">
        <h4>
          <img src={icons[category]} alt={category} style={{ width: 30, height: 30, marginRight: 5 }} />
          <a> {category_names[category]}</a>
        </h4>
        <DropdownFeatureCategory
          category={category}
          features={features[category]}
          handleCheckboxChange={handleCheckboxChange}
          selectedFeatures={selectedFeatures}
        />
      </div>
    ))}
  </div>
);

export default AdvancedFeaturesList;
