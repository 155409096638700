import React, { useState, useRef, useEffect } from 'react';
import styles from './a.module.css';

function CampingResponsiblyModal() {
    const [isOpen, setIsOpen] = useState(!localStorage.getItem("disclaimerShown"));
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const contentRef = useRef(null);

    const handleScroll = () => {
        if (contentRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
            if (Math.abs(scrollHeight - scrollTop - clientHeight) <= 10 ) {
                setIsButtonEnabled(true);
            }
        }
    };

    useEffect(() => {
        if (contentRef.current) {
            const { scrollHeight, clientHeight } = contentRef.current;
            if (scrollHeight <= clientHeight) {
                setIsButtonEnabled(true);
            }
            contentRef.current.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (contentRef.current) {
                contentRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    return (
        <div style={{
            display: isOpen ? 'block' : 'none',
            position: 'fixed',
            top: 0, left: 0, right: 0, bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            zIndex: 20000
        }}>
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: 'center',
                width: "100%",
                height: "100%"
            }}>
                <div style={{
                    maxWidth: '600px',
                    width: '90%',
                    maxHeight: "65%",
                    margin: 'auto auto',
                    padding: '20px',
                    backgroundColor: '#3a3a3a',
                    borderRadius: '10px',
                    textAlign: 'left',
                    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.4)',
                    fontFamily: '"Roboto Mono", sans-serif',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <div style={{
                        flex: '0 0 auto',
                        paddingBottom: '10px',
                        borderBottom: '1px solid #555',
                    }}>
                        <p style={{
                            color: '#ddd',
                            fontSize: '22px',
                            margin: '10px 0',
                            fontWeight: '600'
                        }}>
                            Camping Responsibly
                        </p>
                    </div>
                    <div
                        ref={contentRef}
                        className={styles.customScrollbar}
                        style={{
                            flex: '1 1 auto',
                            overflowY: 'auto',
                            margin: '10px 0',
                            paddingRight: "10px"
                        }}>
                        <p style={{ color: '#ddd', fontSize: '16px', margin: '10px 0' }}>Camping offers us a unique opportunity to connect with nature. To preserve the beauty and integrity of our natural surroundings, it's crucial to practice responsible camping. </p>
                        <p style={{ color: '#ddd', fontSize: '16px', margin: '10px 0' }}>Here are key guidelines:</p>
                        <p style={{ color: '#ddd', fontSize: '16px', margin: '10px 0' }}><strong>1. Leave No Trace:</strong> Always clean up after yourself and aim to leave the campsite better than you found it. Pack out all trash and leftovers.</p>
                        <p style={{ color: '#ddd', fontSize: '16px', margin: '10px 0' }}><strong>2. Respect Wildlife:</strong> Maintain a safe distance from animals. Do not feed wildlife or disturb their natural habitats.</p>
                        <p style={{ color: '#ddd', fontSize: '16px', margin: '10px 0' }}><strong>3. Manage Fire Responsibly:</strong> Use established fire rings, keep fires small, and ensure they are completely extinguished before you leave.</p>
                        <p style={{ color: '#ddd', fontSize: '16px', margin: '10px 0' }}><strong>4. Conserve Water:</strong> Use biodegradable soap and avoid contaminating water sources by washing at least 200 feet away.</p>
                        <p style={{ color: '#ddd', fontSize: '16px', margin: '10px 0' }}><strong>5. Be Considerate of Other Visitors:</strong> Keep noise to a minimum and respect the privacy and experience of other campers.</p>
                        <p style={{ color: '#ddd', fontSize: '16px', margin: '10px 0' }}>For more detailed guidelines and tips on how to camp responsibly, please visit <a style={{ fontSize: "16px", color: "#aaa" }} href='https://lnt.org/why/7-principles/' target='_blank'>Leave No Trace</a>.</p>
                    </div>
                    <div style={{
                        flex: '0 0 auto',
                        paddingTop: '10px',
                        borderTop: '1px solid #555',
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <button
                            onClick={() => {
                                localStorage.setItem("disclaimerShown", "true")
                                setIsOpen(false)
                            }}
                            disabled={!isButtonEnabled}
                            style={{
                                backgroundColor: isButtonEnabled ? '#ec6313' : '#ec6313a0',
                                color: 'white',
                                border: 'none',
                                padding: '10px 20px',
                                borderRadius: '5px',
                                fontSize: '16px',
                                cursor: isButtonEnabled ? 'pointer' : 'not-allowed',
                                transition: 'background-color 0.3s',
                                fontWeight: '600',
                                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                                margin: '20px'
                            }}
                            onMouseOver={(e) => isButtonEnabled && (e.target.style.backgroundColor = '#d4580a')}
                            onMouseOut={(e) => isButtonEnabled && (e.target.style.backgroundColor = '#ec6313')}
                        >
                            {isButtonEnabled ? 'I agree' : 'Please read the disclaimer'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CampingResponsiblyModal;
