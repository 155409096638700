import React from 'react';
import './Donate.css';

function Donate() {
  return (
    <section id="donate" class="details">
          
    <div class="container" data-aos="fade-right">
      <div class="section-title" >
        <h2 style={{ marginLeft: '0', textAlign: 'left' }}>Donate</h2>
        <p>Support Us</p>
      </div>

      <div class="row content">
        <div class="col-md-4">
          <img src="assets/img/details-1.jpg" class="img-fluid" alt=""></img>
        </div>
        <div class="col-md-8 pt-4">
          
          <h3>Accelerate Our Vision: Your Contribution Counts!</h3>
          <p class="fst-italic">
            Your generous support is the key to expediting the development of our application and mapping your country. Join us in this mission, and witness the impact of your contribution on our shared goals.
          </p>
          <ul>
            <li><i class="bi bi-check"></i> Drive accurate mapping initiatives for their nation's benefit.</li>
            <li><i class="bi bi-check"></i> Empower cutting-edge technology for faster progress</li>
            <li><i class="bi bi-check"></i> Enhance user experience with innovative features.</li>
          </ul>

          <div className='kutija'>
            <button
              className='donatedugme'
              onClick={() => window.open("https://www.buymeacoffee.com/tribalcamping", "_blank", "noopener,noreferrer")}>Donate</button>
          </div>

          
        </div>
      </div>

    </div>
</section>
  );
}

export default Donate;