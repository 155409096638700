import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import { fetchWeatherApi } from 'openmeteo';
import styles from './WeatherForecast.module.css'; // Import the CSS module
import WeatherCard from './WeatherCard';
import WeatherCodes from '../../utils/WeatherCodes';
function WeatherForecast({center,placeName}) {
    const [date, setDate] = useState(new Date());
    const [weatherData, setWeatherData] = useState(null);
    const [error, setError] = useState('');
    const today = new Date();
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    // Generate an array of the next seven days
    const days = Array.from({ length: 7 }, (_, i) => {
        const date1 = new Date(today);
        date1.setDate(today.getDate() + i);
        return date1;
    });

    // Get current month for the header
    const currentMonth = months[today.getMonth()];
    useEffect(() => {
        async function fetchData() {
            const params = {
                "latitude": center.lat,
                "longitude": center.lng,
                "current": ["temperature_2m", "relative_humidity_2m", "is_day", "precipitation", "rain", "showers", "snowfall", "weather_code", "wind_speed_10m"],
                "daily": ["weather_code", "temperature_2m_max", "temperature_2m_min", "sunrise", "sunset", "daylight_duration", "sunshine_duration", "uv_index_max", "precipitation_sum", "showers_sum", "precipitation_hours", "precipitation_probability_max", "wind_speed_10m_max"]
            };
            const url = "https://api.open-meteo.com/v1/forecast";
            try {
                const responses = await fetchWeatherApi(url, params);
                if (responses && responses.length > 0) {
                    const response = responses[0];
                    if(response.current && response.daily) {
                        setWeatherData(processWeatherData(response));
                    } else {
                        setError('Weather data is incomplete');
                    }
                } else {
                    setError('No weather data available');
                }
            } catch (err) {
                setError('Failed to fetch weather data');
                console.error(err);
            }
        }
        fetchData();
    }, []);

    function processWeatherData(response) {
        const utcOffsetSeconds = response.utcOffsetSeconds();
        const current = response.current();
        const daily = response.daily();
        const timeRange = range(Number(daily.time()), Number(daily.timeEnd()), daily.interval());

        return {
            current: {
                time: new Date((Number(current.time()) + utcOffsetSeconds) * 1000),
                temperature2m: current.variables(0).value(),
                relativeHumidity2m: current.variables(1).value(),
                isDay: current.variables(2).value(),
                precipitation: current.variables(3).value(),
                rain: current.variables(4).value(),
                showers: current.variables(5).value(),
                snowfall: current.variables(6).value(),
                weatherCode: current.variables(7).value(),
                windSpeed10m: current.variables(8).value(),
            },
            daily: {
                time: timeRange.map(t => new Date((t + utcOffsetSeconds) * 1000)),
                weatherCode: daily.variables(0).valuesArray(),
                temperature2mMax: daily.variables(1).valuesArray(),
                temperature2mMin: daily.variables(2).valuesArray(),
                sunrise: daily.variables(3).valuesArray(),
                sunset: daily.variables(4).valuesArray(),
                daylightDuration: daily.variables(5).valuesArray(),
                sunshineDuration: daily.variables(6).valuesArray(),
                uvIndexMax: daily.variables(7).valuesArray(),
                precipitationSum: daily.variables(8).valuesArray(),
                showersSum: daily.variables(9).valuesArray(),
                precipitationHours: daily.variables(10).valuesArray(),
                precipitationProbabilityMax: daily.variables(11).valuesArray(),
                windSpeed10mMax: daily.variables(12).valuesArray(),
            }
        };
    }

    function range(start, stop, step) {
        return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
    }

    const renderWeatherDataForSelectedDate = () => {
        if (!weatherData) return <p>No data available.</p>;
        const index = weatherData.daily.time.findIndex(time => time.toLocaleDateString() === date.toLocaleDateString());
        if (index === -1) return <p>No data for this day.</p>;

        return (
            <div>
                <h3>Current Conditions</h3>
                <p>Temperature: {weatherData.current.temperature2m} °C</p>
                <p>Humidity: {weatherData.current.relativeHumidity2m} %</p>
                <p>Precipitation: {weatherData.current.precipitation} mm</p>
                <p>Weather Code: {weatherData.current.weatherCode}</p>
                <h3>Forecast for {weatherData.daily.time[index].toLocaleDateString()}</h3>
                <p>Max Temp: {weatherData.daily.temperature2mMax[index]} °C</p>
                <p>Min Temp: {weatherData.daily.temperature2mMin[index]} °C</p>
                <p>Precipitation Probability Max: {weatherData.daily.precipitationProbabilityMax[index]}%</p>
                {/* Add more fields as needed */}
            </div>
        );
    };
    const renderWeatherCard = () => {
        if (!weatherData) return <p>No data available.</p>;
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const selectedIndex = weatherData.daily.time.findIndex(time => 
            new Date(time).toLocaleDateString() === date.toLocaleDateString()
        );

        const isToday = date.toLocaleDateString() === today.toLocaleDateString();
        const temperature = isToday ? Math.round(weatherData.current.temperature2m) : Math.round(weatherData.daily.temperature2mMax[selectedIndex]);
        const lowestTemperature = Math.round(weatherData.daily.temperature2mMin[selectedIndex]);
        const highestTemperature = Math.round(weatherData.daily.temperature2mMax[selectedIndex]);
        const weatherCode = isToday ? weatherData.current.weatherCode : weatherData.daily.weatherCode[selectedIndex];
        const weatherDescription = WeatherCodes[weatherCode]; // Use the weather code to get the description

        return (
            <WeatherCard
                place={placeName}
                date={date}
                temperature={temperature}
                lowestTemperature={lowestTemperature}
                highestTemperature={highestTemperature}
                weatherDescription={weatherDescription}
                weatherCode = {weatherCode}
            />
        );
    };
    
    return (
        <div>
            <div>
                <a style={{color:'whitesmoke'}}>Weather conditions throughout week</a>
            <div style={{  padding: '10px', borderRadius: '15px', color: '#fff', fontFamily: 'Roboto Mono' }}>
                <a style={{ color: '#bbb', margin: '0 0 20px 0' }}>{currentMonth}</a>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr>
                            {days.map((day, index) => (
                                <th key={index} style={{ padding: '15px', borderBottom: '1px solid #555', cursor: 'pointer' }}
                                    onMouseOver={(e) => e.currentTarget.style.color = '#aaa'}
                                    onMouseOut={(e) => e.currentTarget.style.color = '#fff'}>
                                    {daysOfWeek[day.getDay()][0]} 
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody >
                        <br/>
                        <tr>
                            {days.map((day, index) => (
                            <td key={index} onClick={() => setDate(day)} style={{ 
                                textAlign: 'center', 
                                cursor: 'pointer', 
                                borderRadius:50,
                                margin:5,
                                backgroundColor: date.getDate() === day.getDate() && date.getMonth() === day.getMonth() && date.getFullYear() === day.getFullYear() ? "orange" : "",
                                }}
                                onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#444'}
                                onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'transparent'}>
                                {day.getDate()}
                            </td>
                        ))}
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
            {error && <p>{error}</p>}
            <div style={{display:'flex', justifyContent:'center', marginTop:'10px'}}>
            {renderWeatherCard()}
            </div>
        </div>
    );
}

export default WeatherForecast;
