import React from "react";
import './ButtonStyles.css'

function CopyCoordinatesButton({ ids }) {
    const copyToClipboard = (e) => {
        e.preventDefault();
        const baseUrl = global.FRONTURL + "/campsite/";
        const idsQuery = ids.map(id => `Ids=${id}`).join('&');
        const textToCopy = `${baseUrl}?${idsQuery}`;
    
        if (navigator.clipboard) {
            navigator.clipboard.writeText(textToCopy).then(() => {
                alert('Link copied to clipboard!');
            }).catch(err => {
                alert('Failed to copy text to clipboard. Please copy the URL manually.');
            });
        } else {
            alert('Clipboard feature not supported in this browser. Please copy the URL manually.');
        }
    };
    

    return (
        <a href="#" className="prompt-search-button"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }} class="CampsiteScreenButton" onClick={copyToClipboard}>
            <span className="text-2">SHARE spot</span>
        </a>
    );
}

export default CopyCoordinatesButton;

