import React, { useEffect } from 'react';

function preloadImages(urls, callback) {
  let loadedCount = 0;
  const images = [];

  urls.forEach((url) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      loadedCount += 1;
      if (loadedCount === urls.length) {
        callback();
      }
    };
    images.push(img);
  });
}

function Phases() {


  return (
    <section id="phases" className="details">
      <div className="container">
        <div className="row content" data-aos="fade-left">
          <div className="col-md-4 order-1 order-md-2">
            <img src="https://i.imgur.com/pK7lbDO.jpg" className="img-fluid" alt="" loading="lazy" />
          </div>
          <div className="col-md-8 pt-5 order-2 order-md-1">
            <h3>Phase 1: Campsite Search Engine</h3>
            <p className="fst-italic">Welcome to Tribal's Phase 1. This is where our journey began:</p>
            <ul>
              <li><i className="bi bi-check"></i> From waterfalls, lakes, and caves to firepits and more. We bring you sites you won't find elsewhere.</li>
              <li><i className="bi bi-check"></i> Explore revolutionary image recognition and map scraping technology, transforming your camping spot discovery.</li>
              <li><i className="bi bi-check"></i> Every camping feature you desire, we have it mapped out, ensuring you find the perfect spot every time.</li>
            </ul>
            <p>Dive in and find the camping site of your dreams!</p>
          </div>
        </div>

        <div className="row content" data-aos="fade-right">
          <div className="col-md-4">
            <img src="https://i.imgur.com/6qbizZ5.jpg" className="img-fluid" alt="" loading="lazy" />
          </div>
          <div className="col-md-8 pt-5">
            <h3>Phase 2: Social Camping</h3>
            <p>Welcome to Tribal's Phase 2! 🎉</p>
            <p>While Phase 1 helped you discover magical camping spots, Phase 2 is all about elevating your camping experience.</p>
            <ul>
              <li><i className="bi bi-check"></i> Get suggestions on what to bring based on your camping spot. Save maps and routes for easy navigation.</li>
              <li><i className="bi bi-check"></i> Share that perfect spot with friends! Organize group trips, set dates, and reminisce with photos and memories.</li>
              <li><i className="bi bi-check"></i> Connect with campers from around the world. Camp together, share experiences, and make lifelong friends.</li>
              <li><i className="bi bi-check"></i> Fun activities await on your camping, from mushroom picking 🍄 and wildlife snaps 📸 to trail adventures 🚶‍♂️.</li>
            </ul>
            <p>Stay tuned for more enhancements to your camping adventures with Tribal!</p>
          </div>
        </div>

        <div className="row content" data-aos="fade-left">
          <div className="col-md-4 order-1 order-md-2" >
            <img src="https://i.imgur.com/kNrTLdT.jpg" className="img-fluid" alt="" loading="lazy" />
          </div>
          <div className="col-md-8 pt-5 order-2 order-md-1">
            <h3>Phase 3: Will soon be Decided</h3>
            <p className="fst-italic">
              With Phase 3 on the horizon, we're charting a course for the future of camping.
              Stay tuned for a journey into the extraordinary, where your outdoor escapades will be taken to new heights.
            </p>
            <p>
              As we venture into Phase 3, we're excited to unveil a world of possibilities. 
              While our upcoming features are currently under wraps, rest assured that they're set to revolutionize your camping experience. 
              From enhanced convenience to extraordinary adventures, we can't wait to share the details with you.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Phases;
