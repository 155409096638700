import React, { useState } from 'react';
import './Contact.css';

function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('idle'); // 'idle', 'error', 'sent'

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('idle');

    // Construct the request body
    const requestBody = {
      Title: subject,
      Body: `Name: ${name}\nYou got message: ${message}`,
      Email: email
    };

    try {
      const response = await fetch(global.SERVIP + '/api/Notification/send-notification', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
      });

      if (response.ok) {
        setStatus('sent');
        showNotification('Your message has been sent. Thank you!', 'success');
        // Clear form fields
        setName('');
        setEmail('');
        setSubject('');
        setMessage('');
      } else {
        setStatus('error');
        showNotification('There was an error sending your message.', 'error');
      }
    } catch (error) {
      setStatus('error');
      showNotification('There was an error sending your message.', 'error');
      console.error('Error:', error);
    }
  };

  const showNotification = (message, type) => {
    const notification = document.getElementById('notification');
    notification.innerText = message;
    notification.className = `notification ${type} show`;
    setTimeout(() => {
      notification.classList.remove('show');
    }, 3000); // Hide after 5 seconds
  };

  return (
    <section id="contact" className="contact">
      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2 style={{ marginLeft: '0', textAlign: 'left' }}>Contact</h2>
          <p>Contact Us</p>
        </div>
        <div className="row">
          <div className="col-lg-4" data-aos="fade-right" data-aos-delay="100">
            <div className="info">
              <div className="address">
                <i className="bi bi-geo-alt contactdok"></i>
                <h4>Location:</h4>
                <p style={{ paddingTop: '10px', textAlign: 'center', margin: 0 }}>Belgrade, Serbia</p>
              </div>
              <div className="email">
                <i className="bi bi-envelope contactdok"></i>
                <h4>Email:</h4>
                <p style={{ paddingTop: '10px', textAlign: 'center', margin: 0 }}>tribalcamping@gmail.com</p>
              </div>
              <div className="phone">
                <i className="bi bi-phone contactdok"></i>
                <h4>Call:</h4>
                <p style={{ paddingTop: '10px', textAlign: 'center', margin: 0 }}>+381 69 121 2212</p>
              </div>
            </div>
          </div>
          <div className="col-lg-8 mt-5 mt-lg-0" data-aos="fade-left" data-aos-delay="200">
            <form onSubmit={handleSubmit} role="form" className="php-email-form">
              <div className="row">
                <div className="col-md-6 form-group">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    placeholder="Your Name"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Your Email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group mt-3">
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  id="subject"
                  placeholder="Subject"
                  required
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>
              <div className="form-group mt-3">
                <textarea
                  className="form-control"
                  name="message"
                  rows="5"
                  placeholder="Message"
                  required
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <div className="text-center">
                <button type="submit">Send Message</button>
              </div>
            </form>
          </div>
        </div>
        <div id="notification" className="notification"></div>
      </div>
    </section>
  );
}

export default Contact;
