// PosaljiMail.js
import React, { useState } from 'react';
import './PosaljiMail.css';

function PosaljiMail({ onSubscribe, jelhome }) {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');

  const posaljiMail = async (e) => {
    e.preventDefault();
    setStatus('loading');

    // Construct the request body
    const requestBody = { email: email };

    console.log(email);

    try {
      const response = await fetch(global.SERVIP + '/api/Notification/subscribe', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
      });

      
    // Call onSubscribe to show the popup and clear the input
    onSubscribe(); // Open popup on successful subscription

      
      if (response.ok) {
        setStatus('sent');
        setEmail(''); // Clear the email input field
      } else {
        setStatus('error');
      }
    } catch (error) {
      setStatus('error');
    }
  };

  return (
    <form className="subscribe-form" onSubmit={posaljiMail}>
      <div className= {jelhome ? "subscribe-container" : "subscribe-container-2"}>
        <input 
          type="email" 
          name="email" 
          className='enter_email'
          placeholder="Enter your email"
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
        />
        
        <input 
          type="submit" 
          className={jelhome ? 'started-2' : 'btn-get-started'} 
          value="Subscribe" 
        />
      </div>
    </form>
  );
}

export default PosaljiMail;
