import Skycons from 'skycons';  // Assuming Skycons is installed or imported

const WeatherCodes = {
    "0": { description: "Clear sky", icon: "CLEAR_DAY" },
    "1": { description: "Mainly clear", icon: "PARTLY_CLOUDY_DAY" },
    "2": { description: "Partly cloudy", icon: "PARTLY_CLOUDY_DAY" },
    "3": { description: "Overcast", icon: "CLOUDY" },
    "45": { description: "Fog", icon: "FOG" },
    "48": { description: "Depositing rime fog", icon: "FOG" },
    "51": { description: "Drizzle: Light intensity", icon: "SLEET" },
    "53": { description: "Drizzle: Moderate intensity", icon: "SLEET" },
    "55": { description: "Drizzle: Dense intensity", icon: "SLEET" },
    "56": { description: "Freezing Drizzle: Light intensity", icon: "SLEET" },
    "57": { description: "Freezing Drizzle: Dense intensity", icon: "SLEET" },
    "61": { description: "Rain: Slight intensity", icon: "RAIN" },
    "63": { description: "Rain: Moderate intensity", icon: "RAIN" },
    "65": { description: "Rain: Heavy intensity", icon: "RAIN" },
    "66": { description: "Freezing Rain: Light intensity", icon: "SLEET" },
    "67": { description: "Freezing Rain: Heavy intensity", icon: "SLEET" },
    "71": { description: "Snow fall: Slight intensity", icon: "SNOW" },
    "73": { description: "Snow fall: Moderate intensity", icon: "SNOW" },
    "75": { description: "Snow fall: Heavy intensity", icon: "SNOW" },
    "77": { description: "Snow grains", icon: "SNOW" },
    "80": { description: "Rain showers: Slight intensity", icon: "SHOWERS_DAY" },
    "81": { description: "Rain showers: Moderate intensity", icon: "SHOWERS_DAY" },
    "82": { description: "Rain showers: Violent intensity", icon: "SHOWERS_DAY" },
    "85": { description: "Snow showers: Slight intensity", icon: "SNOW" },
    "86": { description: "Snow showers: Heavy intensity", icon: "SNOW" },
    "95": { description: "Thunderstorm: Slight or moderate", icon: "THUNDER_RAIN" },
    "96": { description: "Thunderstorm with slight hail", icon: "THUNDER" },
    "99": { description: "Thunderstorm with heavy hail", icon: "THUNDER" }
};

export default WeatherCodes;
