import './AnimalWarrning.css';
import bearIcon from '../../assets/AnimalIcons/bear.png'
import boarIcon from '../../assets/AnimalIcons/boar.png'
import wolfIcon from '../../assets/AnimalIcons/wolf.png'
import snakeIcon from '../../assets/AnimalIcons/snake.png'
import jackalIcon from '../../assets/AnimalIcons/jackal.png'

function AnimalWarrning({ id, distance }) {
    let string, icon;
    switch (id) {
        case "snakes":
            string = (distance < 2000) ?
                `Watch out! Poisonous snakes have been seen right here.` :
                `Poisonous snakes spotted ${Math.floor(distance / 1000)}km from this spot.`;
            icon = snakeIcon;
            break;
        case "boars":
            string = (distance < 2000) ?
                `Boars are roaming this area. Stay safe!` :
                `Boars have been encountered ${Math.floor(distance / 1000)}km from here.`;
            icon = boarIcon;
            break;
        case "wolves":
            string = (distance < 2000) ?
                `Wolves have been sighted here. Keep your distance!` :
                `Wolves have been reported ${Math.floor(distance / 1000)}km away.`;
            icon = wolfIcon
            break;
        case "jackals":
            string = (distance < 2000) ?
                `Jackals are in this vicinity. Be cautious!` :
                `Jackals have been noticed ${Math.floor(distance / 1000)}km from this location.`;
            icon = jackalIcon
            break;
        case "bears":
            string = (distance < 2000) ?
                `Bears have been seen in this spot. Exercise extreme caution!` :
                `Bears have been spotted ${Math.floor(distance / 1000)}km away. Stay alert!`;
            icon = bearIcon;
            break;
        default:
            string = "Warning: animal";
            icon = 'none';
    }
    return (
        <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
            <img src={icon} style={{ width: 50, height: 50, marginRight: '10px' }}></img>
            <a style={{ color: 'white', fontSize: '14px' }}>{string}</a>
        </div>
    )
}

export default AnimalWarrning;