import React, { useEffect, useState } from 'react';
import '../../../styles/SideBar.css';
import close from '../../../assets/close.png';
import { parseCoordinateString } from '../../../utils/Coordinate';
import countriesData from "country-list-js";

const countries = countriesData.ls('iso2');

countries.sort((a, b) => {
  const nameA = countriesData.findByIso2(a).name;
  const nameB = countriesData.findByIso2(b).name;

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
});

function PromptSearch({
  closeSidebar,
  advancedSearch,
  showHelp,
  center,
  setCenter,
  setShouldSearch,
  country,
  setCountry
}) {
  const [searchQuery, setSearchQuery] = useState('');
  global.setSearchQuery = setSearchQuery;

  useEffect(() => {
  }, [searchQuery])

  const preMadeSearches = [
    "Nice spots for family camping",
    "Remote camping",
    "Fishing",
    "Boys trip",
  ];

  const handleSearchClick = async () => {
    try {
      const response = await fetch(global.SERVIP + '/prompt/translate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ country, text: searchQuery }), // Send searchQuery as JSON
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json(); // Get the JSON data from the response
      // AKO IMA COUNTRY
      if (data.center && data.radius) {
        setCenter(parseCoordinateString(data.center));
        if (global.setRadiusAdvancedSearch) {
          global.setRadiusAdvancedSearch(data.radius / 1000);
        }
        global.radius = data.radius / 1000;
      }
      delete data.radius;
      delete data.center;

      setShouldSearch(data);
    }
    catch (e) {
      console.error(e);
    }
    // You can add logic here to perform the actual search
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="center-container">
        <div className="prompt-search" style={{ backgroundColor: '#121212', color: 'white',  display: 'flex', justifyContent: 'flex-start', height: '100%' }}>
           
            <textarea
                id="search-input"
                value={searchQuery}
                onChange={handleInputChange}
                placeholder='A camping spot 50km or less from Zurich. I want it to be in the woods and have a nice lake and I also want it to have a firepit.'
                className="search-input"
                style={{ backgroundColor: '#1a1a1a', color: 'white', border: '1px solid #3a3a3a', padding: 10 }}
            />
            <select
                value={country}
                style={{
                    backgroundColor: '#1a1a1a',
                    color: 'white',
                    border: 'none',
                    width: '100%',
                    margin: '5px 0',
                    padding: '10px',
                    borderRadius: '5px',
                    outline: 'none'
                }} 
                onChange={(e) => { setCountry(e.target.value) }}>
                <option value="" disabled>Select a Country</option>
                {countries.map(e => <option value={e}>{countriesData.findByIso2(e).name}</option>)}
                {/* Add more country options as needed */}
            </select>
            <div className="pre-made-searches" style={{ margin: '10px 0' }}></div>
            <button className="prompt-search-button" onClick={handleSearchClick}>Search</button>
            <button className="prompt-search-button" onClick={showHelp}>How should I search for a spot?</button>
            <button className="prompt-search-button" onClick={advancedSearch}>Use regular search</button>
        </div>
    </div>
);

}

export default PromptSearch;

