import React from 'react';
import './WeatherCard.css';
import WeatherCodes from '../../utils/WeatherCodes';
import Skycons, { SkyconsType } from 'react-skycons';

function WeatherCard({ place, date, temperature, lowestTemperature, highestTemperature, weatherCode }) {
    // Convert weather code to Skycon type
    const getSkyconType = (code) => {
        const iconMapping = {
            "0": "CLEAR_DAY",
            "1": "PARTLY_CLOUDY_DAY",
            "2": "PARTLY_CLOUDY_DAY",
            "3": "CLOUDY",
            "45": "FOG",
            "48": "FOG",
            "51": "SLEET",
            "53": "SLEET",
            "55": "SLEET",
            "56": "SLEET",
            "57": "SLEET",
            "61": "RAIN",
            "63": "RAIN",
            "65": "RAIN",
            "66": "SLEET",
            "67": "SLEET",
            "71": "SNOW",
            "73": "SNOW",
            "75": "SNOW",
            "77": "SNOW",
            "80": "RAIN",
            "81": "RAIN",
            "82": "RAIN",
            "85": "SNOW",
            "86": "SNOW",
            "95": "THUNDER_RAIN",
            "96": "THUNDER",
            "99": "THUNDER"
        };
        return SkyconsType[iconMapping[code]] || SkyconsType.CLEAR_DAY;
    };

    const iconType = getSkyconType(weatherCode);

    return (
        <div className="weather-card" onClick={()=>{}}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent:'space-between', flexDirection:"row"}}>
            <div className="weather-card-header">
                <span>{place}<br/>{new Date(date).toLocaleDateString()}</span>
                <span>{WeatherCodes[weatherCode]?.description}</span>
            </div>
            <Skycons
                color="white"
                type={iconType}
                animate={true}
                size={84}
                resizeClear={true}
                style={{ marginBottom: '10px' }}
            />
            </div>
           

            <span className="temp">{temperature}°</span>

            <div className="temp-scale">
                <span>Low {lowestTemperature}°C</span>
                <span>High {highestTemperature}°C</span>
            </div>
        </div>
    );
}

export default WeatherCard;
